/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import {
  SHOW_POP_UP,
  HIDE_POP_UP,
  LOGOUT_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
  showPopup: false,
  popupType: '',
  header: '',
  subHeader: '',
  message: '',
  selectedId: '',
  handleConfirm: null
};
const popupReducer = createReducer(
  initialState,
  {
    [SHOW_POP_UP]: (state, action) => {
      state.showPopup = true;
      state.popupType = action.payload.popupType;
      state.header = action.payload.header;
      state.subHeader = action.payload.subHeader;
      state.message = action.payload.message;
      state.selectedId = action.payload.selectedId;
      state.handleConfirm = action.payload.handleConfirm;
    },
    [HIDE_POP_UP]: (state, action) => {
      Object.keys(state).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    [LOGOUT_SUCCESS]: (state) => {
      Object.keys(state).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
);

export default popupReducer;
