/* eslint-disable no-console */
import { createAction } from '@reduxjs/toolkit';
import {
  MENU_CREATE_START,
  MENU_CREATE_SUCCESS,
  MENU_CREATE_FAILED,
  MENU_UPDATE_START,
  MENU_UPDATE_SUCCESS,
  MENU_UPDATE_FAILED,
  MENU_GET_START,
  MENU_GET_SUCCESS,
  MENU_GET_FAILED,
  MENU_DELETE_START,
  MENU_DELETE_SUCCESS,
  MENU_DELETE_FAILED,
  MENU_ITEMS_REORDER_START,
  MENU_ITEMS_REORDER_SUCCESS,
  MENU_ITEMS_REORDER_FAILED,
  MENUS_FOR_USER_GET_START,
  MENUS_FOR_USER_GET_SUCCESS,
  MENUS_FOR_USER_GET_FAILED,
  MENUS_FOR_LINK_GET_START,
  MENUS_FOR_LINK_GET_SUCCESS,
  MENUS_FOR_LINK_GET_FAILED,
  MENU_CREATE_SECTION_START,
  MENU_CREATE_SECTION_SUCCESS,
  MENU_CREATE_SECTION_FAILED,
  MENU_CREATE_ITEM_START,
  MENU_CREATE_ITEM_SUCCESS,
  MENU_CREATE_ITEM_FAILED,
  MENU_UPDATE_SECTION_START,
  MENU_UPDATE_SECTION_SUCCESS,
  MENU_UPDATE_SECTION_FAILED,
  MENU_UPDATE_ITEM_START,
  MENU_UPDATE_ITEM_SUCCESS,
  MENU_UPDATE_ITEM_FAILED,
  MENU_ITEMS_TOGGLE_SUCCESS,
  MENU_ITEM_UPDATED,
  MENU_ITEM_ADDED,
  MENU_LIST_UPDATED,
  SET_SELECTED_MENU,
  RESET_SELECTED_MENU,
  SET_UPDATED_MENU_LIST,
  UPDATE_SELECTED_MENU_THEME,
  MENU_ITEM_DELETED,
  MENU_SECTION_DELETED,
  UPDATE_MENU_CUSTOM_THEME,
  MENU_ORDERING_START,
  MENU_ORDERING,
  MENU_DRAFT_CREATE_START,
  MENU_DRAFT_CREATE_SUCCESS,
  MENU_DRAFT_CREATE_FAILED,
  MENU_DRAFT_DELETE_START,
  MENU_DRAFT_DELETE_SUCCESS,
  MENU_DRAFT_DELETE_FAILED,
  UPDATE_TEMP_SELECTED_MENU,
  SET_IS_LOCKED_THEME
} from './actionTypes';
import * as API from '../../functions/api';
import { hidePopupAction, showPopupAction } from "./popupActions";
import _ from "lodash";
import { hideLoaderAction, showLoaderAction } from "./loaderActions";
import { uploadImage } from "../../firebase/menu";

export const getMenuStart = createAction(MENU_GET_START);
export const getMenuSuccess = createAction(MENU_GET_SUCCESS);
export const getMenuFailed = createAction(MENU_GET_FAILED);
export const getMenu = (id) => (dispatch) => {
  dispatch(getMenuStart());
  API.getMenuById(id)
    .then((menu) => {
      if (menu) {
        dispatch(getMenuSuccess(menu));
      }
    })
    .catch((e) => {
      console.error('getmenu error: ', e);
      dispatch(getMenuFailed(e));
    });
};

export const getMenusForUserStart = createAction(MENUS_FOR_USER_GET_START);
export const getMenusForUserSuccess = createAction(MENUS_FOR_USER_GET_SUCCESS);
export const getMenusForUserFailed = createAction(MENUS_FOR_USER_GET_FAILED);
export const getMenusForUser = (userId) => (dispatch) => {
  dispatch(getMenusForUserStart());
  return API.getMenusByUser(userId)
    .then((menus) => {
      if (menus) {
        dispatch(getMenusForUserSuccess(menus));
      }
    })
    .catch((e) => {
      console.error('getmenusforuser error: ', e);
      dispatch(getMenusForUserFailed(e));
    });
};

export const getMenusForLinkStart = createAction(MENUS_FOR_LINK_GET_START);
export const getMenusForLinkSuccess = createAction(MENUS_FOR_LINK_GET_SUCCESS);
export const getMenusForLinkFailed = createAction(MENUS_FOR_LINK_GET_FAILED);
export const getMenusForLink = (link) => (dispatch) => {
  dispatch(getMenusForLinkStart());
  API.getMenusByLink(link)
    .then((menus) => {
      if (menus) {
        dispatch(getMenusForLinkSuccess(menus));
      }
    })
    .catch((e) => {
      console.error('getmenusforlink error: ', e);
      dispatch(getMenusForLinkFailed(e));
    });
};

export const createMenuStart = createAction(MENU_CREATE_START);
export const createMenuSuccess = createAction(MENU_CREATE_SUCCESS);
export const createMenuFailed = createAction(MENU_CREATE_FAILED);
export const createMenu = (data, hasMenuFile, history, nextPage) => (dispatch) => {
  dispatch(createMenuStart());
  return API.createMenu(data)
    .then((res) => {
      if (res) {
        dispatch(createMenuSuccess(res.data));
        dispatch(setSelectedMenu(res.data));
        window.analytics.track('New Menu', {
          menuId: res.data.id,
          menuName: res.data.name,
        });
        if (hasMenuFile) {
          dispatch(showPopupAction(
            {
              showPopup: true,
              popupType: "Success",
              message: (data.sections && data.sections.length > 0)
                ? 'Looks like we were able to read some of your menu. Unfortunately, this feature is still in beta, so please review your items and make sure they are correct before publishing.'
                : 'Oh no, unfortunately, it looks like our auto-upload feature didn’t work too well for the menu you provided. You can add sections and items using this page to fill in your menu.'
            })
          );
        }
        if (nextPage) {
          history.push(nextPage);
        }
      }
    })
    .catch((e) => {
      console.error('createmenu error: ', e);
      dispatch(createMenuFailed(e));
    });
};

export const createMenuSectionStart = createAction(MENU_CREATE_SECTION_START);
export const createMenuSectionSuccess = createAction(MENU_CREATE_SECTION_SUCCESS);
export const createMenuSectionFailed = createAction(MENU_CREATE_SECTION_FAILED);
export const createMenuSection = (menuId, data) => (dispatch) => {
  dispatch(createMenuSectionStart());
  API.createMenuSection(menuId, data)
    .then((menu) => {
      if (menu) {
        dispatch(createMenuSectionSuccess(menu));
      }
    })
    .catch((e) => {
      console.error('createmenusection error: ', e);
      dispatch(createMenuSectionFailed(e));
    });
};

export const createMenuItemStart = createAction(MENU_CREATE_ITEM_START);
export const createMenuItemSuccess = createAction(MENU_CREATE_ITEM_SUCCESS);
export const createMenuItemFailed = createAction(MENU_CREATE_ITEM_FAILED);
export const createMenuItem = (menuId, sectionId, data) => (dispatch) => {
  dispatch(createMenuItemStart());
  API.createMenuItem(menuId, sectionId, data)
    .then((menu) => {
      if (menu) {
        dispatch(createMenuItemSuccess(menu));
      }
    })
    .catch((e) => {
      console.error('createmenuitem error: ', e);
      dispatch(createMenuItemFailed(e));
    });
};

export const updateMenuStart = createAction(MENU_UPDATE_START);
export const updateMenuSuccess = createAction(MENU_UPDATE_SUCCESS);
export const updateMenuFailed = createAction(MENU_UPDATE_FAILED);
export const updateMenuCustomThemeAction = createAction(UPDATE_MENU_CUSTOM_THEME);
export const updateMenu = (menuId, data, draftId, callback) => (dispatch) => {
  dispatch(updateMenuStart());
  API.updateMenu(menuId, {data})
    .then(async (res) => {
      if (res) {
        window.analytics.track('Update Menu', {
          menuId,
        });
        dispatch(updateMenuSuccess(res.data));
        dispatch(updateMenuCustomThemeAction({}));
        if (draftId) {
          await dispatch(deleteMenuDraft(draftId, menuId));
        }
        return callback();
      }
    })
    .catch((e) => {
      console.error('updatemenu error: ', e);
      dispatch(updateMenuFailed(e));
      callback(e);
    });
};

export const updateMenuSectiontart = createAction(MENU_UPDATE_SECTION_START);
export const updateMenuSectionSuccess = createAction(MENU_UPDATE_SECTION_SUCCESS);
export const updateMenuSectionFailed = createAction(MENU_UPDATE_SECTION_FAILED);
export const updateMenuSection = (menuId, sectionId, data) => (dispatch) => {
  dispatch(updateMenuSectiontart());
  API.updateMenuSection(menuId, sectionId, data)
    .then((menu) => {
      if (menu) {
        dispatch(updateMenuSectionSuccess(menu));
      }
    })
    .catch((e) => {
      console.error('updatemenusection error: ', e);
      dispatch(updateMenuSectionFailed(e));
    });
};

export const updateMenuItemStart = createAction(MENU_UPDATE_ITEM_START);
export const updateMenuItemSuccess = createAction(MENU_UPDATE_ITEM_SUCCESS);
export const updateMenuItemFailed = createAction(MENU_UPDATE_ITEM_FAILED);
export const updateMenuItem = (menuId, sectionId, itemId, data) => (dispatch) => {
  dispatch(updateMenuItemStart());
  API.updateMenuItem(menuId, sectionId, itemId, data)
    .then((menu) => {
      if (menu) {
        dispatch(updateMenuItemSuccess(menu));
      }
    })
    .catch((e) => {
      console.error('updatemenuitem error: ', e);
      dispatch(updateMenuItemFailed(e));
    });
};

export const deleteMenuStart = createAction(MENU_DELETE_START);
export const deleteMenuSuccess = createAction(MENU_DELETE_SUCCESS);
export const deleteMenuFailed = createAction(MENU_DELETE_FAILED);
export const deleteMenu = (id) => (dispatch) => {
  dispatch(deleteMenuStart());
  return API.deleteMenu(id).then((success) => {
    if (success) {
      dispatch(deleteMenuSuccess(id));
      window.analytics.track('Delete Menu', {
        id,
      });
      dispatch(hidePopupAction());
    }
  }).catch((e) => {
    console.error('deletemenu error: ', e);
    dispatch(deleteMenuFailed(e));
    dispatch(hidePopupAction());
  });
};

export const menuDraftCreateStart = createAction(MENU_DRAFT_CREATE_START);
export const menuDraftCreateSuccess = createAction(MENU_DRAFT_CREATE_SUCCESS);
export const menuDraftCreateFailed = createAction(MENU_DRAFT_CREATE_FAILED);
export const createMenuDraft = (data, history) => (dispatch) => {
  dispatch(showLoaderAction());
  dispatch(menuDraftCreateStart());
  API.createMenuDraft(data)
    .then((res) => {
      if (res) {
        dispatch(menuDraftCreateSuccess(res.data));
        dispatch(hideLoaderAction());
        history.push('/');
      }
    })
    .catch((e) => {
      dispatch(menuDraftCreateFailed(e));
      dispatch(showPopupAction({
        showPopup: true,
        popupType: "error",
        header: "Error!",
        message: "Error occurred while saving draft. Please try again!"
      }));
      dispatch(hideLoaderAction());
    });
};

export const deleteMenuDraftStart = createAction(MENU_DRAFT_DELETE_START);
export const deleteMenuDraftSuccess = createAction(MENU_DRAFT_DELETE_SUCCESS);
export const deleteMenuDraftFailed = createAction(MENU_DRAFT_DELETE_FAILED);
export const deleteMenuDraft = (id, menuId) => (dispatch) => {
  dispatch(deleteMenuDraftStart());
  dispatch(hidePopupAction());
  dispatch(showLoaderAction());
  return API.deleteDraft(id).then((success) => {
    if (success) {
      dispatch(deleteMenuDraftSuccess({id, menuId}));
      dispatch(hideLoaderAction());
    }
  }).catch((e) => {
    dispatch(deleteMenuDraftFailed(e));
    dispatch(hideLoaderAction());
  });
};

export const menuReorderStart = createAction(MENU_ITEMS_REORDER_START);
export const menuReorderSuccess = createAction(MENU_ITEMS_REORDER_SUCCESS);
export const menuReorderFail = createAction(MENU_ITEMS_REORDER_FAILED);
export const menuReorder = (update) => (dispatch) => {
  dispatch(menuReorderSuccess(update));
};

export const menuItemToggleSuccess = createAction(MENU_ITEMS_TOGGLE_SUCCESS);
export const menuItemToggle = (update) => (dispatch) => {
  dispatch(menuItemToggleSuccess(update))
};

export const menuItemAddedSuccess = createAction(MENU_ITEM_ADDED);
export const menuItemAdded = (update, imageFile, userId) => (dispatch) => {
  if (imageFile && !_.isNil(imageFile.imageFileObject)) {
    dispatch(showLoaderAction());
    return uploadImage(imageFile.imageFileObject, userId).then((photoUrl) => {
      update.photoUrl = photoUrl;
      dispatch(menuItemAddedSuccess(update));
      dispatch(hideLoaderAction());
    }).catch((e) => {
      update.photoUrl = ``;
      dispatch(menuItemAddedSuccess(update));
      dispatch(hideLoaderAction());
    });
  } else {
    dispatch(menuItemAddedSuccess(update));
  }
};

export const menuItemUpdatedSuccess = createAction(MENU_ITEM_UPDATED);
export const menuItemUpdated = (update, imageFile, userId) => (dispatch) => {
  if (imageFile && !_.isNil(imageFile.imageFileObject)) {
    dispatch(showLoaderAction());
    return uploadImage(imageFile.imageFileObject, userId).then((photoUrl) => {
      update.photoUrl = photoUrl;
      dispatch(menuItemUpdatedSuccess(update));
      dispatch(hideLoaderAction());
    }).catch((e) => {
      update.photoUrl = ``;
      dispatch(menuItemUpdatedSuccess(update));
      dispatch(hideLoaderAction());
    });
  } else {
    dispatch(menuItemUpdatedSuccess(update));
  }
};

export const menuUpdatedSuccess = createAction(MENU_LIST_UPDATED);
export const menuListUpdated = (update) => (dispatch) => {
  dispatch(menuUpdatedSuccess(update))
}

export const setSelectedMenuAction = createAction(SET_SELECTED_MENU);
export const setSelectedMenu = (selectedMenu) => (dispatch) => {
  dispatch(setSelectedMenuAction(selectedMenu))
};

export const resetSelectedMenuId = createAction(RESET_SELECTED_MENU);
export const resetSelectedMenu = () => (dispatch) => {
  dispatch(resetSelectedMenuId())
};

export const setUpdatedMenuListAction = createAction(SET_UPDATED_MENU_LIST);
export const setUpdatedMenuList = (menus) => (dispatch) => {
  dispatch(setUpdatedMenuListAction(menus))
};

export const updateTempSelectedMenuAction = createAction(UPDATE_TEMP_SELECTED_MENU);
export const updateTempSelectedMenu = (data) => (dispatch) => {
  dispatch(updateTempSelectedMenuAction(data));
}

export const updateSelectedMenuThemeAction = createAction(UPDATE_SELECTED_MENU_THEME);
export const updateSelectedMenuTheme = (menuTheme) => (dispatch) => {
  dispatch(updateSelectedMenuThemeAction(menuTheme));
  if (menuTheme.theme.name === "custom") {
    dispatch(updateMenuCustomThemeAction(menuTheme));
  }
  dispatch(updateTempSelectedMenuAction({}));
};

export const menuItemDeletedSuccess = createAction(MENU_ITEM_DELETED);
export const menuItemDeleted = (itemId, sectionId, history) => (dispatch) => {
  dispatch(menuItemDeletedSuccess({ itemId, sectionId }));
  dispatch(hidePopupAction());
  history.push("/update-menu");
};

export const menuSectionDeletedSuccess = createAction(MENU_SECTION_DELETED);
export const menuSectionDeleted = (sectionId, history) => (dispatch) => {
  dispatch(menuSectionDeletedSuccess({ sectionId }));
  dispatch(hidePopupAction());
  history.push("/update-menu");
};

export const menuOrderingStart = createAction(MENU_ORDERING_START);
export const menuOrderingSuccess = createAction(MENU_ORDERING);
export const menuOrdering = (update) => (dispatch) => {
  dispatch(menuOrderingStart());
  if(update){
    dispatch(menuOrderingSuccess(update));
  }
}


export const setIsLockedThemeAction = createAction(SET_IS_LOCKED_THEME);
export const setIsLockedTheme = (setIsLockedThemeFlag) => (dispatch) => {
  dispatch(setIsLockedThemeAction(setIsLockedThemeFlag));
};
