/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import Axios from 'axios';

const axios = Axios.create({
  baseURL: 'https://us-central1-aboard-v1.cloudfunctions.net/api',
  // baseURL: 'http://localhost:5001/aboard-v1/us-central1/api',
  timeout: 10000,
  headers: { 
    'Cache-Control': 'no-cache'
  },
});

const authAxios = Axios.create({
  baseURL: 'https://us-central1-aboard-v1.cloudfunctions.net/api',
  // baseURL: 'http://localhost:5001/aboard-v1/us-central1/api',
  timeout: 10000,
  headers: { 
    'Cache-Control': 'no-cache',
  },
});

// Add a request interceptor For Token Auth
authAxios.interceptors.request.use(
  config => {
      const token = localStorage.getItem("AuthToken");
      if (token) {
          config.headers['Authorization'] =  `Bearer ${token}`
      }
      return config;
  },
  error => {
    console.error('Rejection in interceptors',error)
      Promise.reject(error)
  });

/**
 * User
 */
export const getUserData = (id) => authAxios
  .get(`/user/id/${id}`)
  .then(({ data }) => data)
  .catch((err) => {
    console.log('Error getting user data: ', id, err);
    throw err;
  });

  // GET ORDER INFO
export const getOrdersByUser = (userId) => authAxios
  .get(`/user-orders/${userId}`)
  .then(({ data }) => {
    return data;
  })
  .catch((err) => console.log('Error getting orders for user ', userId, err));


/**
 * Menus
 */
export const getMenuById = (menuId) => authAxios
  .get(`/menu/${menuId}`)
  .then(({ data }) => data)
  .catch((err) => console.log('Error getting menu data for id ', menuId, err));

export const getMenusByUser = (userId) => authAxios
  .get(`/usermenus/${userId}`)
  .then(({ data }) => data)
  .catch((err) => console.log('Error getting menus for user ', userId, err));

export const getMenusByLink = (link) => axios
  .get(`/menus/${link}`)
  .then(({ data }) => data)
  .catch((err) => {
    console.log('Error getting menus for link ', link, err);
    throw err;
  });

export const getcustomtoken = (id) => axios
.post(`/token`,{id})
.then(({data}) =>{ 
  return data})
.catch((err) => {
  console.log('Error getting menus for link ', err);
  throw err;
});

// Make sure `data` includes user id
export const createMenu = (data) => authAxios
  .post('/menu', data)
  .then((res) => {
    return res})
  .catch((err) => {
    console.log('Error creating menu ', err);
    throw err;
  });

export const createMenuSection = (menuId, data) => authAxios
  .post(`/menu/${menuId}/section`, data)
  .then((res) => res)
  .catch((err) => console.log('Error creating section for menu ', menuId, err));

export const createMenuItem = (menuId, sectionId, data) => authAxios
  .post(`/menu/${menuId}/section/${sectionId}/item`, data)
  .then((res) => res)
  .catch((err) => console.log(
    `Error creating new item in section ${sectionId} in menu ${menuId}: ${err}`,
  ));

export const updateMenu = (menuId, data) => authAxios
  .put(`/menu/${menuId}`, data)
  .then((res) => res)
  .catch((err) => {
    console.log('Error updating menu ', menuId, err);
    throw err;
  });

export const updateMenuSection = (menuId, sectionId, data) => authAxios
  .put(`/menu/${menuId}/section/${sectionId}`, data)
  .then((res) => res)
  .catch((err) => console.log(`Error updating section ${sectionId} in menu ${menuId}: ${err}`));

export const updateMenuItem = (menuId, sectionId, itemId, data) => authAxios
  .put(`/menu/${menuId}/section/${sectionId}/item/${itemId}`, data)
  .then((res) => res)
  .catch((err) => console.log(
    `Error updating item ${itemId} in section ${sectionId} in menu ${menuId}: ${err}`,
  ));

export const deleteMenu = (menuId) => authAxios
  .delete(`/menu/${menuId}`)
  .then((res) => res)
  .catch((err) => console.log('Error deleting menu ', menuId, err));

// Function to create draft
export const createMenuDraft = (data) => authAxios
  .post('/draft', data)
  .then((res) => {
    return res;
  })
  .catch((err) => {
    throw err;
  });

// Function to delete a draft
export const deleteDraft = (draftId) => authAxios
  .delete(`/draft/${draftId}`)
  .then((res) => res)
  .catch((err) => {
    throw err;
  });

/**
 * Themes
 */
export const getThemes = () => authAxios
  .get('/themes')
  .then(({ data }) => data)
  .catch((err) => console.log('Error themes', err));

/**
 * Users
 */
export const updateUserSettings = (userId, data) => authAxios
  .put(`/auth/updateuser/${userId}`, { data })
  .then((res) => res)
  .catch((err) => {
    console.log('Error updating user settings ', err);
    throw err;
  });

/**
 * Validate link
 */
export const validateLink = (data) => authAxios
  .post('/user/validatelink', { ...data })
  .then((res) => res)
  .catch((err) => {
    console.log('Error validating user link', err);
    throw err;
  });

/**
 * Stripe
 */
export const createCustomer = (data) => authAxios
  .post('/create-customer', data)
  .then((res) => res)
  .catch((err) => {
    console.log('Error creating customer', err);
    throw err;
  });

export const getCustomer = (id) => authAxios
  .get(`/stripe/customer/${id}`)
  .then((res) => res)
  .catch((err) => {
    console.log('Error getting customer', err);
    throw err;
  });

export const createSubscription = (data) => authAxios
  .post('/create-subscription', data)
  .then((res) => res)
  .catch((err) => {
    console.log('Error creating subscription', err);
    throw err;
  });

export const retryInvoice = (data) => authAxios
  .post('/retry-invoice', data)
  .then((res) => res)
  .catch((err) => console.log('Error retrying invoice', err));

export const cancelSubscription = (data) => authAxios
  .post('/cancel-subscription', data)
  .then((res) => res)
  .catch((err) => console.log('Error canceling subscription', err));

export const updateSubscription = (data) => authAxios
  .post('/update-subscription', data)
  .then((res) => res)
  .catch((err) => {
    console.log('Error updating subscription', err);
    throw err;
  });

export const retrieveUpcomingInvoice = (data) => authAxios
  .post('/retrieve-upcoming-invoice', data)
  .then((res) => res)
  .catch((err) => console.log('Error retrieving upcoming invoice', err));

export const retrievePaymentMethod = (data) => authAxios
  .post('/retrieve-customer-payment-method', data)
  .then((res) => res)
  .catch((err) => console.log('Error retrieving payment method', err));

/**
 * Machine Learning api
 */

export const parseMenu = (menuUrl, delimiter, priceLocation) => {
  let bodyFormData = new FormData();
  bodyFormData.set('menuImage', menuUrl);
  bodyFormData.set('delimiter', delimiter === 'none' ? '.' : delimiter);
  bodyFormData.set('priceLocation', priceLocation);
  return Axios({
    method: 'post',
    url: 'https://data-api-tpm4pusnva-uc.a.run.app/api/v1/menuOcr',
    data: bodyFormData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Cache-Control': 'no-cache',
      'Authorization': 'GUID c348ccf35eec68379f1ea6cdbee428e495b4fc3642e32112a7b9fa459ae1e986'
    }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

/**
 * Email api
 */

export const sendEmail = (data) => authAxios
  .post('/send-email', data)
  .then((res) => res)
  .catch((err) => console.log('Error occurred while sending email', err));
