import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar,
  Button,
  Container,
  CssBaseline,
  IconButton,
  TextField,
  Toolbar,
  Typography,
  Grid,
  Divider,
  CardMedia,
  MenuItem,
  Select,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { v4 as uuidv4 } from "uuid";

import { createMenu } from '../../store/actions/menuActions';
import { uploadMenuFile } from '../../firebase/menu';
import AddIcon from "@material-ui/icons/Add";
import { showPopupAction } from '../../store/actions/popupActions';
import Alert from '@material-ui/lab/Alert';
import { hideLoaderAction, showLoaderAction } from '../../store/actions/loaderActions';
import PDFPreviewComponent from "../../components/PDFPreviewComponent";

const useStyles = makeStyles((theme) => ({
  headerTitle: {
    flexGrow: 2,
    textAlign: 'initial',
    paddingLeft: theme.spacing(2),
  },
  mainContainer: {
    margin: theme.spacing(12, 0, 8, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(5, 0, 3, 0),
  },
  inputText: {
    margin: theme.spacing(0, 0, 2, 0),
    '& .MuiInputBase-root-122': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  subTitleText: {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: 'rgba(0, 0, 0, 0.6)',
    padding: theme.spacing(1.5),
  },
  menuPhoto: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    margin: 'auto',
  },
  subHeaderContainer: {
    width: "100%"
  },
  uploadButton: {
    padding: theme.spacing(1.5)
  },
  warningAlert: {
    marginTop: theme.spacing(1)
  },
  selectInput: {
    margin: theme.spacing(0, 1),
    color: 'rgba(0, 0, 0, 0.6)',
    '& .MuiSelect-select-148': {
      margin: theme.spacing(0, 1)
    }
  },
  menuOptionsText: {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: 'rgba(0, 0, 0, 0.6)',
    padding: theme.spacing(1.5, 0),
  },
}));

const allowedFileTypes = [
  'application/pdf',
  'image/jpeg'
];

const AddMenu = ({ history }) => {
  const user = useSelector((state) => state.auth.user);
  const themes = useSelector((state) => state.theme.themes);
  const menus = useSelector((state) => state.menu.menus);
  const isFreePlan = useSelector((state) => state.stripe.isFreePlan);
  const isContactTracingPlan = useSelector((state) => state.stripe.isContactTracingPlan);
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    name: '',
    priceLocation: 'RIGHT',
    delimiter: '.'
  });
  const [error, setError] = useState({});
  const [menuFile, setMenuFile] = useState();
  const [menuFilePreviewURL, setMenuFilePreviewURL] = useState('');
  const classes = useStyles();

  const handleChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value
    });
    setError({
      ...error,
      [name]: ''
    });
  };

  const handleAddMenu = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { name, delimiter, priceLocation } = values;
    const exampleMenu = menus.find((menu) => menu.name === 'Example Menu');
    const maxAllowedMenus = exampleMenu ? 3 : 2;
    if (!_.isEmpty(name)) {
      if ((isFreePlan || isContactTracingPlan) && menus.length >= maxAllowedMenus) {
        dispatch(showPopupAction({ showPopup: true, message: 'Please upgrade your plan to add more menus!' }));
      } else {
        // Add default theme "Cinema" to user
        const defaultTheme = themes.find((theme) => theme.id === "Cinema");
        let theme = {};
        if (!_.isEmpty(defaultTheme)) {
          theme = {
            includeLogo: false,
            emphasizeHighMargin: false,
            includeSign: 'None',
            decimals: 2,
            theme: {
              name: defaultTheme.id,
              ...defaultTheme
            }
          };
          delete theme.theme.id;
        }
        dispatch(showLoaderAction());
        let menu = {
          userId: user.id,
          name,
          toggle: false,
          theme,
          sections: [],
          checkout_link: false
        };
        let hasMenuFile = false;
        if (!_.isNil(menuFile)) {
          hasMenuFile = true;
          uploadMenuFile(menuFile, user.id, delimiter, priceLocation).then(async (response) => {
            response.processed_menu.forEach((menuSection) => {
              menuSection.items.forEach((sectionItem) => {
                sectionItem.id = uuidv4();
                sectionItem.toggle = true;
                sectionItem.name = _.startCase(sectionItem.name);
                sectionItem.desc = _.capitalize(sectionItem.desc);
                sectionItem.price = _.isNaN(Number(sectionItem.price)) ? '' : Number(sectionItem.price);
                sectionItem.photoUrl = "";
                sectionItem.isHighMarginItem = "None";
              });
              menu.sections.push({
                id: uuidv4(),
                toggle: true,
                name: _.startCase(menuSection.name),
                items: menuSection.items
              })
            });
            await dispatch(createMenu(menu, hasMenuFile, history, '/update-menu'));
            dispatch(hideLoaderAction());
          }).catch((error) => {
            dispatch(showPopupAction({
              showPopup: true,
              popupType: "error",
              header: "Error!",
              message: "Error occurred while uploading menu. Please try again!"
            }));
            dispatch(hideLoaderAction());
          });
        } else {
          await dispatch(createMenu(menu, hasMenuFile, history, '/update-menu'));
          dispatch(hideLoaderAction());
        }
      }
    } else {
      setError({
        ...error,
        name: "Please enter Menu name"
      });
    }
  };

  const onFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      if (allowedFileTypes.includes(event.target.files[0].type)) {
        const previewURL = URL.createObjectURL(event.target.files[0]);
        setMenuFilePreviewURL(previewURL);
        setMenuFile(event.target.files[0]);
        setError({
          ...error,
          menuFile: ''
        });
      } else {
        event.target.value = '';
        setError({
          ...error,
          menuFile: 'Please make sure that the menu file is of jpeg or pdf format.'
        });
      }
    }
  };

  const closeWarningAlert = (event) => {
    event.preventDefault();
    setError({
      ...error,
      menuFile: ''
    });
  }

  const { name, priceLocation, delimiter } = values;
  return (
    <div>
      <AppBar position="fixed" color="primary">
        <Toolbar>
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={() => history.push('/')}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" className={classes.headerTitle}>
            Add Menu
          </Typography>
          <div />
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.mainContainer} onSubmit={handleAddMenu}>
          <form className={classes.form}>
            <TextField
              variant="outlined"
              fullWidth
              id="name"
              name="name"
              placeholder="New Menu Name"
              value={name}
              onChange={handleChange}
              error={!_.isEmpty(error.name)}
              helperText={error.name}
              className={classes.inputText}
            />
            {
              menuFilePreviewURL &&
                <div>
                  <Typography variant="body2" align="left" className={classes.menuOptionsText}>
                    Prices are on the
                    <Select
                      id="priceLocation"
                      name="priceLocation"
                      value={priceLocation}
                      className={classes.selectInput}
                      onChange={handleChange}
                    >
                      <MenuItem key='right' value='RIGHT'>right</MenuItem>
                      <MenuItem key='left' value='LEFT'>left</MenuItem>
                    </Select>
                    side of each item.
                  </Typography>
                  <Typography variant="body2" align="left" className={classes.menuOptionsText}>
                  Prices in this menu use a
                    <Select
                    id="delimiter"
                    name="delimiter"
                    value={delimiter}
                    className={classes.selectInput}
                    onChange={handleChange}
                    >
                      <MenuItem key='period' value='.'>period(.)</MenuItem>
                      <MenuItem key='comma' value=','>comma(,)</MenuItem>
                      <MenuItem key='none' value='none'>none</MenuItem>
                    </Select>
                  in them.
                  </Typography>
                </div>
            }
            <div className={classes.subHeaderContainer}>
              <Typography variant="body2" align="left" className={classes.menuOptionsText}>
                If you'd like to use our auto-upload function, please upload a .pdf (recommended) or .jpeg of your current menu. You will still need to review your menu before publishing as this feature is still in beta.
              </Typography>
              <Grid container maxWidth="sm">
                <Grid item xs>
                  <Typography variant="body2" align="left" className={classes.subTitleText}>{menuFilePreviewURL ? "" : "No File Attached"}</Typography>
                </Grid><Grid item>
                <Button
                  color="primary"
                  component="label"
                  className={classes.uploadButton}
                >
                  Upload
                  <input id="menuFile" type="file" accept="application/pdf, image/jpeg" onChange={onFileChange} style={{ display: 'none' }} />
                </Button>
              </Grid>
              </Grid>
              <Divider/>
            </div>
            {
              error.menuFile && <Alert severity="warning" className={classes.warningAlert} onClose={closeWarningAlert}>
                {error.menuFile}
              </Alert>
            }
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              startIcon={<AddIcon />}
            >
              ADD MENU
            </Button>
            {
              menuFilePreviewURL ?
                ((menuFile && menuFile.type === 'application/pdf')
                  ? <PDFPreviewComponent file={menuFile}/>
                  : <CardMedia
                      component="img"
                      alt="Menu Image"
                      className={classes.menuPhoto}
                      image={menuFilePreviewURL}
                      title="MenuImage"
                    />
                  )
                : ''
            }
          </form>
        </div>
      </Container>
    </div>
  );
};

export default AddMenu;
