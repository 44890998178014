/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps  */
//react-hooks/exhaustive-deps is disable here || It gives warning in useEffect has a missing dependency
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Container,
  Menu,
  MenuItem,
  FormGroup,
  IconButton,
  Typography,
  Toolbar,
  AppBar,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Button,
  CardMedia
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AddIcon from "@material-ui/icons/Add";
import SettingsIcon from "@material-ui/icons/Settings";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { CopyToClipboard } from "react-copy-to-clipboard";
import _ from 'lodash';
import "./dashboard.scss";
import { setSelectedMenu, setUpdatedMenuList } from "../../store/actions/menuActions";
import { logout, getUser, updateUser } from "../../store/actions/authActions";
import { showPopupAction } from "../../store/actions/popupActions";
import { retrieveAllRequiredData } from "../../store/actions/dashboardActions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 2,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    paddingBottom: theme.spacing(6),
  },
  button: {
    margin: theme.spacing(1),
  },
  logo: {
    height: '100%',
    width: '150px',
    objectFit: 'cover',
    margin: theme.spacing(2, "auto", 2),
  },
  settingsIconButton: {
    color: 'rgba(0, 0, 0, 0.38)',
    padding: theme.spacing(2)
  },
  listTitle: {
    lineHeight: '1.3',
    padding: theme.spacing(2, 0)
  },
  warningAlert: {
    marginTop: theme.spacing(1)
  },
  actionWrapperButton: {
    fontSize: "13px",
    color: "#5db075",
    cursor: "pointer",
    textTransform: "none"
  },
  subTitleText: {
    padding: theme.spacing(0.75, 1, 0.75, 0)
  },
  premiumBannerContainer: {
    padding: theme.spacing(0.75, 0),
    marginBottom: theme.spacing(1),
    backgroundColor: 'rgba(58, 127, 89, 0.38)'
  },
  noPaddingContainer: {
    padding: 0
  }
}));

export default function Dashboard(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [orderedMenu,setOrderedMenu] = useState(null)

  const user = useSelector((state) => state.auth.user);
  const menus = useSelector((state) => state.menu.menus);
  const isFreePlan = useSelector((state) => state.stripe.isFreePlan);
  const isContactTracingPlan = useSelector((state) => state.stripe.isContactTracingPlan);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // eslint-disable-next-line no-unused-vars
  const [copied, setCopied] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeCopiedAlert = (event) => {
    event.preventDefault();
    setCopied(false);
  };

  const toggleQRCodePopup = (event) => {
    event.preventDefault();
    dispatch(showPopupAction({ showPopup: true, popupType: "QRCode", subHeader: "Use this QR code to access your menus:" }));
    window.analytics.track('Generate QR Code');
  };

  useEffect(() => {
    dispatch(getUser(user.id));
  }, [user.id]);

  useEffect(() => {
    // Pull menu data for the current user
    setOrderedMenu(null)
    dispatch(retrieveAllRequiredData(user.id, user.stripeCustomerId));
  }, [dispatch, user.id, user.stripeCustomerId]);

  const handleLogout = () => {
    handleClose();
    dispatch(logout());
  };

  const handleMenuClick = (event, menuId) => {
    event.preventDefault();
    const currentMenus = !_.isEmpty(orderedMenu) ? orderedMenu : menus;
    const selectedMenu = currentMenus.find((menu) => menu.id === menuId);
    dispatch(setSelectedMenu(selectedMenu));
    window.analytics.track('View Menu', {
      menuId: selectedMenu.id,
    });
    history.push("/update-menu");
  };

  const handleMenuSettingsClick = (event) => {
    event.preventDefault();
    dispatch(setUpdatedMenuList([]));
    window.analytics.track('View Menu Settings');
    history.push("/menu-settings")
  }

  const handleCopyLinkClick = () => {
    setCopied(true);
  }

  const handleUpgradeClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    history.push('/setup', { nextPage: '/' });
  }

  const handleAddMenuClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (isFreePlan || isContactTracingPlan) {
      const currentMenus = !_.isEmpty(orderedMenu) ? orderedMenu : menus;
      const exampleMenu = currentMenus.find((menu) => menu.name === 'Example Menu');
      const maxAllowedMenus = exampleMenu ? 3 : 2;
      if (currentMenus.length >= maxAllowedMenus) {
        dispatch(showPopupAction({ showPopup: true, message: 'Please upgrade your plan to add more menus!' }));
      } else {
        history.push("/add-menu");
      }
    } else {
      history.push("/add-menu");
    }
  }

  useEffect(() => {
    if(user && user.menuOrder && user.menuOrder.length > 0) {
      orderingNewMenu();
    }
  },[user])

  const orderingNewMenu = () => {
    let tamp = []
    let isNew = [];
    if(menus && menus.length && user.menuOrder && user.menuOrder.length ){
      user.menuOrder.map((orderId) => {
      menus.map((menue) => {
        if(_.includes(user.menuOrder,menue.id)){
          if(orderId === menue.id){
            tamp.push(menue)
          }
        } else {
          if(!_.includes(isNew,menue)){
           isNew.push(menue)
          }
        }
      })
    })
  }
  if(Object.keys(isNew).length > 0) {
      isNew.map(menu => tamp.push(menu))
    }
  setOrderedMenu(tamp)
}

  const MenuBlocks = () => (
    <List className="listingDashboard">
    {menus.map((menu) => (
      <ListItem className="listingItemDashboard" key={menu.id}>
        <ListItemText primary={menu.name} />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="delete"
            id={menu.id}
            name={menu.name}
            onClick={(e) => { handleMenuClick(e, menu.id) }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ))}
  </List>
  )

  const OrderedMenuBox = () => (
    <List className="listingDashboard">
    {orderedMenu.map((menu) => (
      <ListItem className="listingItemDashboard" key={menu.id}>
        <ListItemText primary={menu.name} />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="delete"
            id={menu.id}
            name={menu.name}
            onClick={(e) => { handleMenuClick(e, menu.id) }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ))}
  </List>
  )
  return (
    <div className={classes.root}>
      <FormGroup />
      <AppBar position="static">
        <Toolbar className="appToolBar">
          <Typography variant="h6" className={classes.title}>
            Dashboard
          </Typography>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <SettingsIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={() => history.push("/settings")}>
                Settings
              </MenuItem>
              <MenuItem onClick={() => history.push("/orders")}>
                Orders
              </MenuItem>
              <MenuItem onClick={() => history.push("/tutorials")}>
                Tutorials
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container className={classes.noPaddingContainer} maxWidth='xl'>
            {
              (isFreePlan || isContactTracingPlan) && <div className={classes.premiumBannerContainer}>
                <Container maxWidth='md'>
                  <Grid container>
                    <Grid item xs>
                      <Typography variant="subtitle2" align="left" className={classes.subTitleText}>Unlock more with Pro!</Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleUpgradeClick}
                      >
                        Upgrade
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            }
            {
              user.photoUrl && <CardMedia
                component="img"
                alt="logo"
                className={classes.logo}
                image={user.photoUrl}
                title="Logo"
              />
            }
            <Typography
              component="h4"
              variant="h5"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Welcome Back {user.company}
            </Typography>
          </Container>
        </div>
        <Container maxWidth="md">
          <div className="yourLink">
            <label>YOUR LINK</label>
          </div>
          <div className="copyLink">
            <input
              type="text"
              className="yourLinkValue"
              value={`aboard.menu/${user.link}`}
              disabled
            />
            <ul className="actionWapper">
              <li>
                <CopyToClipboard
                  text={`https://aboard.menu/${user.link}`}
                  onCopy={handleCopyLinkClick}
                >
                  <Button className={classes.actionWrapperButton}>copy link</Button>
                </CopyToClipboard>
              </li>
              <li>
                <Button onClick={toggleQRCodePopup} className={classes.actionWrapperButton}>generate QR code</Button>
              </li>
            </ul>
            {copied && (
              <Alert onClose={closeCopiedAlert}>
                Link copied to your clipboard!
              </Alert>
            )}
            {(menus && menus.length === 0) && (
              <Alert severity="warning" className={classes.warningAlert}>
                Your link will not work until you add a menu!
              </Alert>
            )}
          </div>
          <Grid item xs={12} md={12} className="gripMenuD">
            <div className="titleWapper">
              <Typography variant="h6" className={classes.listTitle}>
                Menus
              </Typography>
              <IconButton
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                className={classes.settingsIconButton}
                onClick={handleMenuSettingsClick}
              >
                <SettingsIcon />
              </IconButton>
            </div>
            <div className={classes.demo}>
             {(!_.isEmpty(orderedMenu))? <OrderedMenuBox /> : <MenuBlocks />}
            </div>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<AddIcon />}
            onClick={handleAddMenuClick}
          >
            MENU
          </Button>
        </Container>
      </main>
    </div>
  );
}
