import React from "react";
import { Backdrop } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import "./ConfirmationAnimationLoader.scss";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 9999,
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.9)"
  }
}));

const ConfirmationAnimationLoader = (props) => {
  const { loadingStatus, displayLoader } = props;
  const classes = useStyles();
  return (
    <Backdrop open={displayLoader} className={classes.backdrop}>
    <div className="checkmark">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0, 0, 100, 100" id="checkmark">
        <g transform="">
          <circle className={ loadingStatus === "loading" ? "path" : "path path-complete" } fill="none" stroke="#7DB0D5" strokeWidth="4" strokeMiterlimit="10" cx="50" cy="50" r="44"/>
          <circle className={ loadingStatus === "loading" ? "fill" : loadingStatus === "complete" ? "fill fill-complete" : "fill fill-complete success"  } fill="none" stroke="#7DB0D5" strokeWidth="4" strokeMiterlimit="10" cx="50" cy="50" r="44"/>
          <polyline className={ loadingStatus === "loading" ? "check" : loadingStatus === "complete" ? "check check-complete" : "check check-complete success" } fill="none" stroke="#7DB0D5" strokeWidth="8" strokeLinecap="round" strokeMiterlimit="10" points="70,35 45,65 30,52  "/>
        </g>
      </svg>
    </div>
    </Backdrop>
  )
};

export default ConfirmationAnimationLoader;
