import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, CssBaseline, Typography, Button, CardMedia, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { sendVerificationEmail } from '../../firebase/auth';

import AboardLogo from "../../assets/images/aboard_logo.svg";
import colors from "../../constants/colors";
import {showPopupAction} from "../../store/actions/popupActions";
import { logout } from "../../store/actions/authActions";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    link: {
        textTransform: "uppercase",
        textDecoration: "none",
        color: colors.white,
    },
    logo: {
        height: '100%',
        width: '100px',
        objectFit: 'cover',
        margin: theme.spacing(2, "auto"),
    },
    alertContainer: {
        width: "100%"
    },
    subTitleText:{
        fontSize: "16px",
        lineHeight: "25px",
        letterSpacing: "0.25px",
        color: "rgba(0, 0, 0, 0.6)",
        padding: theme.spacing(1.5)
    },
    dismissButton: {
        padding: theme.spacing(1.5, 0)
    },
    goBackButton: {
      marginTop: theme.spacing(4)
    }
}));

const UnVerifiedPage = ({ history }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const emailVerified = useSelector((state) => state.auth.emailVerified);

    useEffect(() => {
        if (emailVerified) {
            history.push("/");
        }
    }, [history, emailVerified]);

    useEffect(()=> {
        window.gtag('event', 'conversion', {'send_to': 'AW-604456682/JSbfCILoit0BEOqNnaAC'});
    },[])

    const handleLogout = (event) => {
        event.preventDefault();
        dispatch(logout());
    };

    const handleResendClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        sendVerificationEmail().then(() => {
            dispatch(showPopupAction({
                showPopup: true,
                popupType: "info",
                message: "We have sent you a verification email. Please check your email to verify your address."
            }));
        })
    }
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classes.mainContainer}>
                <CardMedia
                    component="img"
                    alt="logo"
                    className={classes.logo}
                    image={AboardLogo}
                    title="Logo"
                />
                <div>
                <Typography variant="subtitle1">Please check your email to verify your address</Typography>
                </div>
                <div>
                    <Grid container maxWidth="xs">
                        <Grid item>
                            <Typography variant="body2" align="left" className={classes.subTitleText}>Didn't receive the email?</Typography>
                        </Grid>
                        <Grid item>
                            <Button
                              color="primary"
                              className={classes.dismissButton}
                              onClick={handleResendClick}
                            >
                                Resend
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                <Button
                  color="default"
                  className={classes.goBackButton}
                  onClick={handleLogout}
                >
                    Go Back
                </Button>
            </div>
        </Container>
    );
};

export default UnVerifiedPage;
