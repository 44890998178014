/* eslint-disable react-hooks/exhaustive-deps  */
//react-hooks/exhaustive-deps is disable here || It gives warning in useEffect has a missing dependency
import React, { useState, useEffect } from "react";
import {
  Grid,
  Container,
  IconButton,
  Typography,
  List,
} from "@material-ui/core";

import SettingsIcon from "@material-ui/icons/Settings";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropRightIcon from "@material-ui/icons/ArrowRight";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from "lodash";
import { menuListUpdated } from "../../store/actions/menuActions";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "./menuItem";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const addMenuItems = (items, newItem) => {
  let temp = [...items];
  temp.push({
    id: uuidv4(),
    name: newItem.name,
    price: newItem.price ? _.round(Number(newItem.price), 2) : "",
    pricePerGlass: newItem.pricePerGlass ? _.round(Number(newItem.pricePerGlass), 2) : "",
    photoUrl: (newItem.photoUrl) ? newItem.photoUrl : ``,
    desc: newItem.desc,
    toggle: newItem.toggle,
    isHighMarginItem: !_.isUndefined(newItem.isHighMarginItem) ? newItem.isHighMarginItem : "None",
  });
  return temp;
};

const editMenuItems = (item, newItem) => {
  let temp = [];
  item.forEach((value, index) => {
    if (value.id === newItem.id) {
      temp.push({
        id: newItem.id,
        name: newItem.name,
        price: newItem.price ? _.round(Number(newItem.price), 2) : "",
        pricePerGlass: newItem.pricePerGlass ? _.round(Number(newItem.pricePerGlass), 2) : "",
        photoUrl: (newItem.photoUrl) ? newItem.photoUrl : ``,
        desc: newItem.desc,
        toggle: newItem.toggle,
        isHighMarginItem: !_.isUndefined(newItem.isHighMarginItem) ? newItem.isHighMarginItem : "None",
      });
    } else {
      temp.push(value);
    }
  });
  if (newItem.oldSection !== newItem.section) {
    temp.push({
      id: newItem.id,
      name: newItem.name,
      price: newItem.price ? _.round(Number(newItem.price), 2) : "",
      pricePerGlass: newItem.pricePerGlass ? _.round(Number(newItem.pricePerGlass), 2) : "",
      photoUrl: (newItem.photoUrl) ? newItem.photoUrl : ``,
      desc: newItem.desc,
      toggle: newItem.toggle,
      isHighMarginItem: !_.isUndefined(newItem.isHighMarginItem) ? newItem.isHighMarginItem : "None",
    });
  }
  return temp;
};
export default function MenuList(props) {
  let history = useHistory();
  const grid = 8;
  const menuUpdated = useSelector((state) => state.menu.update);
  const menuState = useSelector((state) => state.menu);
  const selectedMenu = useSelector((state) => state.menu.selectedMenu);
  const userDetails = useSelector((state) => state.auth.user);
  const [listCollapse, setListCollapse] = useState([]);
  const [priceStyles,setPriceStyles] = useState({});

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: 0,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging 1px solid
    background: isDragging ? "rgba(33, 33, 33, 0.06)" : "white",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const dispatch = useDispatch();

  // STATIC STATE
  const [MenuItems, setMenuItem] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(selectedMenu)) {
      setMenuItem(selectedMenu.sections);
      let tempPriceStyles ={includeSign:(selectedMenu.theme.includeSign)?selectedMenu.theme.includeSign:null,decimals:(selectedMenu.theme.decimals)?selectedMenu.theme.decimals:null}  
      setPriceStyles(tempPriceStyles)
    }
    if (MenuItems) {
      if (menuUpdated === true) {
        const itemSubItemMap = MenuItems.reduce((acc, item) => {
          acc[item.id] = item.items;
          return acc;
        }, {});

        // POP PUSH
        if (menuState.itemUpdated.length > 0) {
          let updatedArray = [];
          let updatedItem = menuState.itemUpdated[0];
          const parentId = updatedItem.section;
          const oldSectionId = updatedItem.oldSection;
          const subItemsForCorrespondingParent = itemSubItemMap[parentId];
          let updateAble = editMenuItems(
            subItemsForCorrespondingParent,
            updatedItem
          );
          MenuItems.forEach((itemList) => {
            if (itemList.id === parentId) {
              updatedArray.push({
                name: itemList.name,
                id: itemList.id,
                items: updateAble,
                type: itemList.type,
                toggle: itemList.toggle,
              });
            } else if (parentId !== oldSectionId && itemList.id === oldSectionId) {
              let updatedItemsList = itemList.items.filter((i) => i.id !== updatedItem.id);
              updatedArray.push({
                name: itemList.name,
                id: itemList.id,
                toggle: itemList.toggle,
                type: itemList.type,
                items: updatedItemsList
              });
            } else {
              updatedArray.push(itemList);
            }
          });
          setMenuItem(updatedArray);
          dispatch(menuListUpdated(updatedArray));
        }
        // PUSH
        else if (menuState.itemAdded.length > 0) {
          let updatedArray = [];
          const parentId = menuState.itemAdded[0].section;
          // let updatedArray = [];
          const subItemsForCorrespondingParent = itemSubItemMap[parentId];
          let updateAble = addMenuItems(
            subItemsForCorrespondingParent,
            menuState.itemAdded[0]
          );
          MenuItems.forEach((itemList) => {
            if (itemList.id === parentId) {
              updatedArray.push({
                name: itemList.name,
                id: itemList.id,
                items: updateAble,
                type: itemList.type,
                toggle: itemList.toggle,
              });
            } else {
              updatedArray.push(itemList);
            }
          });
          setMenuItem(updatedArray);
          dispatch(menuListUpdated(updatedArray));
        }
      }
    }
  }, [MenuItems, menuUpdated, selectedMenu]);

  // Update on Menu Updated
  // On DRAG
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.type === "droppableItem") {
      const items = reorder(
        MenuItems,
        result.source.index,
        result.destination.index
      );
      setMenuItem(items);
      dispatch(menuListUpdated(items));
    } else if (result.type.includes("droppableSubItem")) {
      const parentId = result.type.split("__")[1];
      const itemSubItemMap = MenuItems.reduce((acc, item) => {
        acc[item.id] = item.items;
        return acc;
      }, {});
      const subItemsForCorrespondingParent = itemSubItemMap[parentId];
      const reorderedSubItems = reorder(
        subItemsForCorrespondingParent,
        result.source.index,
        result.destination.index
      );
      let updatedArray = [];
      MenuItems.forEach((itemList) => {
        if (itemList.id === parentId) {
          updatedArray.push({
            name: itemList.name,
            id: itemList.id,
            items: reorderedSubItems,
            toggle: itemList.toggle,
          });
        } else {
          updatedArray.push(itemList);
        }
      });
      setMenuItem(updatedArray);
      dispatch(menuListUpdated(updatedArray));
    }
  };
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "rgba(33, 33, 33, 0.08)" : "white",
    padding: 0,
    width: "100%",
  });

  const handleCollapse = (id) => {
    let collapse = listCollapse[id] ? { [id]: false } : { [id]: true };
    setListCollapse({ ...listCollapse, ...collapse });
  };
  return (
    <Container maxWidth="md">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" type="droppableItem">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {MenuItems &&
                _.map(MenuItems, (value, index) => {
                  return (
                    <Draggable
                      key={value.id}
                      draggableId={value.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div>
                          <Grid item xs={12} md={12} className="listDownPin">
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div className="dragSection">
                                <div className="updateMenuTitleWrapper">
                                  <div className="collagesWapper">
                                    <IconButton
                                      className="p-0"
                                      aria-controls="menu-appbar"
                                      aria-haspopup="true"
                                      color="inherit"
                                      onClick={() =>
                                        handleCollapse(`${value.id}`)
                                      }
                                    >
                                      {!listCollapse[value.id] ? (
                                        <ArrowDropDownIcon />
                                      ) : (
                                          <ArrowDropRightIcon />
                                        )}
                                    </IconButton>
                                  </div>
                                  <div
                                    className="dragPoint"
                                    {...provided.dragHandleProps}
                                  >
                                    <Typography
                                      variant="h6"
                                      className="listTitle"
                                    >
                                      {value.name}
                                    </Typography>
                                    <div className="settingInList">
                                      <IconButton
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        color="inherit"
                                        className="p-0 clr-gray"
                                        onClick={(e) =>
                                          props.handleButtonClick(e, "/edit-section", {
                                            name: value.name,
                                            desc: value.desc,
                                            id: value.id,
                                            toggle: value.toggle,
                                            type: value.type,
                                            totalItems: value.items.length
                                          })
                                        }
                                      >
                                        <SettingsIcon />
                                      </IconButton>
                                    </div>
                                  </div>
                                </div>

                                <List>
                                  {!listCollapse[value.id] ? (
                                    <MenuItem
                                      subItems={value.items}
                                      type={value.id}
                                      itemIndex={index}
                                      section={value.id}
                                      country={userDetails.country}
                                      handleButtonClick={
                                        props.handleButtonClick
                                      }
                                      priceStyles={priceStyles}
                                    />
                                  ) : (
                                      ``
                                    )}
                                </List>
                              </div>
                            </div>
                          </Grid>
                          {provided.placeholder}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  );
}
