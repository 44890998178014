/* eslint-disable no-useless-escape */
//no-useless-escape is disable here || It gives warning in Regular expression for email
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Container, List, FormControlLabel, Switch, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import OrderCard from "./orderCard";

import "./settings.scss";

const useStyles = makeStyles((theme) => ({
  headerTitle: {
    flexGrow: 2,
    textAlign: "initial",
    // paddingLeft: theme.spacing(2),
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 0,
  },
  form: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  inputText: {
    margin: theme.spacing(0, 0, 2, 0),
    "& .MuiInputBase-root-122": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  subTitleText: {
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.87)",
    padding: theme.spacing(1.5),
  },
  listTitle: {
    lineHeight: "1.3",
    padding: theme.spacing(2, 0),
  },
  timeSegment: {
    fontSize: "1.25rem",
    paddingTop: "20px",
  },
  timeContainer:{
    display: "flex",
    justifyContent: "space-between",
    padding: "0% 5%"
  },
  time: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start"
  },
  button: {
    display: "flex",
    justifyContent: "flex-end",
    alignContent: "center",
  },
}));

const OrderQueue = (props) => {
  const classes = useStyles();
  const [orders, setOrders] = useState(props.orders);
  const [segmentedOrders, setSegmentedOrders] = useState({});
  const [showAll, setShowAll] = useState(false);
  const [hiddenTimes, setHiddenTimes] = useState({});

  const handleToggle = (event) => {
    setShowAll(event.target.checked);
  };

  const handleToggleTime = (index) => {
    let tempState = {
      ...hiddenTimes,
      [index]: !hiddenTimes[index],
    }
    setHiddenTimes(tempState);
  }

  useEffect(() => {
    if (props.orders !== orders) {
      setOrders(props.orders);
    }
  }, [props.orders]);

  useEffect(() => {
    let tempSegmented = orders.reduce(
      (tempSegmented, order) => ({
        ...tempSegmented,
        [order.pickUpDate]: [...(tempSegmented[order.pickUpDate] || []), order],
      }),
      {},
    );
    setSegmentedOrders(tempSegmented);
  }, []);

  return (
    <div>
      <FormControlLabel
        control={
          <Switch
            checked={showAll}
            onChange={handleToggle}
            name="showAll"
            color="primary"
          />
        }
        label="Show Hidden Times"
      />
      {Object.keys(segmentedOrders).map((time, index) => {
        return (
          (!showAll?!hiddenTimes[index]:true)?(
            <div key={time} className={classes.timeSegment}>
              <Container className={classes.timeContainer}>
                <div className={classes.time}>
                  <Typography className={classes.pos} color="textSecondary">
                    Time Slot: {moment(time).subtract(1, 'months').format("MMMM Do HH:mm")} 
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    ({segmentedOrders[time].length}{" "}
                    {segmentedOrders[time].length === 1 ? "Order" : "Orders"})
                  </Typography>
                </div>
                <div className={classes.button}>
                  <Button variant="text" color="secondary" onClick={()=>handleToggleTime(index)}>{hiddenTimes[index]?'Show':'Hide'}</Button>
                </div>
              </Container>
              <List className={classes.mainContainer}>
                {segmentedOrders[time].map((order) => (
                  <OrderCard key={order.orderId} order={order} />
                ))}
              </List>
            </div>
          )
          :''
        );
      })}
    </div>
  );
};

export default OrderQueue;
