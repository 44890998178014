/* eslint-disable no-useless-escape */
//no-useless-escape is disable here || It gives warning in Regular expression for email
import React, {useState} from 'react';
import { useDispatch } from "react-redux";
import {
  Button,
  Container,
  CssBaseline,
  Divider,
  Grid,
  TextField,
  Typography
} from "@material-ui/core";
import _ from "lodash";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import { resetPasswordAction } from '../../store/actions/authActions';
import { hidePopupAction, showPopupAction } from "../../store/actions/popupActions";
import { hideLoaderAction, showLoaderAction } from "../../store/actions/loaderActions";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    margin: theme.spacing(15, 0, 3, 0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  alertContainer: {
    width: "100%"
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(7)
  },
  sendResetButton: {
    marginTop: theme.spacing(2)
  },
  link: {
    marginTop: theme.spacing(4),
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.38)",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  subTitleText:{
    fontSize: "16px",
    lineHeight: "25px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)",
    padding: theme.spacing(1.5)
  },
  dismissButton: {
    padding: theme.spacing(1.5)
  }
}));

const ForgotPassword = ({ history }) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    email: "",
    errors: {}
  });
  const dispatch = useDispatch();

  const handleChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const {name, value} = event.target;
    const {errors} = values;
    delete errors[name];
    setValues({
      ...values,
      errors,
      [name]: value
    });
  };

  const validate = () => {
    const emailRegExp = /^(([^<>()\[\],;:\s@]+(\.[^<>()\[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i;
    let errors = {};
    const { email } = values;
    if (!emailRegExp.test(email)) {
      errors.email = "Please enter valid email address";
    }
    return errors;
  };

  const sendResetLink = (event) => {
    event.preventDefault();
    const validationErrors = validate();
    if (_.isEmpty(validationErrors)){
      const {email} = values;
      dispatch(showPopupAction({
        showPopup: true,
        popupType: "info",
        message: "If an account with this email exists, we will send you an email."
      }));
      setTimeout(() => {
        dispatch(hidePopupAction());
        dispatch(showLoaderAction());
        dispatch(resetPasswordAction(email, () => {
          dispatch(hideLoaderAction());
          history.push('/login');
        }));
      }, 2000);
    } else {
      setValues({...values, errors: validationErrors});
    }
  };

  const { email, errors } = values;
  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline/>
      <div className={classes.mainContainer}>
        <Typography variant="h4" component="h4" className={classes.title}>Forgot Password?</Typography>
        <form className={classes.form} onSubmit={sendResetLink}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            value={email}
            onChange={handleChange}
            error={!_.isEmpty(errors.email)}
            helperText={errors.email}
            autoComplete="email"
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.sendResetButton}
          >
            Send reset
          </Button>
        </form>
        <Link to="/login" className={classes.link}>Log in</Link>
      </div>
    </Container>
  );
};

export default ForgotPassword;
