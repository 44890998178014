/* eslint-disable react-hooks/exhaustive-deps  */
//react-hooks/exhaustive-deps is disable here || It gives warning in useEffect has a missing dependency
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  IconButton,
  Typography,
  Toolbar,
  AppBar,
  Button,
  FormControl,
  Select,
  InputLabel,
  TextField,
  MenuItem,
  InputAdornment,
  FormControlLabel,
  FilledInput,
  FormHelperText,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  menuItemAdded,
  menuItemUpdated,
  menuItemDeleted,
} from "../../store/actions/menuActions";
import _ from "lodash";

import "./items.scss";
import {showPopupAction} from "../../store/actions/popupActions";
import colors from "../../constants/colors";

const highMarginItemOptions = [
  "Best Seller",
  "Chef’s Special",
  "New",
  "None"
];
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: "8px 0",
    minWidth: 120,
  },
  root: {
    flexGrow: 1,
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 2,
  },
  grow: {
    flexGrow: 1,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  button: {
    margin: theme.spacing(1),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%",
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  footerWapper: {
    background: colors.primary,
    padding: "1.3%",
    color: colors.white,
    fontWeight: "bold",
    marginTop: "2%",
    position: "fixed",
    bottom: "0",
    width: "100%",
    cursor: "pointer",
  },
}));

export default function AddItem(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const [itemImage, setItemImage] = useState(null);
  const classes = useStyles();
  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setItemImage({
        file: URL.createObjectURL(event.target.files[0]),
        imageFileObject: event.target.files[0]
      });
    }
  };
  const [form, setForm] = useState({
    name: "",
    desc: "",
    photoUrl: "",
    price: "",
    section: "",
    toggle: true,
    isHighMarginItem: "None"
  });
  const [error, setError] = useState({});
  const [sectionsValue, setSectionsValue] = useState([]);
  const [sectionType, setSectionType] = useState("");
  const selectedMenu = useSelector((state) => state.menu.selectedMenu);
  // Call Once for Setting State Data
  useEffect(() => {
    if (props.location && props.location.state) {
      const state = props.location.state;
      setForm({
        ...state,
        isHighMarginItem: _.isBoolean(state.isHighMarginItem) ? "None" : state.isHighMarginItem,
        oldSection: state.section
      });
      const selectedSection = selectedMenu.sections.find((section) => section.id === state.section);
      setSectionType(selectedSection.type);
      if (state.photoUrl && state.photoUrl !== ``) setItemImage({ file: state.photoUrl })
    }
    if (!_.isEmpty(selectedMenu)) {
      setSectionsValue(selectedMenu.sections || []);
    }
  }, [props.location]);

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    let priceRegExp = /^\d*\.?\d*$/;
    if (name === "price") {
      if (_.isEmpty(value) || priceRegExp.test(value)) {
        setForm({ ...form, [name]: value });
      }
    } else {
      delete error[name];
      if (name === "section") {
        const selectedSection = sectionsValue.find((section) => section.id === value);
        setSectionType(selectedSection.type);
      }
      setForm({ ...form, [name]: value });
      setError(error);
    }
  };

  const validateItem = () => {
    const requiredFields = ["name", "section"];
    let priceRegExp = /^\d*\.?\d*$/;
    let errors = {};
    requiredFields.forEach((fieldName) => {
      if (!form[fieldName] || _.isEmpty(form[fieldName].trim())) {
        errors[fieldName] = `Please enter ${fieldName}`;
      }
    });
    if (!_.isEmpty(form.price) && !priceRegExp.test(form.price)) {
      errors.price = "Please enter valid price";
    }
    return errors;
  };

  const handleSubmit = async (event, action) => {
    event.preventDefault();
    const validationErrors = validateItem();
    if (_.isEmpty(validationErrors)) {
      if (action === "edit") {
        await dispatch(menuItemUpdated(form, itemImage, selectedMenu.userId));
        history.push("/update-menu");
      } else {
        await dispatch(menuItemAdded(form, itemImage, selectedMenu.userId));
        history.push("/update-menu");
      }
    } else {
      setError(validationErrors);
    }
  };

  const handleDeleteItemClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(
      showPopupAction({
        showPopup: true,
        popupType: "confirmation",
        header: "Delete Item",
        message: `Are you sure you want to delete ${form.name}?`,
        selectedId: form.id,
        handleConfirm: handleDeleteItem
      })
    );
  };

  const handleDeleteItem = (itemId) => {
    dispatch(menuItemDeleted(itemId, form.section, history));
  }
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={() => history.push("/update-menu")}
          >
            <ArrowBackIosIcon />
          </IconButton>

          <Typography variant="h6" className={classes.title}>
            {props.location.state ? `Edit Item` : `Add Item`}
          </Typography>
          {
            props.location.state && <IconButton
              color="inherit"
              onClick={handleDeleteItemClick}
            >
              <DeleteIcon />
            </IconButton>
          }
          <div></div>
        </Toolbar>
      </AppBar>
      <div className="customWapper">
        <Container maxWidth="sm">
          <form
            className="addItemForm itemForm"
            onSubmit={(event) => {
              handleSubmit(event, props.location.state ? `edit` : `add`);
            }}
          >
            <FormControl
              fullWidth
              variant="filled"
              required={true}
              className={classes.formControl}
              error={!_.isEmpty(error.section)}
            >
              <InputLabel id="filled-label">Section</InputLabel>
              <Select
                labelId="filled-label"
                id="select-filled"
                name="section"
                value={form.section}
                onChange={handleOnChange}
              >
                {sectionsValue
                  ? sectionsValue.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))
                  : ``}
              </Select>
              {!_.isEmpty(error.section) && (
                <FormHelperText id="component-error-text">
                  {error.section}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                autoComplete="off"
                id="filled-basic"
                label="Name"
                variant="filled"
                name="name"
                value={form.name}
                onChange={handleOnChange}
                error={!_.isEmpty(error.name)}
                helperText={error.name}
              />
            </FormControl>

            {
              sectionType === 'wine' ? (
                <div>
                  <FormControl
                    fullWidth
                    className={`${classes.formControl} amountPrice`}
                    variant="filled"
                    error={!_.isEmpty(error.price)}
                  >
                    <InputLabel htmlFor="filled-adornment-amount">Amount per bottle</InputLabel>
                    <FilledInput
                      id="filled-adornment-amount"
                      value={form.price}
                      name="price"
                      onChange={handleOnChange}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                    />
                    {!_.isEmpty(error.price) && (
                      <FormHelperText id="component-error-text">
                        {error.price}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl
                    fullWidth
                    className={`${classes.formControl} amountPrice`}
                    variant="filled"
                    error={!_.isEmpty(error.pricePerGlass)}
                  >
                    <InputLabel htmlFor="filled-adornment-amount">Amount per glass</InputLabel>
                    <FilledInput
                      id="filled-adornment-amount"
                      value={form.pricePerGlass}
                      name="pricePerGlass"
                      onChange={handleOnChange}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                    />
                    {!_.isEmpty(error.pricePerGlass) && (
                      <FormHelperText id="component-error-text">
                        {error.pricePerGlass}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
              ) : (
                <FormControl
                  fullWidth
                  className={`${classes.formControl} amountPrice`}
                  variant="filled"
                  error={!_.isEmpty(error.price)}
                >
                  <InputLabel htmlFor="filled-adornment-amount">Amount</InputLabel>
                  <FilledInput
                    id="filled-adornment-amount"
                    value={form.price}
                    name="price"
                    onChange={handleOnChange}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                  />
                  {!_.isEmpty(error.price) && (
                    <FormHelperText id="component-error-text">
                      {error.price}
                    </FormHelperText>
                  )}
                </FormControl>
              )
            }
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                id="filled-basic"
                label="Description"
                variant="filled"
                name="desc"
                multiline
                rows={4}
                onChange={handleOnChange}
                value={form.desc}
                error={!_.isEmpty(error.desc)}
                helperText={error.desc}
              />
            </FormControl>
            <FormControl
              fullWidth
              required={true}
              variant="filled"
              className={classes.formControl}
              error={!_.isEmpty(error.isHighMarginItem)}
            >
              <InputLabel id="filled-label">Tag</InputLabel>
              <Select
                labelId="filled-label"
                id="high-margin-filled"
                name="isHighMarginItem"
                value={form.isHighMarginItem}
                onChange={handleOnChange}
              >
                {
                  highMarginItemOptions.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))
                }
              </Select>
              {!_.isEmpty(error.isHighMarginItem) && (
                <FormHelperText id="component-error-text">
                  {error.isHighMarginItem}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className="imageFormControl">
              <div className="imageWapper">
                {itemImage ? (
                  <span> {` `} </span>
                ) : (
                  <span>No Photo Attached</span>
                )}
                <Button variant="contained" component="label">
                  Upload
                  <input
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </Button>
              </div>
              <div className="previewImage">
                {itemImage ? (
                  <img src={itemImage.file || form.photoUrl} alt="menuItemImage" />
                ) : (
                  ``
                )}
              </div>
            </FormControl>
          </form>
        </Container>
        <div className={classes.footerWapper}>
          <div
            className="footerAction"
            onClick={(event) => {
              handleSubmit(event, props.location.state ? `edit` : `add`);
            }}
          >
            <Typography variant="h6" className={classes.title}>
              {props.location.state ? `Save Changes` : `Add Item`}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
