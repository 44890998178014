/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  MENU_CREATE_START,
  MENU_CREATE_SUCCESS,
  MENU_CREATE_FAILED,
  MENU_UPDATE_START,
  MENU_UPDATE_SUCCESS,
  MENU_UPDATE_FAILED,
  MENU_GET_START,
  MENU_GET_SUCCESS,
  MENU_GET_FAILED,
  MENU_DELETE_START,
  MENU_DELETE_SUCCESS,
  MENU_DELETE_FAILED,
  MENU_ITEMS_REORDER_START,
  MENU_ITEMS_REORDER_SUCCESS,
  MENU_ITEMS_REORDER_FAILED,
  MENUS_FOR_USER_GET_START,
  MENUS_FOR_USER_GET_SUCCESS,
  MENUS_FOR_USER_GET_FAILED,
  MENUS_FOR_LINK_GET_START,
  MENUS_FOR_LINK_GET_SUCCESS,
  MENUS_FOR_LINK_GET_FAILED,
  MENU_CREATE_SECTION_START,
  MENU_CREATE_SECTION_SUCCESS,
  MENU_CREATE_SECTION_FAILED,
  MENU_CREATE_ITEM_START,
  MENU_CREATE_ITEM_SUCCESS,
  MENU_CREATE_ITEM_FAILED,
  MENU_UPDATE_SECTION_START,
  MENU_UPDATE_SECTION_SUCCESS,
  MENU_UPDATE_SECTION_FAILED,
  MENU_UPDATE_ITEM_START,
  MENU_UPDATE_ITEM_SUCCESS,
  MENU_UPDATE_ITEM_FAILED,
  MENU_ITEMS_TOGGLE_SUCCESS,
  MENU_ITEM_ADDED,
  MENU_ITEM_UPDATED,
  MENU_LIST_UPDATED,
  SET_SELECTED_MENU,
  RESET_SELECTED_MENU,
  SET_UPDATED_MENU_LIST,
  LOGOUT_SUCCESS,
  UPDATE_SELECTED_MENU_THEME,
  MENU_ITEM_DELETED,
  MENU_SECTION_DELETED,
  UPDATE_MENU_CUSTOM_THEME,
  MENU_ORDERING,
  MENU_ORDERING_START,
  MENU_DRAFT_CREATE_START,
  MENU_DRAFT_CREATE_SUCCESS,
  MENU_DRAFT_CREATE_FAILED,
  MENU_DRAFT_DELETE_START,
  MENU_DRAFT_DELETE_SUCCESS,
  MENU_DRAFT_DELETE_FAILED,
  UPDATE_TEMP_SELECTED_MENU,
  SET_IS_LOCKED_THEME
} from '../actions/actionTypes';

const initialState = {
  menus: [],
  error: null,
  order: [],
  loading: false,
  update: false,
  itemAdded: [],
  itemUpdated: [],
  selectedMenu: {},
  updatedMenuList: [],
  menuCustomTheme: {},
  menuOrder: [],
  tempSelectedMenu: {},
  isLockedTheme: false
};

const menuReducer = createReducer(
  initialState,
  {
    [MENU_GET_START]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [MENU_GET_SUCCESS]: (state, action) => {
      let found = false;
      state.menus = state.menus.map((menu) => {
        const { payload } = action;

        if (menu.id === payload.id) {
          found = true;
          return payload;
        }
        return menu;
      });
      if (!found) {
        state.menus.push(action.payload);
      }
      state.loading = false;
      state.error = null;
    },
    [MENU_GET_FAILED]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [MENUS_FOR_USER_GET_START]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [MENUS_FOR_USER_GET_SUCCESS]: (state, action) => {
      const { payload } = action;
      state.menus = payload;
      state.loading = false;
      state.error = null;
    },
    [MENUS_FOR_USER_GET_FAILED]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [MENUS_FOR_LINK_GET_START]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [MENUS_FOR_LINK_GET_SUCCESS]: (state, action) => {
      const { payload } = action;
      payload.forEach((item) => {
        const index = state.menus.findIndex((m) => m.id === item.id);
        if (index < 0) {
          state.menus.push(item);
          return;
        }
        state.menus[index] = item;
      });
      state.loading = false;
      state.error = null;
    },
    [MENUS_FOR_LINK_GET_FAILED]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [MENU_CREATE_START]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [MENU_CREATE_SUCCESS]: (state, action) => {
      state.menus.push(action.payload);
      if (!_.isEmpty(state.updatedMenuList)) {
        state.updatedMenuList.push(action.payload);
      }
      state.loading = false;
      state.error = null;
    },
    [MENU_CREATE_FAILED]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [MENU_CREATE_SECTION_START]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [MENU_CREATE_SECTION_SUCCESS]: (state, action) => {
      const { payload } = action;
      state.menus = state.menus.map((menu) => {
        if (menu.id === payload.id) {
          return payload;
        }

        return menu;
      });
      state.loading = false;
      state.error = null;
    },
    [MENU_CREATE_SECTION_FAILED]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [MENU_CREATE_ITEM_START]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [MENU_CREATE_ITEM_SUCCESS]: (state, action) => {
      const { payload } = action;
      state.menus = state.menus.map((menu) => {
        if (menu.id === payload.id) {
          return payload;
        }

        return menu;
      });
      state.loading = false;
      state.error = null;
    },
    [MENU_CREATE_ITEM_FAILED]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [MENU_UPDATE_START]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [MENU_UPDATE_SUCCESS]: (state, action) => {
      const { payload } = action;
      state.menus = state.menus.map((menu) => {
        if (menu.id === payload.id) {
          return payload;
        }

        return menu;
      });
      state.loading = false;
      state.error = null;
    },
    [MENU_UPDATE_FAILED]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [MENU_UPDATE_SECTION_START]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [MENU_UPDATE_SECTION_SUCCESS]: (state, action) => {
      const { payload } = action;
      state.menus = state.menus.map((menu) => {
        if (menu.id === payload.id) {
          return payload;
        }

        return menu;
      });
      state.loading = false;
      state.error = null;
    },
    [MENU_UPDATE_SECTION_FAILED]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [MENU_UPDATE_ITEM_START]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [MENU_UPDATE_ITEM_SUCCESS]: (state, action) => {
      const { payload } = action;
      state.menus = state.menus.map((menu) => {
        if (menu.id === payload.id) {
          return payload;
        }

        return menu;
      });
      state.loading = false;
      state.error = null;
    },
    [MENU_UPDATE_ITEM_FAILED]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [MENU_DELETE_START]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [MENU_DELETE_SUCCESS]: (state, action) => {
      const index = state.menus.findIndex((menu) => menu.id === action.payload);

      if (index >= 0) {
        state.menus.splice(index, 1);
      }

      const updatedMenuIndex = state.updatedMenuList.findIndex((menu) => menu.id === action.payload);

      if (updatedMenuIndex >= 0) {
        state.updatedMenuList.splice(updatedMenuIndex, 1);
      }

      state.loading = false;
      state.error = null;
    },
    [MENU_DELETE_FAILED]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [MENU_ITEMS_REORDER_START]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [MENU_ITEMS_REORDER_SUCCESS]: (state, action) => {
      let found = false;
      state.order = state.order.map((menu) => {
        const { payload } = action;

        if (menu.id === payload.id) {
          found = true;
          return payload;
        }

        return menu;
      });
      if (!found) {
        state.order.push(action.payload);
      }
      state.loading = false;
      state.error = null;
    },
    [MENU_ITEMS_REORDER_FAILED]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [MENU_ITEMS_TOGGLE_SUCCESS]: (state,action) => {
      state.selectedMenu.sections.forEach((section) => {
        if (section.id === action.payload.sectionId) {
          section.items.forEach((item) => {
            if (item.id === action.payload.itemId) {
              item.toggle = !item.toggle;
            }
          });
        }
      });
    },
    [MENU_ITEM_ADDED]: (state,action) => {
      state.itemAdded = [];
      state.itemAdded.push(action.payload);
      state.update = true;
      if (state.selectedMenu.theme.theme.name !== 'custom' && action.payload.photoUrl) {
        state.selectedMenu.theme.theme.layout = 'linePhoto';
      }
    },
    [MENU_ITEM_UPDATED]: (state,action) => {
      state.itemUpdated = [];
      state.itemUpdated.push(action.payload);
      state.update = true;
      if (state.selectedMenu.theme.theme.name !== 'custom' && action.payload.photoUrl) {
        state.selectedMenu.theme.theme.layout = 'linePhoto';
      }
    },
    [MENU_ITEM_DELETED]: (state,action) => {
      state.selectedMenu.sections.forEach((section) => {
        if (section.id === action.payload.sectionId) {
          const index = section.items.findIndex((item) => item.id === action.payload.itemId);

          if (index >= 0) {
            section.items.splice(index, 1);
          }
        }
      });
    },
    [MENU_SECTION_DELETED]: (state,action) => {
      const index = state.selectedMenu.sections.findIndex((section) => section.id === action.payload.sectionId );
      if (index >= 0) {
        state.selectedMenu.sections.splice(index, 1);
      }
    },
    [MENU_LIST_UPDATED]: (state,action) => {
      state.update = false;
      state.itemAdded = [];
      state.itemUpdated = [];
      state.selectedMenu.sections = action.payload;
    },
    [SET_SELECTED_MENU]: (state,action) => {
      state.selectedMenu = action.payload;
      if (state.selectedMenu.theme && state.selectedMenu.theme.theme && state.selectedMenu.theme.theme.name === "custom") {
       state.menuCustomTheme = state.selectedMenu.theme;
      } else {
        state.menuCustomTheme = {};
      }
    },
    [RESET_SELECTED_MENU]: (state,action) => {
      state.selectedMenu = {};
    },
    [SET_UPDATED_MENU_LIST]: (state,action) => {
      state.updatedMenuList = action.payload;
    },
    [UPDATE_SELECTED_MENU_THEME]: (state,action) => {
      state.selectedMenu.theme = action.payload;
    },
    [UPDATE_MENU_CUSTOM_THEME]: (state,action) => {
      state.menuCustomTheme = action.payload;
    },
    [LOGOUT_SUCCESS]: (state) => {
      Object.keys(state).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    [MENU_ORDERING_START]: (state) => {
      state.menuOrder = []
    },
    [MENU_ORDERING]: (state,action) => {
      state.menuOrder = action.payload
    },
    [MENU_DRAFT_CREATE_START]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [MENU_DRAFT_CREATE_SUCCESS]: (state, action) => {
      state.menus.forEach((menu) => {
        if (menu.id === action.payload.menuId) {
          if (menu.drafts) {
            menu.drafts.push(action.payload);
          } else {
            menu.drafts = [action.payload];
          }
        }
      });
      state.loading = false;
      state.error = null;
    },
    [MENU_DRAFT_CREATE_FAILED]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [MENU_DRAFT_DELETE_START]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [MENU_DRAFT_DELETE_SUCCESS]: (state, action) => {
      state.menus.forEach((menu) => {
        if (menu.id === action.payload.menuId) {
          const index = menu.drafts ? menu.drafts.findIndex((draft) => draft.id === action.payload.id) : -1;
          if (index >= 0) {
            menu.drafts.splice(index, 1);
          }
        }
      });
      state.loading = false;
      state.error = null;
    },
    [MENU_DRAFT_DELETE_FAILED]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [UPDATE_TEMP_SELECTED_MENU]: (state,action) => {
      state.tempSelectedMenu = action.payload;
    },
    [SET_IS_LOCKED_THEME]: (state,action) => {
      state.isLockedTheme = action.payload;
    },
  },
);

export default menuReducer;
