/* eslint-disable react-hooks/exhaustive-deps  */
import React, { useState, useEffect } from "react";
import _ from "lodash";
import { getMenuById } from "../../functions/api";
import "./preview.scss";
import { useParams } from "react-router-dom";
import { themes } from "../../assets/themes";
import RestroLogo from "./RestroLogo";
import Sections from "./Sections";
import "./Styles/main.scss";
import LikeBar from './LikeBar'

const styleData = themes.mediterranean;
const artPopImage = 'https://firebasestorage.googleapis.com/v0/b/aboard-dev.appspot.com/o/backgrounds%2FTemplate%202_Grid_Background_colour.jpg?alt=media&token=e31e6c5c-ca45-428c-8748-9de485cfec6f';

const MenuPreview = (props) => {
  const [menuInfo, setMenuInfo] = useState({ sections: [], name: "" });

  const { id } = useParams();

  const style = {
    background: {
      backgroundColor: styleData.background.color,
    },
  };

  useEffect(() => {
    if (_.isEmpty(props.selectedMenu)) {
      getMenuById(id).then((res) => {
        setMenuInfo(res);
      });
    } else {
      setMenuInfo(props.selectedMenu);
    }
  }, [props.selectedMenu]);

  let contentUI = (
    <div>
      <span>{`No Menu found`}</span>
    </div>
  );
  let backgroundStyles = {};

  if (menuInfo && menuInfo.id) {
    const { userDetails } = props;
    if (menuInfo.theme && menuInfo.theme.theme.layout === "float") {
      contentUI = (
        <>
          <RestroLogo
            themeData={menuInfo.theme}
            restaurantName={userDetails.company}
            menuName={menuInfo.name}
            logoTheme={menuInfo.theme.theme.logo}
            logo={userDetails.photoUrl}
            includeLogo={menuInfo.theme.includeLogo}
            layoutClass={`float`}
            imageClass={`logoListingPage`}
          />
          {/* line | linePhoto | centered |centeredPhoto || LIST OF SECTIONS*/}
          {menuInfo && menuInfo.sections ? (
            <Sections
              type={`float`}
              sections={menuInfo.sections}
              country={userDetails.country}
              themeData={menuInfo.theme}
              highLighted={menuInfo.theme.emphasizeHighMargin}
              menuID={menuInfo.id}
            />
          ) : (
            <div>
              <span>
                {!menuInfo.sections || menuInfo.sections.length === 0
                  ? `No Menu found for ${menuInfo.name}`
                  : `loading..`}
              </span>
            </div>
          )}
           <LikeBar theme={menuInfo.theme.theme}/>
        </>
      );
    } else {
      contentUI = (
        <>
          <RestroLogo
            themeData={menuInfo.theme}
            restaurantName={userDetails.company}
            menuName={menuInfo.name}
            logoTheme={menuInfo.theme.theme.logo}
            logo={userDetails.photoUrl}
            includeLogo={menuInfo.theme.includeLogo}
          />
          {/* line | linePhoto | centered |centeredPhoto || LIST OF SECTIONS*/}
          {menuInfo && menuInfo.sections ? (
            <Sections
              type={
                menuInfo.theme && menuInfo.theme.theme.layout
                  ? menuInfo.theme.theme.layout
                  : `linePhoto`
              }
              sections={menuInfo.sections}
              country={userDetails.country}
              themeData={menuInfo.theme}
              highLighted={menuInfo.theme.emphasizeHighMargin}
              menuID={menuInfo.id}
            />
          ) : (
            <div>
              <span>
                {!menuInfo.sections || menuInfo.sections.length === 0
                  ? `No Menu found for ${menuInfo.name}`
                  : `loading..`}
              </span>
            </div>
          )}
         <LikeBar theme={menuInfo.theme.theme}/>
        </>
      );
    }
  }

  if (
    menuInfo &&
    menuInfo.theme &&
    menuInfo.theme.theme &&
    menuInfo.theme.theme.background
  ) {
    let themeData = menuInfo.theme;

    if(themeData.theme.background.color || themeData.theme.background.backgroundImage){
    backgroundStyles = {
      backgroundColor: themeData.theme.background.color,
      backgroundImage: `url(${themeData.theme.background.backgroundImage})`,
      backgroundRepeat: themeData.theme.background.backgroundRepeat,
      backgroundSize: themeData.theme.background.backgroundImage === artPopImage ? 'cover' : 'contain'
    };
  } else {
    // COLOR Scheme Is NOT DEFAULT THEN OVERRIDE COLORS 
    if( themeData.theme.background.color.accent != '#000' &&
    themeData.theme.colors.primary !== "#000" &&
    themeData.theme.colors.secondary !== "#000"
    ){
      backgroundStyles = {
        backgroundColor: themeData.theme.colors.primary,
        backgroundSize: 'contain'
      };
    }
  }
  }

  return (
    <div
      className={`menu ${
        menuInfo &&
        menuInfo.theme &&
        menuInfo.theme.theme &&
        menuInfo.theme.theme.layout === "float"
          ? `floatContainer`
          : ``
      }`}
      style={{ ...style.background, ...backgroundStyles }}
    >
      {contentUI}
    </div>
  );
};

export default MenuPreview;
