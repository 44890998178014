import { auth, database } from "./config";
import * as API from "../functions/api";

export function createUser(uid, data, callback) {
  delete data.password;
  const newUser = { ...data, id: uid, link: uid, bypassEmailValidation: true };
  return database
    .collection("users")
    .doc(uid)
    .set(newUser)
    .then(() => newUser)
    .catch((error) => callback(error));
}

export function getUser(userId, callback) {
  return database
    .collection("users")
    .doc(userId)
    .get()
    .then((doc) => {
      const { exists } = doc;

      // If the user exists in the DB
      let user = null;
      if (exists) user = doc.data();

      const data = { user };
      return data;
    })
    .catch((error) => callback(error));
}

export function updateUser(uid, data, callback) {
  return database
    .collection("users")
    .doc(uid)
    .set({ ...data })
    .then(() => data)
    .catch((error) => callback(error));
}

export function signOut(callback) {
  auth
    .signOut()
    .then(() => { 
      localStorage.setItem("AuthToken", null);
      callback(null)})
    .catch((error) => callback(error));
}

// Create the new user using email and password
export function createUserWithEmailAndPassword(data, callback) {
  const { email, password } = data;
  return auth
    .createUserWithEmailAndPassword(email, password)
    .then((res) => {
      if (!res || !res.user) {
        return callback('Invalid response for user sign up');
      }
      return sendVerificationEmail().then(async () => {
        // SettingToken.
        const authToken =  await API.getcustomtoken(res.user.uid)
        if(authToken.token) await localStorage.setItem("AuthToken", authToken.token);
        return API.getThemes().then((themes) => {
          if (themes) {
            const defaultTheme = themes.find((theme) => theme.id === "Cinema");
            data.menuTheme = {
              includeLogo: false,
              theme: defaultTheme ? defaultTheme.id : "",
              ...defaultTheme
            };
            delete data.menuTheme.id;
          }
          return createUser(res.user.uid, data, callback);
        }).catch((e) => {
          return createUser(res.user.uid, data, callback);
        });
      });
    })
    .catch((error) => callback(error));
}

// Sign the user in with their email and password
export function signInWithEmailAndPassword(data, callback) {
  const { email, password } = data;
  return auth
    .signInWithEmailAndPassword(email, password)
    .then(async (res) => {
      if (!res || !res.user) {
        return callback("Invalid response for user sign in");
      }
      // GETTING & SETTING TOKEN
      const authToken =  await API.getcustomtoken(res.user.uid)
      if(authToken.token) await localStorage.setItem("AuthToken", authToken.token);
      const userResponse = await getUser(res.user.uid, res.user.emailVerified, callback);
      return {	
        user: userResponse.user,	
        emailVerified: res.user.emailVerified	
      }
    })
    .catch((error) => {
      callback(error);
    });
}

// Send reset password email
export function sendPasswordResetEmail(emailAddress) {
  return auth.sendPasswordResetEmail(emailAddress);
}

// Send verification email
export function sendVerificationEmail() {
  // For dev environment change url to `http://localhost:3000/`
  return auth.currentUser.sendEmailVerification({
    url: 'https://app.aboard.menu/'
  })
    .then(function(res) {
      return res;
    })
    .catch(function(error) {
      console.log('error', error);
    });
}