import React from 'react';
import {Backdrop, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 9999,
    color: '#fff'
  }
}));

const Loader = (props) => {
  const { showLoader } = props;
  const classes = useStyles();
  return (
    <Backdrop open={showLoader} className={classes.backdrop}>
      <CircularProgress color='inherit'/>
    </Backdrop>
  );
};

export default Loader;
