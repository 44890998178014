/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import {
  THEMES_GET_ALL_START,
  THEMES_GET_ALL_SUCCESS,
  THEMES_GET_ALL_FAILED,
  LOGOUT_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
  themes: [],
  error: null,
  loading: false,
};

const themeReducer = createReducer(
  initialState,
  {
    [THEMES_GET_ALL_START]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [THEMES_GET_ALL_SUCCESS]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.themes = action.payload;
    },
    [THEMES_GET_ALL_FAILED]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [LOGOUT_SUCCESS]: (state) => {
      Object.keys(state).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
);

export default themeReducer;
