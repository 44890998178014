import { hideLoaderAction, showLoaderAction } from './loaderActions';
import { createAction } from '@reduxjs/toolkit';
import * as API from '../../functions/api';
import {
  ORDERS_FOR_USER_GET_START,
  ORDERS_FOR_USER_GET_SUCCESS,
  ORDERS_FOR_USER_GET_FAILED
} from './actionTypes';

export const getOrdersForUserStart = createAction(ORDERS_FOR_USER_GET_START);
export const getOrdersForUserSuccess = createAction(ORDERS_FOR_USER_GET_SUCCESS);
export const getOrdersForUserFailed = createAction(ORDERS_FOR_USER_GET_FAILED);
export const getOrdersForUser = (userId) => (dispatch) => {
  dispatch(getOrdersForUserStart());
  return API.getOrdersByUser(userId)
    .then((orders) => {
      if (orders) {
        dispatch(getOrdersForUserSuccess(orders));
      }
    })
    .catch((e) => {
      console.error('getordersforuser error: ', e);
      dispatch(getOrdersForUserFailed(e));
    });
};

export const retrieveAllOrders = (userId) => async (dispatch) => {
  dispatch(showLoaderAction());
  await dispatch(getOrdersForUser(userId));
  dispatch(hideLoaderAction());
};
