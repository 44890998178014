/* eslint-disable no-useless-escape */
//no-useless-escape is disable here || It gives warning in Regular expression for email
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Container,
  CssBaseline,
  TextField,
  Toolbar,
  Typography,
  Divider,
  ListItem,
  List,
  ListItemSecondaryAction,
  ListItemText,
  CardMedia,
  Checkbox,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import OrderQueue from "./orderQueue";
import OrderOverview from "./orderOverview";
import moment from "moment";
import NavigationBlockerPrompt from "../../components/NavigationBlockerPrompt";
import { retrieveAllOrders } from "../../store/actions/ordersActions";
import colors from "../../constants/colors";

import "./settings.scss";

const useStyles = makeStyles((theme) => ({
  headerTitle: {
    flexGrow: 2,
    textAlign: "initial",
    paddingLeft: theme.spacing(2),
  },
  mainContainer: {
    margin: theme.spacing(6, 0, 8, 0),
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: 0,
  },
  form: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  subTitleText: {
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.87)",
    padding: theme.spacing(1.5),
  },
  listTitle: {
    lineHeight: "1.3",
    padding: theme.spacing(2, 0),
  },
}));

const opts = {
  height: "390",
  width: "640",
  padding: "5",
  playerVars: {
    autoplay: 0,
  },
};

const Orders = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const userId = useSelector((state) => state.auth.user.id)
  const orders = useSelector((state) => state.orders.orders);

  const [dashboard, setDashboard] = useState("Customer Order Queue");
  const [sortedOrders, setSortedOrders] = useState([]);

  const handleChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDashboard(event.target.value);
  };

  const sortOrders = () => {
    let tempSort = [];
    orders.forEach((element) => {
      if (!element.test) {
        let date = {};
        let splitted = element.pickUpDate.split(" ");
        splitted.forEach((element, i) => {
          if (i===0) date.month = element;
          if (i===1) date.day = element*1;
          if (i===2) {
            date.hour = element[0]*1+12;
            date.minute = element.slice(1, 3)*1;
          }
        });
        let newElement = Object.assign({}, element);
        newElement.pickUpDate = moment({year: 2021, month: 2, day: date.day, hour: date.hour, minute: date.minute, second: 0, millisecond: 0});
        newElement.orderId = element.orderId.slice(element.orderId.length - 8).toUpperCase();
        tempSort.push(newElement); 
      }
    });
    tempSort.sort((a, b) => a.pickUpDate.valueOf() - b.pickUpDate.valueOf());
    setSortedOrders(tempSort);
  };

  useEffect(() => {
    dispatch(retrieveAllOrders(userId));
  }, []);

  useEffect(() => {
    sortOrders();
  }, [orders]);

  return (
    <div>
      <AppBar position="fixed" color="primary">
        <Toolbar>
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={() => history.push("/")}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" className={classes.headerTitle}>
            Order Dashboard
          </Typography>
          <div />
        </Toolbar>
      </AppBar>
      <Container className={classes.mainContainer} maxWidth="xl">
        <FormControl
          variant="filled"
          className={classes.inputContainer}
          fullWidth
          margin="normal"
        >
          <InputLabel id="dashboardType">Dashboard</InputLabel>
          <Select
            id="dashboardType"
            name="dashboardType"
            value="Customer Order Queue"
            className={classes.selectInput}
            onChange={handleChange}
          >
            <MenuItem value="Customer Order Queue">
              Customer Order Queue
            </MenuItem>
            {/* <MenuItem value="Orders Overview">Orders Overview</MenuItem> */}
          </Select>
        </FormControl>
        {sortedOrders.length>0 ? (
          dashboard === "Customer Order Queue" ? (
            <OrderQueue orders={sortedOrders} />
          ) : (
            <OrderOverview orders={sortedOrders} />
          )
        ) : (
          ""
        )}
      </Container>
    </div>
  );
};

export default Orders;
