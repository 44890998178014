/* eslint-disable react-hooks/exhaustive-deps  */
//react-hooks/exhaustive-deps is disable here || It gives warning in useEffect has a missing dependency
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  IconButton,
  Typography,
  Toolbar,
  AppBar,
  FormControl,
  TextField,
  Button,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createMenu,
  deleteMenu,
  menuOrdering,
  updateMenu
} from "../../store/actions/menuActions";
import { showPopupAction } from "../../store/actions/popupActions";
import _ from "lodash";
import colors from "../../constants/colors";
import {updateUser} from "../../store/actions/authActions";
import {hideLoaderAction, showLoaderAction} from "../../store/actions/loaderActions";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: "8px 0",
    minWidth: 120,
  },
  root: {
    flexGrow: 1,
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
  title: {
    flexGrow: 2,
  },
  footerWapper: {
    background: colors.primary,
    padding: "1.3%",
    color: colors.white,
    fontWeight: "bold",
    marginTop: "2%",
    position: "fixed",
    bottom: "0",
    width: "100%",
    cursor: "pointer",
  },
  duplicateButton: {
    marginTop: theme.spacing(2),
    minWidth: '100px',
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export default function EditSection(props) {
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    id: "",
    name: ""
  });
  const [error, setError] = useState({});

  const userDetails = useSelector((state) => state.auth.user);
  const menuOrderingState = useSelector((state) => state.menu.menuOrder);
  const menusData = useSelector((state) => state.menu.menus);
  const isFreePlan = useSelector((state) => state.stripe.isFreePlan);
  const isContactTracingPlan = useSelector((state) => state.stripe.isContactTracingPlan);

  // Call Once for Setting State Data
  useEffect(() => {
    if (props.location.state) {
      setForm(props.location.state);
    }
  }, [props.location]);

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    delete error[name];
    setForm({ ...form, [name]: value });
    setError(error);
  };

  const validateMenu = () => {
    const requiredFields = ["name"];
    let errors = {};
    requiredFields.forEach((fieldName) => {
      if (!form[fieldName] || _.isEmpty(form[fieldName].trim())) {
        errors[fieldName] = `Please enter ${fieldName}`;
      }
    });
    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateMenu();
    if(_.isEmpty(validationErrors)){
      dispatch(showLoaderAction());
      dispatch(updateMenu(form.id, form, "", (error) => {
        dispatch(hideLoaderAction());
        if (error) {
          dispatch(showPopupAction({
            showPopup: true,
            popupType: "error",
            header: "Error!",
            message: "Error occurred while updating the menu name. Please try again!"
          }));
        } else {
          history.push("/menu-settings");
        }
      }));
    } else {
      setError(validationErrors);
    }
  };

  const handleDeleteMenu = async (menuId) => {
    await dispatch(deleteMenu(menuId));
    if (
      userDetails &&
      userDetails.menuOrder &&
      userDetails.menuOrder.length > 0
    ) {
      let newMenuOrdering = (menuOrderingState && menuOrderingState.length > 0)? _.cloneDeep(menuOrderingState) : _.cloneDeep(userDetails.menuOrder);
      const index = newMenuOrdering.findIndex((order) => order === menuId);
      if (index >= 0) {
        newMenuOrdering.splice(index, 1);
      }
      let updatedUserDetails = _.cloneDeep(userDetails);
      updatedUserDetails.menuOrder = newMenuOrdering
      dispatch(updateUser(userDetails.id,updatedUserDetails,(error) => {
        if (error) {
          history.push("/menu-settings");
          return false;
        }
        dispatch(menuOrdering(newMenuOrdering));
        history.push("/menu-settings");
        return true;
      }))
    } else {
      history.push("/menu-settings");
    }
  };

  const handleDeleteMenuClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(
      showPopupAction({
        showPopup: true,
        popupType: "confirmation",
        header: "Delete Menu",
        message: `Are you sure you want to delete ${form.name} menu?`,
        selectedId: form.id,
        handleConfirm: handleDeleteMenu,
      })
    );
  };

  const handleDuplicateMenu = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const exampleMenu = menusData.find((menu) => menu.name === 'Example Menu');
    const maxAllowedMenus = exampleMenu ? 3 : 2;

    if ((isFreePlan || isContactTracingPlan) && menusData.length >= maxAllowedMenus) {
      dispatch(showPopupAction({ showPopup: true, message: 'Please upgrade your plan to add more menus!' }));
    } else {
      const originalMenu = menusData.find((menu) => menu.id === form.id);
      let newMenu = _.cloneDeep(originalMenu);
      newMenu.name = newMenu.name.trim() + '-copy';
      newMenu.toggle = false;
      newMenu.checkout_link = newMenu.checkout_link || false;

      delete newMenu.id;

      dispatch(showLoaderAction());
      await dispatch(createMenu(newMenu, false, history));
      history.push("/menu-settings");
      dispatch(hideLoaderAction());
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={() => history.push("/menu-settings")}
          >
            <ArrowBackIosIcon />
          </IconButton>

          <Typography variant="h6" className={classes.title}>
            Edit Menu
          </Typography>
          {
            props.location.state && <IconButton
              color="inherit"
              onClick={handleDeleteMenuClick}
            >
              <DeleteIcon />
            </IconButton>
          }
          <div></div>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm">
        <form className="itemForm" onSubmit={(event) => {handleSubmit(event)}}>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              autoComplete="off"
              id="filled-basic"
              label="Name"
              variant="filled"
              name="name"
              value={form.name || ""}
              onChange={handleOnChange}
              error={!_.isEmpty(error.name)}
              helperText={error.name}
            />
          </FormControl>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              align="center"
              className={classes.duplicateButton}
              onClick={handleDuplicateMenu}
            >
              Duplicate Menu
            </Button>
          </div>
        </form>
      </Container>
      <div className={classes.footerWapper}>
        <div
          className="footerAction"
          onClick={(event) => {handleSubmit(event)}}
        >
          <Typography variant="h6" className={classes.title}>
            Update Menu
          </Typography>
        </div>
      </div>
    </div>
  );
}
