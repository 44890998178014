import { createAction } from '@reduxjs/toolkit';
import {
  SHOW_LOADER,
  HIDE_LOADER,
} from './actionTypes';

export const showLoader = createAction(SHOW_LOADER);
export const showLoaderAction = () => (dispatch) => {
  dispatch(showLoader());
};

export const hideLoader = createAction(HIDE_LOADER);
export const hideLoaderAction = () => (dispatch) => {
  dispatch(hideLoader());
};
