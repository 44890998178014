/* eslint-disable no-useless-escape */
//no-useless-escape is disable here || It gives warning in Regular expression for email
import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar,
  Button,
  Container,
  CssBaseline,
  IconButton,
  TextField,
  Toolbar,
  Typography,
  Grid,
  Divider,
  ListItem,
  List,
  CardMedia,
  Checkbox
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import moment from 'moment';
import SettingsIcon from '@material-ui/icons/Settings';

import PlaceHolderLogo from '../../assets/images/placeHolderLogo.svg';
import { updateUser, logout } from '../../store/actions/authActions';
import { uploadLogo } from '../../firebase/menu';
import { validateLink } from '../../functions/api';
import NavigationBlockerPrompt from "../../components/NavigationBlockerPrompt";
import { showLoaderAction } from "../../store/actions/loaderActions";
import colors from '../../constants/colors';
import YouTube from 'react-youtube';

import './settings.scss';

const useStyles = makeStyles((theme) => ({
  headerTitle: {
    flexGrow: 2,
    textAlign: 'initial',
    paddingLeft: theme.spacing(2),
  },
  mainContainer: {
    margin: theme.spacing(10, 0, 8, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  form: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  inputText: {
    margin: theme.spacing(0, 0, 2, 0),
    '& .MuiInputBase-root-122': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  subTitleText: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: theme.spacing(1.5),
  },
  listTitle: {
    lineHeight: '1.3',
    padding: theme.spacing(2, 0),
  }
}));

const opts = {
  height: '390',
  width: '640',
  padding: '5',
  playerVars: {
    autoplay: 0,
  },
};

const Tutorials = ({ history }) => {
  const classes = useStyles();

  return (
    <div>
      <AppBar position="fixed" color="primary">
        <Toolbar>
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={() => history.push('/')}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" className={classes.headerTitle}>
            Tutorials
          </Typography>
          <div />
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.mainContainer}>
          <div className="titleWapper">
            <Typography variant="h6" className={classes.listTitle}>
              Welcome to Aboard!
            </Typography>
          </div>
          <YouTube videoId="fFxA8ne2SLo" opts={opts}/>
          <div className="titleWapper">
            <Typography variant="h6" className={classes.listTitle}>
              Menu Sections Tutorial: What are they and how to use them.
            </Typography>
          </div>
          <YouTube videoId="qV1j_dGSCg0" opts={opts}/>
          <div className="titleWapper">
            <Typography variant="h6" className={classes.listTitle}>
              Menu Items Tutorial: What are they and how to use them.
            </Typography>
          </div>
          <YouTube videoId="6xWeaJ4RIiA" opts={opts}/>
          <div className="titleWapper">
            <Typography variant="h6" className={classes.listTitle}>
              Menu Templates Tutorial
            </Typography>
          </div>
          <YouTube videoId="xP8VDYehFCI" opts={opts}/>
          <div className="titleWapper">
            <Typography variant="h6" className={classes.listTitle}>
              Menu Customization Tutorial
            </Typography>
          </div>
          <YouTube videoId="A-4F0qABrWM" opts={opts}/>
          <div className="titleWapper">
            <Typography variant="h6" className={classes.listTitle}>
              Account Settings Tutorial
            </Typography>
          </div>
          <YouTube videoId="kkTXm45sBZ0" opts={opts}/>
          <div className="titleWapper">
            <Typography variant="h6" className={classes.listTitle}>
              Menu Settings Tutorial: How to update your main page.
            </Typography>
          </div>
          <YouTube videoId="9ue2770VpY8" opts={opts}/>
          <div className="titleWapper">
            <Typography variant="h6" className={classes.listTitle}>
              Contact Tracing Tutorial: Set-up and how to submit requests.
            </Typography>
          </div>
          <YouTube videoId="ysk2xI0we-M" opts={opts}/>
          <div className="titleWapper">
            <Typography variant="h6" className={classes.listTitle}>
              Billing Information Tutorial
            </Typography>
          </div>
          <YouTube videoId="927vxjVUT3w" opts={opts}/>
          <div className="titleWapper">
            <Typography variant="h6" className={classes.listTitle}>
              Drafts Tutorial: What are they and why do you need them.
            </Typography>
          </div>
          <YouTube videoId="MkxdxyhB8ME" opts={opts}/>
        </div>
      </Container>
    </div>
  );
};

export default Tutorials;
