/* eslint-disable react-hooks/exhaustive-deps  */
//react-hooks/exhaustive-deps is disable here || It gives warning in useEffect has a missing dependency
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  IconButton,
  Typography,
  Toolbar,
  AppBar,
  FormControl,
  TextField,
  FormControlLabel, InputLabel, Select, MenuItem, FormHelperText,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { menuListUpdated, menuSectionDeleted } from "../../store/actions/menuActions";
import { showPopupAction } from "../../store/actions/popupActions";
import _ from "lodash";
import colors from "../../constants/colors";
import presetData from "../../constants/presetData.json";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: "8px 0",
    minWidth: 120,
  },
  root: {
    flexGrow: 1,
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
  title: {
    flexGrow: 2,
  },
  proOnlyText: {
    alignSelf: 'right',
    paddingLeft: theme.spacing(1)
  },
  proOnlyTextContainer: {
    justifyContent: 'space-between'
  },
  footerWapper: {
    background: colors.primary,
    padding: "1.3%",
    color: colors.white,
    fontWeight: "bold",
    marginTop: "2%",
    position: "fixed",
    bottom: "0",
    width: "100%",
    cursor: "pointer",
  },
}));

export default function EditSection(props) {
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    name: "",
    type: "",
    desc: "",
    toggle: true
  });
  const [error, setError] = useState({});
  const selectedMenu = useSelector((state) => state.menu.selectedMenu);
  const isFreePlan = useSelector((state) => state.stripe.isFreePlan);
  const isContactTracingPlan = useSelector((state) => state.stripe.isContactTracingPlan);

  // Call Once for Setting State Data
  useEffect(() => {
    if (props.location.state) {
      setForm(props.location.state);
    }
  }, [props.location]);

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    if (name === "toggle") {
      setForm({ ...form, toggle: event.target.checked });
    } else {
      delete error[name];
      setForm({ ...form, [name]: value });
      setError(error);
    }
  };

  const editSection = (update,event) => {
    let sections = selectedMenu.sections || [];
    let updatedSection = [];
    if(event === "edit") {
      sections.forEach((value, index) => {
        if (value.id === update.id) {
          updatedSection.push({
            name: update.name,
            toggle: update.toggle,
            type: update.type,
            desc: update.desc,
            id: value.id,
            items: value.items,
          });
        } else {
          updatedSection.push(value);
        }
      });
      return updatedSection;
  } else if (event === "add"){
      sections.forEach((value, index) => {
        updatedSection.push(value);
      });
      updatedSection.push({
        name: update.name,
        toggle: update.toggle,
        type: update.type,
        id: update.id,
        desc: update.desc,
        items: [],
      });
    return updatedSection;
  } else {
      return false;
    }
  };

  const validateSection = () => {
    const requiredFields = ["name", "type"];
    let errors = {};
    requiredFields.forEach((fieldName) => {
      if (!form[fieldName] || _.isEmpty(form[fieldName].trim())) {
        errors[fieldName] = `Please enter ${fieldName.toLowerCase()}`;
      }
    });
    return errors;
  };

  const handleSubmit = (event, state) => {
    event.preventDefault();
    const validationErrors = validateSection();
    if(_.isEmpty(validationErrors)){
    if (state === "edit") {
      let update = {
        name: form.name,
        toggle: form.toggle,
        type: form.type,
        desc: form.desc,
        id: props.location.state.id,
      };
      let updatedSection = editSection(update, state);
      dispatch(menuListUpdated(updatedSection));
      history.push("/update-menu");
    } else if (state === "add") {
      let add = {
        name: form.name,
        type: form.type,
        desc: form.desc,
        toggle: (form.toggle) ? form.toggle : false,
        id: uuidv4()
      };
      let newSection = editSection(add, state)
      dispatch(menuListUpdated(newSection));
      history.push("/update-menu");
    }
  } else {
      setError(validationErrors);
  }
  };

  const handleDeleteSectionClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(
      showPopupAction({
        showPopup: true,
        popupType: "confirmation",
        header: "Delete Section",
        message: form.totalItems
          ? `Are you sure you want to delete ${form.name} section with ${form.totalItems} items?`
          : `Are you sure you want to delete ${form.name} section?`,
        selectedId: form.id,
        handleConfirm: handleDeleteSection
      })
    );
  };

  const handleDeleteSection = (selectedId) => {
    dispatch(menuSectionDeleted(selectedId, history));
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={() => history.push("/update-menu")}
          >
            <ArrowBackIosIcon />
          </IconButton>

          <Typography variant="h6" className={classes.title}>
            {props.location.state ? `Edit Section` : `Add Section`}
          </Typography>
          {
            props.location.state && <IconButton
              color="inherit"
              onClick={handleDeleteSectionClick}
            >
              <DeleteIcon />
            </IconButton>
          }
          <div></div>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm">
        <form className="itemForm" onSubmit={(event) => {handleSubmit(event, props.location.state ? `edit` : `add`)}}>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              autoComplete="off"
              id="filled-basic"
              label="Name"
              variant="filled"
              name="name"
              value={form.name || ""}
              onChange={handleOnChange}
              error={!_.isEmpty(error.name)}
              helperText={error.name}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="filled"
            required={true}
            className={classes.formControl}
            error={!_.isEmpty(error.type)}
          >
            <InputLabel id="filled-label">Type</InputLabel>
            <Select
              labelId="filled-label"
              id="section-type-select-filled"
              name="type"
              value={form.type}
              onChange={handleOnChange}
            >
              {
                _.map(presetData.sectionTypes, (typeName, typeKey) => (
                  (isFreePlan || isContactTracingPlan) && typeKey === 'wine'
                    ? <MenuItem key={typeKey} value={typeKey} disabled={true} className={classes.proOnlyTextContainer}>
                      {typeName}
                      <Typography color='primary' className={classes.proOnlyText}> Premium only</Typography>
                  </MenuItem>
                    : <MenuItem key={typeKey} value={typeKey}>{typeName}</MenuItem>
                ))
              }
            </Select>
            {!_.isEmpty(error.type) && (
              <FormHelperText id="component-error-text">
                {error.type}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              id="filled-basic"
              label="Description"
              variant="filled"
              name="desc"
              onChange={handleOnChange}
              value={form.desc}
              error={!_.isEmpty(error.desc)}
              helperText={error.desc}
            />
          </FormControl>
          <FormControl fullWidth className={classes.margin}>
            <FormControlLabel
              control={
                <Checkbox
                  name="toggle"
                  checked={form.toggle || false}
                  className="menu-checkbox"
                  onChange={handleOnChange}
                />
              }
              label={`Show In Menu`}
            />
          </FormControl>
        </form>
      </Container>
      <div className={classes.footerWapper}>
        <div
          className="footerAction"
          onClick={(event) => {handleSubmit(event, props.location.state ? `edit` : `add`)}}
        >
          <Typography variant="h6" className={classes.title}>
            {props.location.state ? `Update Section` : `Add Section`}
          </Typography>
        </div>
      </div>
    </div>
  );
}
