/* eslint-disable no-useless-escape */
//no-useless-escape is disable here || It gives warning in Regular expression for email
import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar,
  Button,
  Container,
  CssBaseline,
  IconButton,
  TextField,
  Toolbar,
  Typography,
  Grid,
  Divider,
  ListItem,
  List,
  CardMedia,
  Checkbox
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import moment from 'moment';
import SettingsIcon from '@material-ui/icons/Settings';

import PlaceHolderLogo from '../../assets/images/placeHolderLogo.svg';
import { updateUser, logout } from '../../store/actions/authActions';
import { uploadLogo } from '../../firebase/menu';
import { validateLink } from '../../functions/api';
import NavigationBlockerPrompt from "../../components/NavigationBlockerPrompt";
import { showLoaderAction } from "../../store/actions/loaderActions";
import colors from '../../constants/colors';

import './settings.scss';
import {hidePopupAction, showPopupAction} from "../../store/actions/popupActions";

const useStyles = makeStyles((theme) => ({
  headerTitle: {
    flexGrow: 2,
    textAlign: 'initial',
    paddingLeft: theme.spacing(2),
  },
  mainContainer: {
    margin: theme.spacing(10, 0, 8, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  inputText: {
    margin: theme.spacing(0, 0, 2, 0),
    '& .MuiInputBase-root-122': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  subTitleText: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: theme.spacing(1.5),
  },
  helperLink: {
    marginTop: theme.spacing(2),
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '1.25px',
    textDecoration: 'none',
    color: colors.primary,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  subscriptionDetailsText: {
    marginTop: theme.spacing(1),
    letterSpacing: '1.25px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  logo: {
    height: '100%',
    width: '150px',
    objectFit: 'cover',
    margin: 'auto',
  },
  disclaimer: {
    fontStyle: 'italic',
    fontSize: 'xx-small',
    color: 'rgba(0,0,0,0.5)'
  },
  checkBoxContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(1),
  },
  checkBoxText: {
    margin: theme.spacing(1)
  }
}));

const Settings = ({ history }) => {
  const userData = useSelector((state) => state.auth.user);
  const stripeData = useSelector((state) => state.stripe.data);
  const isFreePlan = useSelector((state) => state.stripe.isFreePlan);
  const isContactTracingPlan = useSelector((state) => state.stripe.isContactTracingPlan);
  const currentSubscriptionDetails = stripeData ? stripeData.subscriptions.data[0] : {};
  const currentSubscriptionPlan =
  (currentSubscriptionDetails && currentSubscriptionDetails.plan && currentSubscriptionDetails.plan.metadata)
    ? currentSubscriptionDetails.plan.metadata.planName
    : '';
  const nextBillingDate = (currentSubscriptionDetails && currentSubscriptionDetails.current_period_end)
    ? moment.unix(currentSubscriptionDetails.current_period_end).format('MMMM Do, YYYY')
    : "";
  const isTrial = !!(currentSubscriptionDetails && (currentSubscriptionDetails.status === 'trialing'));
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    userDetails: userData,
    errors: {},
  });
  const [imagePreviewURL, setImagePreviewURL] = useState('');
  const [isUpdated, setIsUpdated] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setValues({
      userDetails: userData,
      errors: values.errors
    });
  }, [userData, values.errors]);

  useEffect(() => {
    setIsUpdated(!_.isEqual(values.userDetails, userData))
  }, [values.userDetails, userData]);

  const handleChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { name, value } = event.target;
    const { errors, userDetails } = values;
    delete errors[name];
    setValues({ ...values, errors, userDetails: { ...userDetails, [name]: value } });
  };

  const handleEnableContactTracingPlan = () => {
    history.push('/billing-settings', {planType: 'contactTracing', nextPage: '/settings'});
    dispatch(hidePopupAction());
  }

  const handleContactTracingCheckBoxClick = (event) => {
    const { name, checked } = event.target;
    const { userDetails } = values;
    if (isFreePlan) {
      dispatch(showPopupAction({
        showPopup: true,
        message: `Do you want to check out our contact-tracing plan?`,
        handleConfirm: handleEnableContactTracingPlan
      }));
    } else {
      setValues({ ...values, userDetails: { ...userDetails, [name]: checked } });
    }
  };

  const validate = () => {
    const contactNumberRegExp = /^([0-9]{10})$/gm;
    const requiredFields = ['name', 'company', 'link'];
    const { userDetails, errors } = values;
    requiredFields.forEach((fieldName) => {
      if (!_.isUndefined(userDetails[fieldName]) && _.isEmpty(userDetails[fieldName].trim())) {
        errors[fieldName] = `Please enter valid ${fieldName}`;
      }
    });
    if (!_.isUndefined(userDetails.phone) && !contactNumberRegExp.test(userDetails.phone)) {
      errors.phone = 'Phone number must contain 10 digits';
    }
    return errors;
  };

  const handleApplyChanges = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const validationErrors = validate();
    if (_.isEmpty(validationErrors)) {
      const updatedUserDetails = {...values.userDetails};
      if (!_.isNil(values.logo)) {
        dispatch(showLoaderAction());
        uploadLogo(values.logo, updatedUserDetails.id).then((photoUrl) => {
          updatedUserDetails.photoUrl = photoUrl;
          dispatch(updateUser(updatedUserDetails.id, updatedUserDetails, () => {}));
        }).catch((e) => {
          updatedUserDetails.photoUrl = ``;
          dispatch(updateUser(updatedUserDetails.id, updatedUserDetails, () => {}));
        });
      } else {
        dispatch(updateUser(updatedUserDetails.id, updatedUserDetails, () => {}));
      }
    } else {
      setValues({ ...values, errors: validationErrors });
    }
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const previewURL = URL.createObjectURL(event.target.files[0]);
      setValues({
        ...values,
        logo: event.target.files[0],
      });
      setImagePreviewURL(previewURL);
      setIsUpdated(true)
    }
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const validateUserLink = (event) => {
    event.preventDefault();
    const { userDetails: { id, link }, errors} = values;
    validateLink({userId: id, link}).then((res) => {
      if (!(res && res.data && res.data.result)) {
        errors.link = "Link is already taken!";
        setValues({ ...values, errors });
      }
    }).catch((error) => {
      let message = "Invalid link!";
      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message;
      }
      errors.link = message;
      setValues({ ...values, errors });

    });
  };

  const {
    userDetails: {
      name, company, email, phone, link, fbPixel, photoUrl, collectContactTracingInfo
    }, errors,
  } = values;
  return (
    <div>
      <NavigationBlockerPrompt
        navigationBlocked={isUpdated}
        message="All your unsaved changes will be lost. Are you sure you want to leave?"
      />
      <AppBar position="fixed" color="primary">
        <Toolbar>
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={() => history.push('/')}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" className={classes.headerTitle}>
            Settings
          </Typography>
          <div />
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.mainContainer}>
          <form className={classes.form}>
            <CardMedia
              component="img"
              alt="logo"
              className={classes.logo}
              image={imagePreviewURL || (photoUrl || PlaceHolderLogo)}
              title="Logo"
            />
            <div>
            <Typography> <span className={classes.disclaimer}>logo preferred 360x120 pixels</span> </Typography>
            <Button component="label" color="primary" className={classes.button}>
              upload logo
              <input type="file" onChange={onImageChange} style={{ display: 'none' }} />
            </Button>
            </div>
            <TextField
              variant="outlined"
              fullWidth
              id="company"
              label="Restaurant Name"
              name="company"
              placeholder="Company"
              value={company}
              onChange={handleChange}
              error={!_.isEmpty(errors.company)}
              helperText={errors.company}
              className={classes.inputText}
            />
            <TextField
              variant="outlined"
              fullWidth
              id="name"
              label="Contact Name"
              name="name"
              placeholder="Contact Name"
              value={name}
              onChange={handleChange}
              error={!_.isEmpty(errors.name)}
              helperText={errors.name}
              className={classes.inputText}
            />
            <TextField
              variant="outlined"
              fullWidth
              id="email"
              label="Email"
              placeholder="Email"
              name="email"
              value={email}
              disabled={true}
              className={classes.inputText}
            />
            <TextField
              variant="outlined"
              margin="normal"
              type="number"
              fullWidth
              id="phone"
              label="Contact Number"
              placeholder="Contact Number"
              name="phone"
              value={phone}
              onChange={handleChange}
              error={!_.isEmpty(errors.phone)}
              helperText={errors.phone}
              className={`contactNumber ${classes.inputText}`}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="link"
              label="Link"
              placeholder="Link"
              name="link"
              value={link}
              disabled={isFreePlan || isContactTracingPlan}
              onChange={handleChange}
              onBlur={validateUserLink}
              error={!_.isEmpty(errors.link)}
              helperText={errors.link}
              className={classes.inputText}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="fbPixel"
              label="Facebook Pixel ID"
              placeholder="Facebook Pixel ID"
              name="fbPixel"
              value={fbPixel}
              disabled={isFreePlan || isContactTracingPlan}
              onChange={handleChange}
              error={!_.isEmpty(errors.fbPixel)}
              helperText={errors.fbPixel}
              className={classes.inputText}
            />
            <div className={classes.checkBoxContainer}>
              <Checkbox
                checked={collectContactTracingInfo || false}
                onChange={handleContactTracingCheckBoxClick}
                name="collectContactTracingInfo"
                color="primary"
              />
              <Typography
                variant="subtitle1"
                gutterBottom
                className={classes.checkBoxText}
              >
                Collect Contact Tracing Info
              </Typography>
            </div>
            <Button
              color="primary"
              disabled={!isUpdated}
              onClick={handleApplyChanges}
            >
              Apply changes
            </Button>
          </form>
          <Grid container className={classes.subTitleGroup}>
            <Grid item xs>
              <Typography variant="subtitle1" component="h6" align="left" className={classes.subTitleText}>Subscription & Billing</Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                onClick={() => history.push('/billing-settings', {nextPage: '/settings'})}
                color="default"
              >
                <SettingsIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12}>
              <List>
                <Divider component="li" />
                <ListItem role={undefined} dense>
                  <Typography variant="body2" id="currentSubscription" className={classes.subscriptionDetailsText}>
                    Current Subscription: {isFreePlan?`Free Plan`:(currentSubscriptionPlan||'Free Trial')}
                  </Typography>
                </ListItem>
                <ListItem role={undefined} dense>
                  <Typography variant="body2" id="nextBilling" className={classes.subscriptionDetailsText}>
                    {
                      (currentSubscriptionDetails && isTrial)?
                        ((currentSubscriptionDetails && currentSubscriptionDetails.cancel_at)
                          ?`Free Trial Ends ${nextBillingDate}`
                          :`Next Billing: ${nextBillingDate}`
                        )
                        : `Valid until: ${nextBillingDate || 'Forever'}`
                    }
                  </Typography>
                </ListItem>
                <Divider component="li" />
              </List>
            </Grid>
          </Grid>
          <Button variant="outlined" color="primary" className={classes.button} onClick={handleLogout}>
            LOG OUT
          </Button>
          <a href="mailto:help@aboard.menu" className={classes.helperLink}>Need help?</a>
          <a href="mailto:tracingrequests@aboard.menu?subject=Contact tracing request&body=Please provide your Aboard account email and requested contact tracing date/time" className={classes.helperLink}>Request Contact Trace Info</a>
        </div>
      </Container>
    </div>
  );
};

export default Settings;
