import { database, storage, firebaseConfig } from './config';
import * as API from "../functions/api";

export function createMenu(uid, data, callback) {
  return database
    .collection('menus')
    .doc(uid)
    .set({ ...data, id: uid })
    .then(() => data)
    .catch((error) => callback(error));
}

export function getMenu(menuId, callback) {
  return database
    .collection('menus')
    .doc(menuId)
    .get()
    .then((doc) => {
      let menu = null;
      const { exists } = doc;

      // If the user exists in the DB
      if (exists) menu = doc.data();

      return menu;
    })
    .catch((error) => callback(error));
}

export function updateMenu(uid, data, callback) {
  return database
    .collection('menus')
    .doc(uid)
    .set(data)
    .then(() => data)
    .catch((error) => callback(error));
}

export function deleteMenu(menuId, callback) {
  return database
    .collection('menus')
    .doc(menuId)
    .delete()
    .then(() => true)
    .catch((error) => callback(error));
}

// Function to upload image on firebase storage under images/userId folder
export function uploadImage(imageAsFile, userId) {
  return new Promise((resolve, reject) => {
    const timeStamp = new Date().getTime();
    const imageName = timeStamp + '-' + imageAsFile.name;
    const uploadTask = storage.ref(`/images/${userId}/${imageName}`).put(imageAsFile);
    uploadTask.on('state_changed', () => {}, (error) => {
      return reject(error);
    }, () => {
      storage.ref(`/images/${userId}`).child(imageName).getDownloadURL()
        .then(fireBaseUrl => {
          return resolve(fireBaseUrl);
        })
    })
  })
}

// Function to upload menu file on firebase storage under menus/userId folder
export function uploadMenuFile(menuFile, userId, delimiter, priceLocation) {
  return new Promise((resolve, reject) => {
    const menuFileTask = storage.ref(`/menus/${userId}/${menuFile.name}`).put(menuFile);
    menuFileTask.on('state_changed', () => {}, (error) => {
      return reject(error);
    }, () => {
      const firebaseUrl = `gs://${firebaseConfig.storageBucket}/menus/${userId}/${menuFile.name}`;
      return API.parseMenu(firebaseUrl, delimiter, priceLocation).then((data) => {
        return resolve(data.response);
      }).catch((error) => {
        return reject(error);
      });
    });
  });
}

// Function to upload restaurant logo on firebase storage under logos/userId folder
export function uploadLogo(imageAsFile, userId) {
  return new Promise((resolve, reject) => {
    const timeStamp = new Date().getTime();
    const logoName = timeStamp + '-' + imageAsFile.name;
    const uploadTask = storage.ref(`/logos/${userId}/${logoName}`).put(imageAsFile);
    uploadTask.on('state_changed',
      () => {}, (error) => {
        return reject(error);
      }, () => {
        storage.ref(`/logos/${userId}`).child(logoName).getDownloadURL()
          .then(fireBaseUrl => {
            return resolve(fireBaseUrl);
          })
      })
  })
}

// Function to upload image on firebase storage under customBackgrounds/userId folder
export function uploadBackgroundImage(imageAsFile, userId) {
  return new Promise((resolve, reject) => {
    const timeStamp = new Date().getTime();
    const imageName = timeStamp + '-' + imageAsFile.name;
    const uploadTask = storage.ref(`/customBackgrounds/${userId}/${imageName}`).put(imageAsFile);
    uploadTask.on('state_changed', () => {}, (error) => {
      return reject(error);
    }, () => {
      storage.ref(`/customBackgrounds/${userId}`).child(imageName).getDownloadURL()
        .then(fireBaseUrl => {
          return resolve(fireBaseUrl);
        })
    })
  })
}