import React from "react";

const MenuBlock = (props) => {
  let backgroundStyles = {}
  let font = {}
  let boxBorder = {}

  if (props.theme) {
    if (props.theme.cardBackground) {
      backgroundStyles = {
        backgroundColor: props.theme.cardBackground.color,
        backgroundImage: `url(${props.theme.cardBackground.backgroundImage})`,
        backgroundRepeat: props.theme.cardBackground.backgroundRepeat
      };
    } else if(props.theme.theme === "custom" && props.theme.colors) {
      // If Color Scheme is set
      backgroundStyles.backgroundColor = props.theme.colors.primary
    }  else {
      backgroundStyles.backgroundColor = '#fff';
    }

    if (props.theme.cardBorder) {
      boxBorder = { border: `${props.theme.cardBorder.borderWidth} ${props.theme.cardBorder.borderStyle} ${props.theme.cardBorder.borderColor}` };
    } else if(props.theme.theme === "custom" && props.theme.colors) {
      // If Color Scheme is set
      boxBorder.borderColor = props.theme.colors.accent;
    } else {
      boxBorder.borderColor = '#000';
    }

    if (props.theme.fonts && props.theme.fonts.section) {
      font = { ...props.theme.fonts.section };
    } else if(props.theme.theme === "custom" && props.theme.colors) {
      font.color = props.theme.colors.secondary;
    } else {
      font.color = '#000';
    }
  }

  return (
    <div className={`blockWapper ${props.wapper ? props.wapper : ``}`} style={{ ...backgroundStyles, ...boxBorder }}>
      <div className="blockBox">
        <h3 className={`h3Text menuBlockTitle ${
          props.textClass ? props.textClass : ``
          }`}
          style={font} >
          {props.name}
        </h3>
      </div>
    </div>
  );
};

export default MenuBlock;
