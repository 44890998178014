import React, { useState } from 'react';
import {pdfjs} from "react-pdf";
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import {Grid, IconButton, Typography} from '@material-ui/core';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  pdfTitle: {
    alignSelf: 'start',
    paddingLeft: '16px'
  },
  'pdf-preview-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
    margin: '10px 0',
    '& .pdf-preview-load': {
      marginTop: '1em',
      color: 'white'
    },
    '& .pdf-preview-document': {
      margin: '1em 0'
    },
    '& .react-pdf__Document': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    '& .react-pdf__Page': {
      maxWidth: 'calc(100% - 2em)',
      boxShadow: '0 0 8px rgba(0, 0, 0, .5)',
      margin: '1em',

      '& canvas': {
        maxWidth: '100%',
        height: 'auto !important'
      }
    },
    '& .react-pdf__message': {
      padding: '20px',
      color: 'white'
    },
    '& .react-pdf__Page__textContent': {
      top: '0 !important',
      left: '0 !important'
    },
    '& .pagination-container':{
      margin: 'auto',
      '& button': {
        padding: 0
      }
    }
  }
}));

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFPreviewComponent = (props) => {
  const [ numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
  };
  const classes = useStyles();

  const onDocumentLoadSuccess = (props) => {
    setNumPages(props.numPages);
    setPageNumber(1);
  }

  const changePage = (offset) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  const previousPage = (event) => {
    event.preventDefault();
    event.stopPropagation();
    changePage(-1);
  }

  const nextPage = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setPageNumber(pageNumber + 1);
  }

  return (
    <div className={classes["pdf-preview-container"]}>
      <p></p>
      <p className={classes.pdfTitle}>{props.file.name}</p>
      <div className="pdf-preview-document">
        <Document file={props.file} onLoadSuccess={onDocumentLoadSuccess} options={options}>
          <Page pageNumber={pageNumber} />
        </Document>
      </div>
      <div className="pagination-container">
        <Grid container>
          <Grid item>
            <IconButton
              id="previous-button"
              color="inherit"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              <ArrowBackIosIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant="body2">{pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              id="next-button"
              color="inherit"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default PDFPreviewComponent;