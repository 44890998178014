import React from "react";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import {Grid} from "@material-ui/core";

const Item = React.forwardRef((props, ref) => {
  let backgroundStyles = {};
  let lineAfter = {};
  let boxBorder = {};
  let hmItemBorder = {};
  let hmItemBackground = {};
  let currenySign = "";
  let decimals = 0;

  if (props.cardStyle) {
    if (props.cardStyle.background) {
      backgroundStyles = {
        backgroundColor: props.cardStyle.background.color,
        backgroundImage: `url(${props.cardStyle.background.backgroundImage})`,
        backgroundRepeat: props.cardStyle.background.backgroundRepeat,
      };
    } else {
      // If card style is not given then use primary color as a background
      if (props.themeName === 'custom' && props.colors) {
        backgroundStyles.backgroundColor = props.colors.primary;
      }
    }
    if (props.cardStyle.line) {
      lineAfter = {
        borderBottom: `${props.cardStyle.line.borderWidth} ${props.cardStyle.line.borderStyle} ${props.cardStyle.line.borderColor}`,
      };
    }
    if (props.cardStyle.border) {
      boxBorder = {
        border: `${props.cardStyle.border.borderWidth} ${props.cardStyle.border.borderStyle} ${props.cardStyle.border.borderColor}`,
      };
    }
  } else {
    // If card style is not given then use primary color as a background
    if (props.themeName === 'custom' && props.colors) {
      backgroundStyles.backgroundColor = props.colors.primary;
    }
  }

  if (props.emphasizeHighMargin) {
    if (Object.keys(props.hmItemBorder).length > 0) {
      hmItemBorder = {
        border: `${props.hmItemBorder.borderWidth} ${props.hmItemBorder.borderStyle} ${props.hmItemBorder.borderColor}`,
      };
    }
    if (Object.keys(props.hmItemBackground).length > 0) {
      hmItemBackground = {
        backgroundColor: props.hmItemBackground.color,
        backgroundImage: `url(${props.hmItemBackground.backgroundImage})`,
        backgroundRepeat: props.hmItemBackground.backgroundRepeat,
      };
    }
  }
  if (props.items) {
    if (props.highLighted) {
      var element = document.getElementById(`${props.highLighted}`);
      if (element)
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
    }

    if (props.priceStyles.decimals || props.priceStyles.includeSign) {
      if (props.priceStyles.includeSign) {
        if (props.priceStyles.includeSign != "None") {
          currenySign = props.priceStyles.includeSign;
        }
      }

      if (props.priceStyles.decimals) {
        decimals = props.priceStyles.decimals;
      }
    }

    if (props.type === "float") {
      return props.items.map((values) => {
        if (values.toggle) {
          return (
            <div
              id={values.id}
              className={`itemWapper ${props.type} 
              ${
                props.emphasizeHighMargin && values.isHighMarginItem && typeof values.isHighMarginItem === "string" &&
                values.isHighMarginItem !== "None"
                  ? `isHighMarginBOX`
                  : ``
              }
              ${props.highLighted === values.id ? `isHighLighted` : ``}`}
              style={
                props.emphasizeHighMargin && values.isHighMarginItem && typeof values.isHighMarginItem === "string" &&
                values.isHighMarginItem !== "None" ? hmItemBorder : {}
              }
              key={values.id}
            >
              {/* TAG */}
              {props.emphasizeHighMargin && values.isHighMarginItem &&
              typeof values.isHighMarginItem === "string" &&
              values.isHighMarginItem !== "None" ? (
                <div className="isHighMarginItemTag TagRed">
                  <span className="isHighMarginTAG" style={{backgroundColor: props.hmItemBorder.borderColor}}>
                    {values.isHighMarginItem}
                  </span>
                </div>
              ) : (
                ``
              )}
              <div className="itemBoxed">
                {/* 
                 IMAGE IF THERE IN float LAYOUT.
                {props.type === "linePhoto" || props.type === "centeredPhoto" ? (
                  <div className="itemImageWapper">
                    {values.photoUrl ? (
                      <img alt="Item" src={values.photoUrl} />
                    ) : (
                      ``
                    )}
                  </div>
                ) : (
                  ``
                )} */}
                <div className="textWapper">
                  <div className="header">
                    <div className="itemTitleWapper">
                      <h5 className="itemTitle" style={{ ...props.itemStyles }}>
                        {values.name}
                      </h5>
                    </div>
                    {values.price ? (
                      props.type == "linePhoto" ||
                      props.type == "line" ||
                      props.type == "float" ? (
                        <div
                          className="priceWapper"
                          style={{ ...props.itemDescriptionStyles }}
                        >
                          {currenySign} {values.price.toFixed(decimals)}
                        </div>
                      ) : (
                        ``
                      )
                    ) : (
                      ``
                    )}
                  </div>

                  <div className="details">
                    <div className="descWapper">
                      <div>
                        <p style={{ ...props.itemDescriptionStyles }}>
                          {" "}
                          {values.desc}
                        </p>
                      </div>
                    </div>
                    <div className="actionWapper">
                      <span>
                        <FavoriteBorderIcon />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
        return null;
      });
    } else {
      return props.items.map((values) => {
        if (values.toggle) {
          return (
            <div
              id={values.id}
              className={`itemWapper ${props.type} 
            ${
              props.emphasizeHighMargin && values.isHighMarginItem && typeof values.isHighMarginItem === "string" &&
              values.isHighMarginItem !== "None"
                ? `isHighMarginBOX`
                : ``
            }
            ${props.highLighted === values.id ? `isHighLighted` : ``}`}
              key={values.id}
              style={
                (props.emphasizeHighMargin && values.isHighMarginItem && typeof values.isHighMarginItem === "string" && values.isHighMarginItem !== "None")
                  ? (props.type === "linePhoto" ? { ...hmItemBorder, ...hmItemBackground } : hmItemBorder)
                  : (props.type === "linePhoto" ? { ...backgroundStyles, ...boxBorder, ...lineAfter } : {})
              }
            >
              {/* TAG */}
              {props.emphasizeHighMargin && values.isHighMarginItem &&
              typeof values.isHighMarginItem === "string" &&
              values.isHighMarginItem !== "None" ? (
                <div className="isHighMarginItemTag TagRed">
                  <span className="isHighMarginTAG" style={{backgroundColor: props.hmItemBorder.borderColor}}>
                    {values.isHighMarginItem}
                  </span>
                </div>
              ) : (
                ``
              )}
              <div className="itemBoxed">
                {props.type === "linePhoto" ||
                props.type === "centeredPhoto" ? (
                  <div className="itemImageWapper">
                    {values.photoUrl ? (
                      <img alt="Item" src={values.photoUrl} />
                    ) : (
                      ``
                    )}
                  </div>
                ) : (
                  ``
                )}
                <div className="textWapper">
                  {
                    props.sectionType !== 'wine' ? (
                      <div className="header">
                        <div className="itemTitleWapper">
                          <h5 className="itemTitle" style={{ ...props.itemStyles }}>
                            {values.name}
                          </h5>
                        </div>
                        {values.price ? (
                          props.type === "linePhoto" || props.type === "line" ? (
                            <div
                              className="priceWapper"
                              style={{ ...props.itemDescriptionStyles }}
                            >
                              {currenySign} {values.price.toFixed(decimals)}
                            </div>
                          ) : (
                            ``
                          )
                        ) : (
                          ``
                        )}
                      </div>
                    ) : (
                      <Grid container spacing={1}>
                        <Grid item xs={8} sm={10} md={10}>
                          <h5 className="itemTitle wineLayoutItemTitle" style={{ ...props.itemStyles }}>
                            {values.name}
                          </h5>
                        </Grid>
                        <Grid item xs={2} sm={1} md={1} className="winePriceWrapper">
                          {values.pricePerGlass ? (
                            props.type === "linePhoto" || props.type === "line" ? (
                              <div
                                className="priceWapper"
                                style={{ ...props.itemDescriptionStyles }}
                              >
                                {currenySign} {values.pricePerGlass.toFixed(decimals)}
                              </div>
                            ) : (
                              ``
                            )
                          ) : (
                            ``
                          )}
                        </Grid>
                        <Grid item xs={2} sm={1} md={1} className="winePriceWrapper">
                          {values.price ? (
                            props.type === "linePhoto" || props.type === "line" ? (
                              <div
                                className="priceWapper"
                                style={{ ...props.itemDescriptionStyles }}
                              >
                                {currenySign} {values.price.toFixed(decimals)}
                              </div>
                            ) : (
                              `-`
                            )
                          ) : (
                            `-`
                          )}
                        </Grid>
                      </Grid>
                    )
                  }
                  <div className="details">
                    <div className="descWapper">
                      <div>
                        <p style={{ ...props.itemDescriptionStyles }}>
                          {" "}
                          {values.desc}
                        </p>
                      </div>
                      {props.type === "centeredPhoto" ||
                      props.type === "centered" ? (
                        <div
                          className="priceWapper"
                          style={{ ...props.itemDescriptionStyles }}
                        >
                          {values.price
                            ? `${currenySign}${values.price.toFixed(decimals)}`
                            : ""}
                        </div>
                      ) : (
                        ``
                      )}
                    </div>
                    <div className="actionWapper">
                      <span>
                        <FavoriteBorderIcon />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
        return null;
      });
    }
  } else {
    return (
      <div>
        <span> No data found in this section </span>
      </div>
    );
  }
});
export default Item;
