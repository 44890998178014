import React from "react";
import { Redirect, Route } from "react-router-dom";

const AuthenticatedRoute = ({
  component: C,
  props: cProps,
  permissions,
  ...rest
}) => (
    <Route
      {...rest}
      render={(props) => {
        if (cProps && cProps.isAuthenticated && permissions) {
          let userPermissions = hasUserPermissions(permissions, cProps);

          if (!userPermissions.isVerifiedUser) {
            return <Redirect to="/unverified-user" />;
          } else if (!userPermissions.isAuthUser) {
            return <Redirect to="/forbidden" />;
          }
          return <C {...props} {...cProps} />;
        } else if (cProps && cProps.isAuthenticated) {
          return <C {...props} {...cProps} />;
        } else {
          return <Redirect to="/welcome" />;
        }
      }}
    />
  );

const hasUserPermissions = (permissions, cProps) => {
  let userPermissions = {
    isAuthUser: true,
    isVerifiedUser: true
  }

  for (let permissionIndex = 0; permissionIndex < permissions.length; permissionIndex++) {
    const permission = permissions[permissionIndex];
    if (permission === 'verifiedEmail' && !cProps.bypassEmailValidation && !(cProps.emailVerified)) {
      userPermissions.isVerifiedUser = false;
    }
    if (permission === 'onlyStripeActive' && !(cProps.stripe &&
      cProps.stripe.subscriptions &&
      cProps.stripe.subscriptions.data &&
      cProps.stripe.subscriptions.data.length > 0 &&
      cProps.stripe.subscriptions.data[0].plan.active)) {
      userPermissions.isAuthUser = false;
      break;
    }
  }

  return userPermissions;
}

export default AuthenticatedRoute;