import React from 'react';
import {AppBar, Toolbar, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import BillingForm from '../../components/billingForm';

const useStyles = makeStyles((theme) => ({
  headerTitle: {
    flexGrow: 2,
    textAlign: "initial",
    paddingLeft: theme.spacing(2)
  }
}));

const SetUp = (props) => {
  const classes = useStyles();
  return (
    <div>
      <AppBar position="fixed" color="primary">
        <Toolbar>
          <Typography variant="h6" className={classes.headerTitle}>
            Upgrade Plan
          </Typography>
          <div></div>
        </Toolbar>
      </AppBar>
      <BillingForm isUpdate={false} nextPage={props.location.state && props.location.state.nextPage}/>
    </div>
  );
};

export default SetUp;
