import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Container,
  CssBaseline, Divider, FormControl, Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
  Typography
} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import ColorPicker from "../../components/ColorPicker";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { updateTempSelectedMenu } from "../../store/actions/menuActions"
import presetData from "../../constants/presetData.json";

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
  },
  headerTitle: {
    flexGrow: 2,
    textAlign: "initial",
    paddingLeft: theme.spacing(2)
  },
  title: {
    flexGrow: 2,
  },
  mainContainer: {
    margin: theme.spacing(8, 0, 8, 0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%"
  },
  subTitleText: {
    color: 'rgba(0, 0, 0, 0.6)'
  },
  dividerContainer: {
    margin: "auto",
    '& hr': {
      backgroundColor: 'rgba(0, 0, 0, 0.6)'
    }
  },
  selectInput: {
    textAlign: 'left'
  },
  inputContainer: {
    padding: theme.spacing(0, 0.5)
  },
  paddingLeft: {
    paddingLeft: theme.spacing(1)
  },
  textPreviewBox: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5)
  },
  lockIcon: {
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25)
  },
  noPaddingContainer: {
    padding: 0
  }
}));

const CustomizeFonts = (props) => {
  const [currentFontScheme, setCurrentFontScheme] = useState({
    heading: {
      fontStyle: "normal",
      fontSize: "50px",
      fontFamily: "Pinyon Script",
      fontWeight: "bold",
      color: "#000"
    },
    section: {
      fontStyle: "normal",
      fontSize: "20px",
      fontFamily: "Pinyon Script",
      fontWeight: "normal",
      color: "#000"
    },
    item: {
      fontStyle: "normal",
      fontSize: "28px",
      fontFamily: "Pinyon Script",
      fontWeight: "bold",
      color: "#000"
    },
    description: {
      fontStyle: "normal",
      fontSize: "16px",
      fontFamily: "Pinyon Script",
      fontWeight: "normal",
      color: "#000"
    },
    sectionDescription: {
      fontStyle: "normal",
      fontSize: "16px",
      fontFamily: "Pinyon Script",
      fontWeight: "normal",
      color: "#000"
    },
    tray: {
      fontStyle: "normal",
      fontSize: "20px",
      fontFamily: "Pinyon Script",
      fontWeight: "normal",
      color: "#000"
    }
  });
  const tempSelectedMenu = useSelector((state) => state.menu.tempSelectedMenu);
  const isFreePlan = useSelector((state) => state.stripe.isFreePlan);
  const isContactTracingPlan = useSelector((state) => state.stripe.isContactTracingPlan);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (tempSelectedMenu && tempSelectedMenu.theme && tempSelectedMenu.theme.theme) {
      const {fonts: { heading, section, item, description, tray, sectionDescription }} = tempSelectedMenu.theme.theme;
      const newSectionBackground = sectionDescription || currentFontScheme.sectionDescription;
      setCurrentFontScheme({...currentFontScheme, heading, section, item, description, tray, sectionDescription: newSectionBackground});
    }
  }, [tempSelectedMenu]);

  const handleChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const {name, value} = event.target;
    const path = name.split('.');
    const newFontScheme = _.cloneDeep(currentFontScheme);
    newFontScheme[path[0]][path[1]] = value;
    setCurrentFontScheme({...newFontScheme});
  };

  const handleColorChange = (propName, color) => {
    const path = propName.split('.');
    const newFontScheme = _.cloneDeep(currentFontScheme);
    const {rgb: {r, g, b, a}} = color;
    newFontScheme[path[0]][path[1]] = `rgba(${r},${g},${b},${a})`;
    setCurrentFontScheme({...newFontScheme});
  }

  const handleApplyChanges = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let tempMenu = _.cloneDeep(tempSelectedMenu);
    tempMenu.theme.theme.fonts = _.merge({}, tempMenu.theme.theme.fonts, currentFontScheme);
    dispatch(updateTempSelectedMenu(tempMenu));
    props.history.push('/change-theme');
  }

  return (
    <div>
      <AppBar position="fixed" color="primary">
        <Toolbar>
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={() => {props.history.push('/change-theme')}}
          >
            <ArrowBackIosIcon/>
          </IconButton>
          <Typography variant="h6" className={classes.headerTitle}>
            Customize Colors
          </Typography>
          <div></div>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm">
        <CssBaseline/>
        <div className={classes.mainContainer}>
          <form className={classes.form}>
            <div className={classes.textPreviewBox}>
              <div style={currentFontScheme.heading}>Header text</div>
              <div style={currentFontScheme.section}>Section text</div>
              <div style={currentFontScheme.sectionDescription}>Section Description text</div>
              <div style={currentFontScheme.item}>Item text</div>
              <div style={currentFontScheme.description}>Item Description text</div>
              <div style={currentFontScheme.tray}>Tray text</div>
            </div>
            <Grid container maxWidth="sm">
              <Grid item xs={2}>
                <Typography variant="subtitle1" align="left" className={classes.subTitleText}>Header</Typography>
              </Grid>
              <Grid item xs={10} className={classes.dividerContainer}>
                <Divider/>
              </Grid>
            </Grid>
            <FormControl variant="filled" className={classes.inputContainer} fullWidth margin="normal">
              <InputLabel id="primaryFont">
                Font
                {
                  (isFreePlan || isContactTracingPlan) && <IconButton edge="end" aria-label="lock" className={classes.noPaddingContainer}>
                    <LockIcon color='primary' className={classes.lockIcon}/>
                  </IconButton>
                }
              </InputLabel>
              <Select
                id="primaryFont"
                name="heading.fontFamily"
                value={currentFontScheme.heading.fontFamily}
                className={classes.selectInput}
                onChange={handleChange}
                disabled={isFreePlan || isContactTracingPlan}
              >
                {
                  presetData.fonts.map((fontName) => (<MenuItem key={fontName} value={fontName}>{fontName}</MenuItem>))
                }
              </Select>
            </FormControl>
            <div className={classes.inputContainer}>
              <ColorPicker propName="heading.color" label='Color' color={currentFontScheme.heading.color} isFreePlan={isFreePlan || isContactTracingPlan} handleColorChange={handleColorChange}/>
            </div>

            <Grid container maxWidth="sm">
              <Grid item xs={2}>
                <Typography variant="subtitle1" align="left" className={classes.subTitleText}>Section</Typography>
              </Grid>
              <Grid item xs={10} className={classes.dividerContainer}>
                <Divider/>
              </Grid>
            </Grid>
            <FormControl variant="filled" className={classes.inputContainer} fullWidth margin="normal">
              <InputLabel id="primaryFont">
                Font
                {
                  (isFreePlan || isContactTracingPlan) && <IconButton edge="end" aria-label="lock" className={classes.noPaddingContainer}>
                    <LockIcon color='primary' className={classes.lockIcon}/>
                  </IconButton>
                }
              </InputLabel>
              <Select
                id="primaryFont"
                name="section.fontFamily"
                value={currentFontScheme.section.fontFamily}
                className={classes.selectInput}
                onChange={handleChange}
                disabled={isFreePlan || isContactTracingPlan}
              >
                {
                  presetData.fonts.map((fontName) => (<MenuItem key={fontName} value={fontName}>{fontName}</MenuItem>))
                }
              </Select>
            </FormControl>
            <div className={classes.inputContainer}>
              <ColorPicker propName="section.color" label='Color' color={currentFontScheme.section.color} isFreePlan={isFreePlan || isContactTracingPlan} handleColorChange={handleColorChange}/>
            </div>

            <Grid container maxWidth="sm">
              <Grid item xs={5} sm={4}>
                <Typography variant="subtitle1" align="left" className={classes.subTitleText}>Section Description</Typography>
              </Grid>
              <Grid item xs={7} sm={8} className={classes.dividerContainer}>
                <Divider/>
              </Grid>
            </Grid>
            <FormControl variant="filled" className={classes.inputContainer} fullWidth margin="normal">
              <InputLabel id="primaryFont">
                Font
                {
                  (isFreePlan || isContactTracingPlan) && <IconButton edge="end" aria-label="lock" className={classes.noPaddingContainer}>
                    <LockIcon color='primary' className={classes.lockIcon}/>
                  </IconButton>
                }
              </InputLabel>
              <Select
                id="primaryFont"
                name="sectionDescription.fontFamily"
                value={currentFontScheme.sectionDescription.fontFamily}
                className={classes.selectInput}
                onChange={handleChange}
                disabled={isFreePlan || isContactTracingPlan}
              >
                {
                  presetData.fonts.map((fontName) => (<MenuItem key={fontName} value={fontName}>{fontName}</MenuItem>))
                }
              </Select>
            </FormControl>
            <div className={classes.inputContainer}>
              <ColorPicker propName="sectionDescription.color" label='Color' color={currentFontScheme.sectionDescription.color} isFreePlan={isFreePlan || isContactTracingPlan} handleColorChange={handleColorChange}/>
            </div>

            <Grid container maxWidth="sm">
              <Grid item xs={1}>
                <Typography variant="subtitle1" align="left" className={classes.subTitleText}>Item</Typography>
              </Grid>
              <Grid item xs={11} className={`${classes.dividerContainer} ${classes.paddingLeft}`}>
                <Divider/>
              </Grid>
            </Grid>
            <FormControl variant="filled" className={classes.inputContainer} fullWidth margin="normal">
              <InputLabel id="primaryFont">
                Font
                {
                  (isFreePlan || isContactTracingPlan) && <IconButton edge="end" aria-label="lock" className={classes.noPaddingContainer}>
                    <LockIcon color='primary' className={classes.lockIcon}/>
                  </IconButton>
                }
              </InputLabel>
              <Select
                id="primaryFont"
                name="item.fontFamily"
                value={currentFontScheme.item.fontFamily}
                className={classes.selectInput}
                disabled={isFreePlan || isContactTracingPlan}
                onChange={handleChange}
              >
                {
                  presetData.fonts.map((fontName) => (<MenuItem key={fontName} value={fontName}>{fontName}</MenuItem>))
                }
              </Select>
            </FormControl>
            <div className={classes.inputContainer}>
              <ColorPicker propName="item.color" label='Color' color={currentFontScheme.item.color} isFreePlan={isFreePlan || isContactTracingPlan} handleColorChange={handleColorChange}/>
            </div>

            <Grid container maxWidth="sm">
              <Grid item xs={4} sm={3}>
                <Typography variant="subtitle1" align="left" className={classes.subTitleText}>Item Description</Typography>
              </Grid>
              <Grid item xs={8} sm={9}className={classes.dividerContainer}>
                <Divider/>
              </Grid>
            </Grid>
            <FormControl variant="filled" className={classes.inputContainer} fullWidth margin="normal">
              <InputLabel id="primaryFont">
                Font
                {
                  (isFreePlan || isContactTracingPlan) && <IconButton edge="end" aria-label="lock" className={classes.noPaddingContainer}>
                    <LockIcon color='primary' className={classes.lockIcon}/>
                  </IconButton>
                }
              </InputLabel>
              <Select
                id="primaryFont"
                name="description.fontFamily"
                value={currentFontScheme.description.fontFamily}
                className={classes.selectInput}
                disabled={isFreePlan || isContactTracingPlan}
                onChange={handleChange}
              >
                {
                  presetData.fonts.map((fontName) => (<MenuItem key={fontName} value={fontName}>{fontName}</MenuItem>))
                }
              </Select>
            </FormControl>
            <div className={classes.inputContainer}>
              <ColorPicker propName="description.color" label='Color' color={currentFontScheme.description.color} isFreePlan={isFreePlan || isContactTracingPlan} handleColorChange={handleColorChange}/>
            </div>

            <Grid container maxWidth="sm">
              <Grid item xs={1}>
                <Typography variant="subtitle1" align="left" className={classes.subTitleText}>Tray</Typography>
              </Grid>
              <Grid item xs={11} className={`${classes.dividerContainer} ${classes.paddingLeft}`}>
                <Divider/>
              </Grid>
            </Grid>
            <FormControl variant="filled" className={classes.inputContainer} fullWidth margin="normal">
              <InputLabel id="primaryFont">
                Font
                {
                  (isFreePlan || isContactTracingPlan) && <IconButton edge="end" aria-label="lock" className={classes.noPaddingContainer}>
                    <LockIcon color='primary' className={classes.lockIcon}/>
                  </IconButton>
                }
              </InputLabel>
              <Select
                id="primaryFont"
                name="tray.fontFamily"
                value={currentFontScheme.tray.fontFamily}
                className={classes.selectInput}
                disabled={isFreePlan || isContactTracingPlan}
                onChange={handleChange}
              >
                {
                  presetData.fonts.map((fontName) => (<MenuItem key={fontName} value={fontName}>{fontName}</MenuItem>))
                }
              </Select>
            </FormControl>
            <div className={classes.inputContainer}>
              <ColorPicker propName="tray.color" label='Color' color={currentFontScheme.tray.color} isFreePlan={isFreePlan || isContactTracingPlan} handleColorChange={handleColorChange}/>
            </div>
          </form>
        </div>
      </Container>
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar className="bottomTool">
          <Typography variant="h6" className={classes.title} button="true" onClick={handleApplyChanges}>
            Apply Changes
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default CustomizeFonts;
