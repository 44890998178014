import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Box,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "90%",
    margin: "2%",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  orderId: {
    fontSize: 14,
    alignSelf: "flex-start",
  },
  pos: {
    marginBottom: 12,
  },
  actionArea: {
    justifyContent: "flex-end",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
  },
  contentHidden: {
    display: "none",
  },
  selection: {
    paddingLeft: '20px',
  },
  item: {
    fontSize: '1.1rem',
    paddingLeft: '5px',
    paddingTop: '5px',
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: '700',
  },
  customer: {
    paddingLeft: '5px',
  },
  customerTitle: {
    fontSize: '1.1rem',
    fontWeight: '700',
    paddingTop: '5px',
  },
  customerDetails: {
    fontSize: '.9rem'
  },
  customerAddress: {
    textAlign: 'right',
  }
});

const OrderCard = (props) => {
  const {
    order: {
      customerName,
      customerPhone,
      customerEmail,
      orderId,
      fulfillmentOption,
      deliveryAddress,
      items,
    },
  } = props;
  const classes = useStyles();

  const [hidden, setHidden] = useState(false);

  const hideCard = () => {
    if (hidden) {
      setHidden(false);
    } else setHidden(true);
  };

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent className={hidden ? classes.contentHidden : classes.content}>
        <Grid
          container
          direction="row"
          align="space-between"
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
            >
              Order #: {orderId}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {fulfillmentOption ? fulfillmentOption : "Pick-up"}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="stretch"
        >
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
          >
            {items.map((item) => {
              return (
                <Grid key={item.id} item xs>
                  <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-start"
                      xs
                    >
                      <Grid item>
                        <Typography
                          className={classes.item}
                          color="textSecondary"
                        >
                          {item.quantity} x
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          className={classes.item}
                          color="textSecondary"
                        >
                          {item.description}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs>
                      <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                      >
                        {item.selection && item.selection.length > 0
                          ? item.selection.map((selection) => {
                            return (
                              <Grid key={selection} item>
                                <Typography
                                  className={classes.selection}
                                  color="textSecondary"
                                >
                                  - {selection}
                                </Typography>
                              </Grid>
                            );
                          })
                          : ""}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="stretch"
          xs={12}
        >
          <Grid item xs={6} className={classes.customer}>
            <Typography
              className={classes.customerTitle}
              color="textSecondary"
              gutterBottom
            >
              Customer
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
            >
              {customerName}
            </Typography>
            <Typography
              className={classes.customerDetails}
              color="textSecondary"
              gutterBottom
            >
              {customerEmail}
            </Typography>
            <Typography
              className={classes.customerDetails}
              color="textSecondary"
              gutterBottom
            >
              {customerPhone}
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.customerAddress}>
          <Typography
              className={classes.customerTitle}
              color="textSecondary"
              gutterBottom
            >
              {fulfillmentOption==='Delivery'?'Address':''}
            </Typography>
                <Typography className={classes.customerDetails} color="textSecondary">
                  {deliveryAddress && deliveryAddress.address1
                    ? deliveryAddress.address1
                    : ""}
                </Typography>
                <Typography className={classes.customerDetails} color="textSecondary">
                  {deliveryAddress && deliveryAddress.address2
                    ? deliveryAddress.address2
                    : ""}
                </Typography>
                <Typography className={classes.customerDetails} color="textSecondary">
                  {deliveryAddress && deliveryAddress.city
                    ? deliveryAddress.city
                    : ""}
                </Typography>
                <Typography className={classes.customerDetails} color="textSecondary">
                  {deliveryAddress && deliveryAddress.postCode
                    ? deliveryAddress.postCode
                    : ""}
                </Typography>
                <Typography className={classes.customerDetails} color="textSecondary">
                  {deliveryAddress && deliveryAddress.additionalInstructions
                    ? deliveryAddress.additionalInstructions
                    : ""}
                </Typography>
              </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.actionArea}>
        <div className={!hidden?classes.contentHidden:classes.content}>
          <Typography
            className={classes.title}
            color="textSecondary"
          >
            Order #: {orderId}
          </Typography>
        </div>
        <Button size="small" color="secondary" onClick={hideCard}>{hidden?'Show Order':'Hide Order'}</Button>
      </CardActions>
    </Card>
  );
};

export default OrderCard;
