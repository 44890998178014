import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Container, CssBaseline, IconButton, Toolbar, Typography} from "@material-ui/core";
import ColorPicker from "../../components/ColorPicker";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { updateTempSelectedMenu } from "../../store/actions/menuActions"

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
  },
  headerTitle: {
    flexGrow: 2,
    textAlign: "initial",
    paddingLeft: theme.spacing(2)
  },
  title: {
    flexGrow: 2,
  },
  mainContainer: {
    margin: theme.spacing(8, 0, 8, 0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%"
  }
}));

const CustomizeColors = (props) => {
  const [currentColorScheme, setCurrentColorScheme] = useState({
    colors: {
      "primary": "#FFF",
      "secondary": "#000",
      "accent": "#7B7B7B",
      "accentFont": "#FFF"
    },
    tray: {
      color: '#fff'
    },
    itemBorder: {
      borderColor: "#000",
      borderStyle: "solid",
      borderWidth: "2px"
    },
    itemBackground: {
      backgroundRepeat: "",
      color: "#FFF",
      backgroundImage: ""
    },
    hmItemBackground: {
      backgroundRepeat: "",
      color: "#FFF",
      backgroundImage: ""
    },
    sectionBackground: {
      backgroundRepeat: "",
      color: "#FFF",
      backgroundImage: ""
    },
    hmItemBorder: {
      borderColor: "#FF0000",
      borderStyle: "solid",
      borderWidth: "2px"
    }
  });
  const [layout, setLayout] = useState('line');
  const tempSelectedMenu = useSelector((state) => state.menu.tempSelectedMenu);
  const isFreePlan = useSelector((state) => state.stripe.isFreePlan);
  const isContactTracingPlan = useSelector((state) => state.stripe.isContactTracingPlan);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (tempSelectedMenu && tempSelectedMenu.theme && tempSelectedMenu.theme.theme) {
      const {colors, tray, itemBorder, itemBackground, hmItemBorder, sectionBackground, hmItemBackground} = tempSelectedMenu.theme.theme;
      const newSectionBackground = sectionBackground || currentColorScheme.sectionBackground;
      setCurrentColorScheme({...currentColorScheme, colors, tray, itemBorder, itemBackground, hmItemBorder, hmItemBackground, sectionBackground: newSectionBackground});
      setLayout(tempSelectedMenu.theme.theme.layout);
    }
  }, [tempSelectedMenu]);

  const handleBackgroundChange = (propName, color) => {
    const path = propName.split('.');
    const newColorSchema = _.cloneDeep(currentColorScheme);
    const {rgb: {r, g, b, a}} = color;
    newColorSchema[path[0]][path[1]] = `rgba(${r},${g},${b},${a})`;
    setCurrentColorScheme({...newColorSchema});
  }

  const handleApplyChanges = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let tempMenu = _.cloneDeep(tempSelectedMenu);
    tempMenu.theme.theme = _.merge({}, tempMenu.theme.theme, currentColorScheme);
    dispatch(updateTempSelectedMenu(tempMenu));
    props.history.push('/change-theme');
  }

  return (
    <div>
      <AppBar position="fixed" color="primary">
        <Toolbar>
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={() => {props.history.push('/change-theme')}}
          >
            <ArrowBackIosIcon/>
          </IconButton>
          <Typography variant="h6" className={classes.headerTitle}>
            Customize Colors
          </Typography>
          <div></div>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm">
        <CssBaseline/>
        <div className={classes.mainContainer}>
          <form className={classes.form}>
            {
              layout === 'float' && <ColorPicker propName="colors.primary" label='Header Color' color={currentColorScheme.colors.primary} isFreePlan={isFreePlan || isContactTracingPlan} handleColorChange={handleBackgroundChange}/>
            }
            {
              layout === 'float' && <ColorPicker propName="sectionBackground.color" label='Section Background Color' color={currentColorScheme.sectionBackground.color} isFreePlan={isFreePlan || isContactTracingPlan} handleColorChange={handleBackgroundChange}/>
            }
            {
              (layout === 'linePhoto' || layout === 'float') && <ColorPicker propName="itemBackground.color" label='Item Background Color' color={currentColorScheme.itemBackground.color} isFreePlan={isFreePlan || isContactTracingPlan} handleColorChange={handleBackgroundChange}/>
            }
            {
              (layout === 'linePhoto' || layout === 'float') && <ColorPicker propName="itemBorder.borderColor" label='Item Border Color' color={currentColorScheme.itemBorder.borderColor} isFreePlan={isFreePlan || isContactTracingPlan} handleColorChange={handleBackgroundChange}/>
            }
            {
              (layout === 'linePhoto') && <ColorPicker propName="hmItemBackground.color" label='Highlighted Item Background Color' color={currentColorScheme.hmItemBackground.color} isFreePlan={isFreePlan || isContactTracingPlan} handleColorChange={handleBackgroundChange}/>
            }
            <ColorPicker propName="hmItemBorder.borderColor" label='Item Tag Outline Color' color={currentColorScheme.hmItemBorder.borderColor} isFreePlan={isFreePlan || isContactTracingPlan} handleColorChange={handleBackgroundChange}/>
            <ColorPicker propName="tray.color" label='Tray Color' color={currentColorScheme.tray.color} isFreePlan={isFreePlan || isContactTracingPlan} handleColorChange={handleBackgroundChange}/>
          </form>
        </div>
      </Container>
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar className="bottomTool">
          <Typography variant="h6" className={classes.title} button="true" onClick={handleApplyChanges}>
            Apply Changes
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default CustomizeColors;
