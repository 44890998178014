import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Typography,
  Toolbar,
  AppBar,
  Button,
  Grid,
  MenuItem,
  Menu
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ClearIcon from '@material-ui/icons/Clear';
import { useHistory } from "react-router-dom";
import _ from "lodash";
import moment from 'moment';

import "./updateMenu.scss";
import MenuList from "./menuList";
import NavigationBlockerPrompt from "../../components/NavigationBlockerPrompt";
import { showPopupAction } from "../../store/actions/popupActions";
import {setSelectedMenu, deleteMenuDraft, updateSelectedMenuTheme} from "../../store/actions/menuActions";
import { hideLoaderAction, showLoaderAction } from "../../store/actions/loaderActions";
import colors from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 2,
  },
  headerTitle: {
    flexGrow: 2,
    textAlign: "initial",
    paddingLeft: theme.spacing(2)
  },
  grow: {
    flexGrow: 1,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(9, 2, 6),
    width: '100%',
    boxSizing: 'border-box'
  },
  button: {
    margin: theme.spacing(1),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%",
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  draftDropDown: {
    '& ul': {
      overflow: 'auto',
      maxHeight: '50vh',
      '& li': {
        width: 'fit-content'
      }
    }
  },
  draftDate: {
    paddingLeft: theme.spacing(1)
  },
  selectedDraft: {
    color: colors.primary
  },
  crossButton: {
    padding: theme.spacing(1, 1, 1, 0)
  }
}));

export default function UpdateMenu() {
  let history = useHistory();
  const classes = useStyles();
  const menuData = useSelector((state) => state.menu.menus);
  const selectedMenu = useSelector((state) => state.menu.selectedMenu);
  const [isUpdated, setIsUpdated] = useState(false);
  const [originalMenu, setOriginalMenu] = useState({});
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    // If the selectedMenu has `menuId` property then it is a draft menu and the id of the original menu id is stored in `menuId`
    let originalMenu = menuData.find((menu) => menu.id === selectedMenu.menuId || menu.id === selectedMenu.id);
    setOriginalMenu(originalMenu);
  }, [menuData, selectedMenu]);

  const handleAddItemClick = (event) => {
    event.preventDefault();
    if (_.isEmpty(selectedMenu.sections)) {
      dispatch(showPopupAction({showPopup: true, popupType: "warning", header: "Warning!", message: "Please add at least one section before adding items. An example section would be 'Desserts' and items within that section might include 'Pecan Pie' and 'Chocolate Cake'."}));
    } else {
      setIsUpdated(false);
      setTimeout(() => {
        history.push("/add-item");
      }, 10);
    }
  };

  const handleButtonClick = (event, path, data) => {
    event.preventDefault();
    setIsUpdated(false);
    setTimeout(() => {
      if (_.isEmpty(data)) {
        history.push(path);
      } else {
        history.push(path, data);
      }
    }, 10);
  };

  const handlePreviewClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let isWineType = false;
    selectedMenu.sections.forEach((section) => {
      if (section.type === 'wine') {
        isWineType = true;
      }
    });
    if (isWineType) {
      const updatedTheme = _.cloneDeep(selectedMenu.theme);
      updatedTheme.theme.layout = 'line';
      dispatch(updateSelectedMenuTheme(updatedTheme));
    }
    setIsUpdated(false);
    setTimeout(() => {
      history.push("/update-menu-preview");
    });
  }

  const handleBackClick = () => {
    const originalMenu = menuData.find((menu) => menu.id === selectedMenu.id);
    const updated = !_.isEmpty(selectedMenu) && !_.isEqual(originalMenu, selectedMenu);
    setIsUpdated(updated);
    setTimeout(() => {
      history.push("/")
    }, 10);
  };

  const handleShowDraftsClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }

  const deleteDraft = (draftId) => {
    dispatch(deleteMenuDraft(draftId, originalMenu.id));
  }

  const handleDeleteDraftClick = (event, draftId) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(
      showPopupAction({
        showPopup: true,
        popupType: "confirmation",
        header: "Delete Draft",
        message: `Are you sure you want to delete this draft?`,
        selectedId: draftId,
        handleConfirm: deleteDraft,
      })
    );
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const populateDraftData = async (event, draftId) => {
    event.preventDefault();
    const draft = originalMenu.drafts.find((draft) => draft.id === draftId);
    setAnchorEl(null);
    dispatch(showLoaderAction());
    await dispatch(setSelectedMenu(draft));
    dispatch(hideLoaderAction());
  }

  return (
    <div className={classes.root}>
      <NavigationBlockerPrompt
        navigationBlocked={isUpdated}
        message="All your unpublished changes will be lost. Are you sure you want to leave?
(You can save it as a draft by using save as draft button at the top right of the preview page.)"
      />
      <AppBar position="fixed" color="primary">
        <Toolbar>
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={handleBackClick}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" className={classes.headerTitle}>
            Update Menu
          </Typography>
          <div>
            <Typography variant="subtitle1" id="draft-button" onClick={handleShowDraftsClick}>
              Drafts
            </Typography>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              className={classes.draftDropDown}
              open={open}
              onClose={handleClose}
            >
              {
                originalMenu && originalMenu.drafts && originalMenu.drafts.length > 0 ? originalMenu.drafts.map((draft) => (
                  <MenuItem key={draft.id}>
                    <IconButton
                      color="inherit"
                      disabled={selectedMenu.id === draft.id}
                      onClick={(e) => {handleDeleteDraftClick(e, draft.id)}}
                      className={classes.crossButton}
                    >
                      <ClearIcon fontSize="small"/>
                    </IconButton>
                    <span className={selectedMenu.id === draft.id ? classes.selectedDraft : ''} onClick={(e) => {populateDraftData(e, draft.id)}}>
                      {draft.name} draft
                      <Typography variant="caption" className={classes.draftDate}>
                        ({moment(draft.updatedAt).format('LLL')})
                      </Typography>
                    </span>
                  </MenuItem>
                )) : (
                  <MenuItem>
                    You don't have any drafts!
                  </MenuItem>
                )
              }
            </Menu>
          </div>
          <div></div>
        </Toolbar>
      </AppBar>
      <div className="customWapper">
        <main>
          {/* Hero unit */}
          <div className={classes.heroContent}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
            <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                onClick={handleAddItemClick}
                startIcon={<AddIcon />}
              >
                Item
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                onClick={(e) => {handleButtonClick(e, "/add-section")}}
                startIcon={<AddIcon />}
              >
                Section
              </Button>
              </Grid>
            </Grid>
          </div>
          <MenuList handleButtonClick={handleButtonClick}/>
        </main>
      </div>
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar className="bottomTool">
          <Typography variant="h6" className={classes.title} button="true" onClick={handlePreviewClick}>
            Preview
          </Typography>
        </Toolbar>
      </AppBar>

    </div>
  );
}
