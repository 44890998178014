import { hideLoaderAction, showLoaderAction } from "./loaderActions";
import { getMenusForUser } from "./menuActions";
import { getAllThemes } from "./themeActions";
import { getCustomer } from "./stripeActions";

export const retrieveAllRequiredData = (userId, stripeCustomerId) => async (dispatch) => {
  dispatch(showLoaderAction());
  await dispatch(getMenusForUser(userId));
  await dispatch(getAllThemes());
  if (stripeCustomerId) {
    await dispatch(getCustomer(stripeCustomerId));
  }
  dispatch(hideLoaderAction());
};
