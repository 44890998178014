import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  Container,
  CssBaseline,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
  Button,
  Divider,
  Grid,
  Select,
  MenuItem,
  FormControl
} from '@material-ui/core';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import _ from 'lodash';

import Preview from '../../components/Preview';
import ConfirmationAnimationLoader from '../../components/ConfirmationAnimationLoader';
import { setUpdatedMenuList, setIsLockedTheme } from "../../store/actions/menuActions";
import { updateMainMenuTheme, updateMenuAndUserMenuTheme, updateMainMenuCustomTheme } from "../../store/actions/authActions";

import './MenuSettingsPreview.scss';
import LockIcon from "@material-ui/icons/Lock";
import {showPopupAction} from "../../store/actions/popupActions";

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
  },
  headerTitle: {
    flexGrow: 2,
    textAlign: "initial",
    paddingLeft: theme.spacing(2)
  },
  title: {
    flexGrow: 2,
  },
  mainContainer: {
    margin: theme.spacing(7, 0, 2, 0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  selectInputControl: {
    marginBottom: theme.spacing(2),
    textAlign: "left",
    '& #themeName': {
      padding: theme.spacing(3, 1.5, 2.5),
      display: 'flex'
    },
  },
  subTitleText: {
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.87)",
    padding: theme.spacing(1.5)
  },
  previewTextContainer: {
    width: "100%"
  },
  proOnlyTextContainer: {
    justifyContent: 'space-between'
  },
  lockIcon: {
    padding: theme.spacing(0.25),
    marginLeft: theme.spacing(0.5)
  },
  lockedThemeName:{
    padding: theme.spacing(0.5, 0)
  }
}));

const MenuSettingsPreview = (props) => {
  const classes = useStyles();
  const [displayLoader, setDisplayLoader] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('loading');
  const [themeName, setThemeName] = useState('Cinema');
  const [currentTheme, setCurrentTheme] = useState(null);
  const originalMenuList = useSelector((state) => state.menu.menus);
  const updatedMenuList = useSelector((state) => state.menu.updatedMenuList);
  const isLockedTheme = useSelector((state) => state.menu.isLockedTheme);
  const userDetails = useSelector((state) => state.auth.user);
  const themes = useSelector((state) => state.theme.themes);
  const updatedMenuTheme = useSelector((state) => state.auth.updatedMenuTheme);
  const mainMenuCustomTheme = useSelector((state) => state.auth.mainMenuCustomTheme);
  const menus = useSelector((state) => state.menu.menuOrder)
  const isFreePlan = useSelector((state) => state.stripe.isFreePlan);
  const isContactTracingPlan = useSelector((state) => state.stripe.isContactTracingPlan);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!_.isEmpty(updatedMenuTheme)) {
      setThemeName(updatedMenuTheme.theme);
      setCurrentTheme(updatedMenuTheme);
    } else if (!_.isEmpty(userDetails.menuTheme)) {
      setThemeName(userDetails.menuTheme.theme);
      setCurrentTheme(userDetails.menuTheme);
    }
  }, [userDetails.menuTheme, updatedMenuTheme]);

  const handleChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { value } = event.target;
    let finalTheme = {};
    if (value === "custom") {
      finalTheme = _.cloneDeep(mainMenuCustomTheme);
    } else {
      const selectedTheme = themes.find((theme) => theme.id === value);
      finalTheme = {
        theme: value,
        includeLogo: userDetails.menuTheme ? userDetails.menuTheme.includeLogo : false,
        ...selectedTheme
      };
      delete finalTheme.id;
      if (isFreePlan || isContactTracingPlan) {
        const themeIndex = themes.findIndex((theme) => theme.id === value);
        if (themeIndex > 5) {
          dispatch(setIsLockedTheme(true));
        } else {
          dispatch(setIsLockedTheme(false));
        }
      }
    }
    dispatch(updateMainMenuTheme(finalTheme));
  };

  const publishMenu = (event) => {
    event.preventDefault();
    if (isLockedTheme) {
      dispatch(showPopupAction({ showPopup: true, message: 'Please upgrade your plan to use Premium theme!' }));
    } else {
      setDisplayLoader(true);
      let updatedUserDetails = _.cloneDeep(userDetails);
      if (!_.isEmpty(updatedMenuTheme)) {
        updatedUserDetails.menuTheme = updatedMenuTheme;
      } else if (_.isEmpty(userDetails.menuTheme)) {
        // If user does not have any theme then assign "Cinema" as default theme
        const defaultTheme = themes.find((theme) => theme.id === "Cinema");
        updatedUserDetails.menuTheme = {
          includeLogo: false,
          theme: defaultTheme.id,
          ...defaultTheme
        };
        delete updatedUserDetails.menuTheme.id;
      }

      if (!_.isEmpty(menus)) {
        updatedUserDetails.menuOrder = menus;
      }

      dispatch(updateMenuAndUserMenuTheme(originalMenuList, updatedMenuList, updatedUserDetails, (error) => {
        if (error) {
          setDisplayLoader(false);
          setLoadingStatus("loading");
        } else {
          setLoadingStatus("complete");
          setTimeout(() => {
            setLoadingStatus("success");
          }, 1000);
          setTimeout(() => {
            setDisplayLoader(false);
            setLoadingStatus("loading");
            dispatch(setUpdatedMenuList([]));
            dispatch(updateMainMenuCustomTheme({}));
            props.history.push("/");
          }, 1500);
        }
      }));
    }
  };

  const handleCustomizeClick = (event) => {
    event.preventDefault();
    props.history.push('/menu-theme-settings');
  };

  return (
    <div>

      <AppBar position="fixed" color="primary">
        <Toolbar>
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={() => { props.history.push('/menu-settings') }} >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" className={classes.headerTitle}>
            Menu Settings
          </Typography>
          <div></div>
        </Toolbar>
      </AppBar>

      <Container component="main" maxWidth="md">
        <CssBaseline />
        <ConfirmationAnimationLoader displayLoader={displayLoader} loadingStatus={loadingStatus} />
        <div className={classes.mainContainer}>
          <FormControl variant="filled" className={classes.selectInputControl} fullWidth margin="normal">
            <Select
              id="themeName"
              name="themeName"
              value={themeName}
              onChange={handleChange}
              className={classes.selectInput}
              disableUnderline={true} >
              {
                themes.map((theme, index) => {
                  return ((isFreePlan || isContactTracingPlan) && index > 5) ?
                    <MenuItem key={theme.id} value={theme.id} className={classes.proOnlyTextContainer}>
                      <Typography className={classes.lockedThemeName}>
                        {theme.id}
                      </Typography>
                      <LockIcon color='primary' className={classes.lockIcon}/>
                    </MenuItem>
                    : <MenuItem key={theme.id} value={theme.id}>{theme.id}</MenuItem>
                })
              }
              {
                !_.isEmpty(mainMenuCustomTheme) && (isLockedTheme ? <MenuItem value="custom" className={classes.proOnlyTextContainer}>
                  <Typography className={classes.lockedThemeName}>
                    Custom
                  </Typography>
                  <LockIcon color='primary' className={classes.lockIcon}/>
                </MenuItem> : <MenuItem key="custom" value="custom">custom</MenuItem>)
              }
            </Select>
          </FormControl>
          <Button variant="contained"
            color="primary"
            className={classes.signUpButton}
            onClick={handleCustomizeClick} >
            Customize
          </Button>
          <div className={classes.previewTextContainer}>
            <Grid container maxWidth="sm">
              <Grid item xs>
                <Typography variant="body2" align="left" className={classes.subTitleText}>Preview</Typography>
              </Grid>
            </Grid>
            <Divider />
          </div>
          <div className="previewContainer">
            <Preview selectedMenuList={updatedMenuList}
              userDetails={userDetails}
              updatedMenuTheme={currentTheme} />
          </div>
        </div>
      </Container>

      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar className="bottomTool">
          <Typography variant="h6" className={classes.title} button="true" onClick={publishMenu}>
            Publish
          </Typography>
        </Toolbar>
      </AppBar>

    </div >
  );
};

export default MenuSettingsPreview;
