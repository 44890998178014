/* eslint-disable no-useless-escape */
//no-useless-escape is disable here || It gives warning in Regular expression for email
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  TextField,
  Button,
  CssBaseline,
  Typography,
  Checkbox,
  InputAdornment,
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  CardMedia,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import AboardWordmark from "../../assets/images/WordMark-black.svg";
import _ from "lodash";

import { signUp } from "../../store/actions/authActions";
import { SIGNUP_RESET } from "../../store/actions/actionTypes";
import { showPopupAction } from '../../store/actions/popupActions';
import { Link } from "react-router-dom";
import { createAction } from "@reduxjs/toolkit";
import countryData from "../../constants/countryDataProd.json";

const signUpReset = createAction(SIGNUP_RESET);

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    margin: theme.spacing(3, 0, 3, 0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  form: {
    width: "100%",
  },
  signUpButton: {
    minWidth: '100px',
    width: '50%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  passwordField: {
    width: "100%",
  },
  termsBox: {
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(1),
  },
  loginLink: {
    marginTop: theme.spacing(4),
    paddingTop: '20px',
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.38)",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  termsText: {
    margin: "4% 2% 2% 0%",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.38)",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  selectInput: {
    textAlign: 'left'
  },
  logo: {
    height: '100%',
    width: '280px',
    objectFit: 'cover',
    margin: theme.spacing(4, "auto"),
  },
  card: {
    borderColor: '#3A7F59',
    borderStyle: 'solid',
    borderRadius: '10px',
    borderWidth: '2px',
    backgroundColor: '#FFF',
    width: '90%',
    padding: '20px',
    boxShadow: '5px 10px #3A7F59',
  },
}));

const Signup = ({ history }) => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    company: "",
    country: "",
    phone: "",
    errors: {},
    showPassword: false,
    agreeTerms: false,
  });
  // const [isSignUpRequestSent, setIsSignUpRequestSent] = useState(false);

  const classes = useStyles();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const signUpError = useSelector((state) => state.auth.error);
  const dispatch = useDispatch();

  if (signUpError) {
    dispatch(showPopupAction({ showPopup: true, popupType: "ErrorSignupMessage", header: "Error Signup!", subHeader: `${signUpError.message}` }));
    // setIsSignUpRequestSent(false);
    dispatch(signUpReset());
  }

  const handleChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { name, value } = event.target;
    const { errors } = values;
    delete errors[name];
    setValues({ ...values, errors, [name]: value });
  };

  const handleClickShowPassword = (event) => {
    event.preventDefault();
    const { showPassword } = values;
    setValues({ ...values, showPassword: !showPassword });
  };

  const handleAgreeTermsChange = () => {
    const { agreeTerms } = values;
    setValues({ ...values, agreeTerms: !agreeTerms });
  };

  const validate = () => {
    const emailRegExp = /^(([^<>()\[\],;:\s@]+(\.[^<>()\[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i;
    const passwordRegExp = /^(?=.{8,})/;
    const contactNumberRegExp = /^([0-9]{9,11})$/gm;
    const requiredFields = ["company", "country"];
    let errors = {};
    const { email, password, phone } = values;
    requiredFields.forEach((fieldName) => {
      if (_.isEmpty(values[fieldName].trim())) {
        errors[fieldName] = `Please enter ${fieldName}`;
      }
    });
    if (!emailRegExp.test(email)) {
      errors.email = "Please enter valid email address";
    }
    if (!passwordRegExp.test(password)) {
      errors.password =
        "Password must be at least 8 characters or longer";
    }
    if (!contactNumberRegExp.test(phone)) {
      errors.phone = 'Please enter valid contact number';
    }
    return errors;
  };

  const handleSignUp = (event) => {
    event.preventDefault();
    const validationErrors = validate();
    const { email, password, company, country, phone } = values;
    if (_.isEmpty(validationErrors)) {
      dispatch(signUp({ email, password, company, country, phone }, history));
    } else {
      setValues({ ...values, errors: validationErrors });
    }
  };

  const {
    company,
    country,
    email,
    password,
    phone,
    agreeTerms,
    showPassword,
    errors,
  } = values;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.mainContainer}>
      <CardMedia
          component="img"
          alt="logo"
          className={classes.logo}
          image={AboardWordmark}
          title="Logo"
        />
        <div className={classes.card}>
          <Typography variant="h5" component="h5" className={classes.title}>
            Sign up now!
          </Typography>
          <Typography variant="subtitle2" component="subtitle2" className={classes.title}>
            Create online menus and publish them. Share your dedicated link and QR code. Enjoy contactless menus.
          </Typography>
          <form className={classes.form} onSubmit={handleSignUp}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="company"
              label="Restaurant Name"
              name="company"
              value={company}
              onChange={handleChange}
              error={!_.isEmpty(errors.company)}
              helperText={errors.company}
              autoComplete="rName"
            />
            <FormControl variant="outlined" fullWidth margin="normal" error={!_.isEmpty(errors.country)}>
              <InputLabel id="country">Region</InputLabel>
              <Select
                id="country"
                name="country"
                value={country}
                className={classes.selectInput}
                onChange={handleChange}
                labelWidth={60}
              >
                {
                  Object.keys(countryData).map((countryName) => (<MenuItem key={countryName} value={countryName}>{countryName}</MenuItem>))
                }
              </Select>
              {!_.isEmpty(errors.country) && (
                <FormHelperText id="component-error-text">
                  {errors.country}
                </FormHelperText>
              )}
            </FormControl>
            <TextField
              variant="outlined"
              margin="normal"
              type="number"
              fullWidth
              id="phone"
              label="Contact Number"
              placeholder="Contact Number"
              name="phone"
              value={phone}
              onChange={handleChange}
              error={!_.isEmpty(errors.phone)}
              helperText={errors.phone}
              className={`contactNumber ${classes.inputText}`}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={email}
              onChange={handleChange}
              error={!_.isEmpty(errors.email)}
              helperText={errors.email}
              autoComplete="email"
            />
            <FormControl
              className={classes.passwordField}
              variant="outlined"
              margin="normal"
              error={!_.isEmpty(errors.password)}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                name="password"
                value={password}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={85}
                fullWidth
              />
              {!_.isEmpty(errors.password) && (
                <FormHelperText id="component-error-text">
                  {errors.password}
                </FormHelperText>
              )}
            </FormControl>
            <div className={classes.termsBox}>
              <Checkbox
                checked={agreeTerms}
                onChange={handleAgreeTermsChange}
                name="agreeTerms"
                color="primary"
              />
              <a target="_blank" href="https://www.iubenda.com/terms-and-conditions/29014545" className={classes.termsText}>
                I agree to the terms of service.
              </a>
              {/* <Typography
                variant="subtitle1"
                gutterBottom
                className={classes.termsText}
              >
                I agree to the
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                className={classes.termsText}
              >
                <a href='https://www.iubenda.com/terms-and-conditions/29014545'>
                terms of service
                </a>
              </Typography> */}
            </div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.signUpButton}
              disabled={!agreeTerms}
            >
              Sign Up
            </Button>
          </form>
          <br/>
          <br/>
          <Link to="/login" className={classes.loginLink}>
            Have an Account? Sign in
          </Link>
        </div>
      </div>
    </Container>
  );
};

export default Signup;
