import { createAction } from '@reduxjs/toolkit';
import {
  SHOW_POP_UP,
  HIDE_POP_UP,
} from './actionTypes';

export const showPopup = createAction(SHOW_POP_UP);
export const showPopupAction = (data) => (dispatch) => {
  dispatch(showPopup({...data}));
};

export const hidePopup = createAction(HIDE_POP_UP);
export const hidePopupAction = (data) => (dispatch) => {
  dispatch(hidePopup());
};
