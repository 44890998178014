import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  CardMedia,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

import { logIn } from "../../store/actions/authActions";
import AboardWordmark from "../../assets/images/WordMark-black.svg";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    margin: theme.spacing(3, 0, 3, 0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  form: {
    width: "100%"
  },
  signInButton: {
    marginTop: theme.spacing(5),
    minWidth: '100px',
    width: '50%',
    marginBottom: theme.spacing(1),
  },
  passwordField: {
    width: "100%"
  },
  link: {
    marginTop: theme.spacing(4),
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.38)",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  logo: {
    height: '100%',
    width: '280px',
    objectFit: 'cover',
    margin: theme.spacing(4, "auto"),
  },
  card: {
    borderColor: '#3A7F59',
    borderStyle: 'solid',
    borderRadius: '10px',
    borderWidth: '2px',
    backgroundColor: '#FFF',
    width: '90%',
    padding: '20px',
    boxShadow: '5px 10px #3A7F59',
  },
  linkContainer: {
    marginTop: theme.spacing(2.5)
  }
}));

const Login = ({ history }) => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    errors: {},
    showPassword: false
  });
  const classes = useStyles();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const {name, value} = event.target;
    const {errors} = values;
    delete errors[name];
    setValues({...values, errors, [name]: value});
  };

  const validate = () => {
    const requiredFields = ["email", "password"];
    let errors = {};
    requiredFields.forEach((fieldName) => {
      if (_.isEmpty(values[fieldName].trim())) {
        errors[fieldName] = `Please enter ${fieldName}`;
      }
    });
    return errors;
  };

  const handleClickShowPassword = (event) => {
    event.preventDefault();
    const {showPassword} = values;
    setValues({...values, showPassword: !showPassword});
  };

  const handleSignIn = (event) => {
    event.preventDefault();
    const validationErrors = validate();
    if (_.isEmpty(validationErrors)){
      const {email, password} = values;
      dispatch(logIn({ email, password }));
    } else {
      setValues({...values, errors: validationErrors});
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/");
    }
  }, [history, isAuthenticated]);

  const {email, password, showPassword, errors} = values;
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <div className={classes.mainContainer}>
        <CardMedia
          component="img"
          alt="logo"
          className={classes.logo}
          image={AboardWordmark}
          title="Logo"
        />
        <div className={classes.card}>
          <Typography variant="h5" component="h5" className={classes.title}>Login</Typography>
          <form className={classes.form} onSubmit={handleSignIn}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={email}
              onChange={handleChange}
              error={!_.isEmpty(errors.email)}
              helperText={errors.email}
              autoComplete="email"
            />
            <FormControl className={classes.passwordField} variant="outlined" margin="normal" required error={!_.isEmpty(errors.password)}>
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                name="password"
                value={password}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={85}
                fullWidth
              />
              {
                !_.isEmpty(errors.password) && <FormHelperText id="component-error-text">{errors.password}</FormHelperText>
              }
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.signInButton}
            >
              Sign In
            </Button>
          </form>
          <Typography className={classes.linkContainer}>
            <Link to="/forgot-password" className={classes.link}>Forgot password!</Link>
          </Typography>
          <Typography className={classes.linkContainer}>
            <Link to="/signup" className={classes.link}>Sign up!</Link>
          </Typography>
        </div>
      </div>
    </Container>
  );
};

export default Login;
