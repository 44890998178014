import React from "react";
import { AppBar, IconButton, Toolbar, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { makeStyles } from "@material-ui/core/styles";
import BillingForm from "../../components/billingForm";

const useStyles = makeStyles((theme) => ({
  headerTitle: {
    flexGrow: 2,
    textAlign: "initial",
    paddingLeft: theme.spacing(2)
  }
}));

const BillingSettings = (props) => {
  const classes = useStyles();
  return (
    <div>
      <AppBar position="fixed" color="primary">
        <Toolbar>
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={() => props.history.push("/settings")} >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" className={classes.headerTitle}>
            Billing Settings
          </Typography>
          <div></div>
        </Toolbar>
      </AppBar>
      <BillingForm isUpdate={true} planType={props.location.state && props.location.state.planType} nextPage={props.location.state && props.location.state.nextPage}/>
    </div>
  );
};

export default BillingSettings;
