import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/storage'

//PROD
export const firebaseConfig = {
  apiKey: "AIzaSyD3PDAvx-H8dvvoaznIiY-bIuQ9fGlutLc",
  authDomain: "aboard-v1.firebaseapp.com",
  databaseURL: "https://aboard-v1.firebaseio.com",
  projectId: "aboard-v1",
  storageBucket: "aboard-v1.appspot.com",
  messagingSenderId: "912455003119",
  appId: "1:912455003119:web:2680998990ad56be75a41a",
  measurementId: "G-DYR8YR5M5L"
};

//DEV
// export const firebaseConfig = {
//   apiKey: "AIzaSyAsLJgs9RNF4Tv-WSCAGA3rbfKQdLClkg4",
//   authDomain: "aboard-dev.firebaseapp.com",
//   databaseURL: "https://aboard-dev.firebaseio.com",
//   projectId: "aboard-dev",
//   storageBucket: "aboard-dev.appspot.com",
//   messagingSenderId: "423202268830",
//   appId: "1:423202268830:web:dfea61092e19d4e2467d8f",
//   measurementId: "G-KSJPH25SSH"
// };

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const database = firebase.firestore(app);
export const storage = firebase.storage();
export default firebase;
