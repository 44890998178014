/* eslint-disable no-console */
import { createAction } from '@reduxjs/toolkit';
import {
  THEMES_GET_ALL_START,
  THEMES_GET_ALL_SUCCESS,
  THEMES_GET_ALL_FAILED,
} from './actionTypes';
import * as API from '../../functions/api';

export const getThemesStart = createAction(THEMES_GET_ALL_START);
export const getThemesSuccess = createAction(THEMES_GET_ALL_SUCCESS);
export const getThemesFailed = createAction(THEMES_GET_ALL_FAILED);
export const getAllThemes = () => (dispatch) => {
  dispatch(getThemesStart());
  return API.getThemes()
    .then((themes) => {
      if (themes) {
        dispatch(getThemesSuccess(themes));
      }
    })
    .catch((e) => {
      console.error('getallthemes error: ', e);
      dispatch(getThemesFailed(e));
    });
};
