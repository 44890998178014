/* eslint-disable react-hooks/exhaustive-deps  */
//react-hooks/exhaustive-deps is disable here || It gives warning in useEffect has a missing dependency
import React, { useState, useEffect } from "react";
import _ from "lodash";
import { getMenusByLink } from "../../functions/api";
import "./preview.scss";
import { useParams } from "react-router-dom";
// import { themes } from "../../assets/themes";
import RestroLogo from "../MenuPreview/RestroLogo";
import Row from "../MenuPreview/Row";
import MenuBlock from './MenuBlock';

const artPopImage = 'https://firebasestorage.googleapis.com/v0/b/aboard-dev.appspot.com/o/backgrounds%2FTemplate%202_Grid_Background_colour.jpg?alt=media&token=e31e6c5c-ca45-428c-8748-9de485cfec6f';

const Preview = (props) => {
  // const styleData = themes.Cinema;
  const [menuInfo, setMenuInfo] = useState([]);
  const [logoTheme,setLogoTheme] = useState(null)
  const { link } = useParams();

  let backgroundStyles = {};
  // Home Page Theme 
  const homeTheme = props.updatedMenuTheme;
  if (homeTheme && homeTheme.background) {
    backgroundStyles = {
      backgroundColor: homeTheme.background.color,
      backgroundImage: `url(${homeTheme.background.backgroundImage})`,
      backgroundRepeat: homeTheme.background.backgroundRepeat,
      backgroundSize: homeTheme.background.backgroundImage === artPopImage ? 'cover' : 'contain'
    };
  }

  useEffect(() => {
    if (_.isEmpty(props.selectedMenuList)) {
      getMenusByLink(link).then((res) => {
        setMenuInfo(res);
        // if (res.menuTheme&res.menuThem.theme) styleData = themes[res.menuTheme.theme];
      });
    } else {
      setMenuInfo(props.selectedMenuList);
    }
    if(props.updatedMenuTheme){
      setLogoTheme(props.updatedMenuTheme.logo)
    }
  }, [props.selectedMenuList]);

  return (
    <div className="menu previewMenuBuilder" style={{ ...backgroundStyles }}>
     {props.updatedMenuTheme ? <RestroLogo
         logo={props.userDetails.photoUrl}
         restaurantName={props.userDetails.company}
         homeTheme={props.updatedMenuTheme}
         menuClass={`nameOnly`}
         logoTheme={logoTheme}
         includeLogo={props.updatedMenuTheme.includeLogo}
       />
        : `` }
      <Row>
        {menuInfo.map((menue, menueIndex) => {
          if (menue.toggle) {
            return (
              <div key={menueIndex} className="row-item">
                <MenuBlock
                  name={menue.name}
                  link={menue.id}
                  theme={props.updatedMenuTheme}
                />
              </div>
            );
          }

          return null;
        })}
      </Row>
    </div>
  );
};

export default Preview;