import React from "react";

const RestroLogo = (props) => {
  let restaurantHeadingStyles = {};
  let logoTheme = {};
  let menuNameStyle = {};
  let logoWrapperBackground = {};

  const { homeTheme } = props;

  if (props.themeData && props.themeData.theme) {
    const theme = props.themeData.theme;
    if (theme.fonts.heading) {
      const restaurantHeadingStylesObj = theme.fonts.heading;
      restaurantHeadingStyles = {
        ...restaurantHeadingStylesObj,
      };
    }
    // COLOR Scheme Is NOT DEFAULT THEN OVERRIDE COLORS
    if (theme.name === 'custom' && theme.colors) {
      menuNameStyle.color = theme.colors.secondary;
      if (props.layoutClass === 'float') {
        logoWrapperBackground.backgroundColor = theme.colors.primary;
      }
    }
  }

  if (props.logoTheme) {
    if (Object.keys(props.logoTheme).length > 0) {
      logoTheme = { border: `${props.logoTheme.borderWidth} ${props.logoTheme.borderStyle} ${props.logoTheme.borderColor}` };
    }
  }

  if (homeTheme && !props.logoTheme && !props.themeData && Object.keys(homeTheme).length > 0) {
    if (homeTheme.fonts && homeTheme.fonts.heading) {
      const restaurantHeadingStylesObj = homeTheme.fonts.heading;
      restaurantHeadingStyles = {
        ...restaurantHeadingStylesObj,
      };
    }
    if (homeTheme.logo && Object.keys(homeTheme.logo).length > 0) {
      logoTheme = {
        border: `${homeTheme.logo.borderWidth} ${homeTheme.logo.borderStyle} ${homeTheme.logo.borderColor}`,
      };
    }
    // COLOR Scheme Is NOT DEFAULT THEN OVERRIDE COLORS
    if (homeTheme.theme === 'custom' && homeTheme.colors) {
      if (props.layoutClass === 'float') {
        logoWrapperBackground.backgroundColor = homeTheme.colors.primary;
      }
    }
  }

  return (
    <div className={`RestroLogoWapper ${props.layoutClass ? props.layoutClass : ``}`} style={{ ...logoWrapperBackground}}>
      <div className="wrapperLogo" style={{ ...logoTheme }}>
        <div className={`name ${(props.menuClass)?props.menuClass:``}`}>
        {
          props.includeLogo ?
            (props.logo ? <img src={props.logo} alt={props.restaurantName} className={`restaurantLOGO ${props.imageClass}`}/>
            : <span style={{ ...restaurantHeadingStyles }}>{props.restaurantName}</span>)
            : <span style={{ ...restaurantHeadingStyles }}>{props.restaurantName}</span>
        }
        </div>
      </div>
      <div className="name">
        <h4 className="restroName h4Text" style={{ ...menuNameStyle }}> {props.menuName} </h4>
      </div>
    </div>
  );
};

export default RestroLogo;