import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Container, CssBaseline, IconButton, Toolbar, Typography} from "@material-ui/core";
import ColorPicker from "../../components/ColorPicker";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {updateMainMenuTempTheme} from "../../store/actions/authActions";

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
  },
  headerTitle: {
    flexGrow: 2,
    textAlign: "initial",
    paddingLeft: theme.spacing(2)
  },
  title: {
    flexGrow: 2,
  },
  mainContainer: {
    margin: theme.spacing(8, 0, 8, 0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%"
  }
}));

const CustomizeMainMenuColor = (props) => {
  const [currentColorScheme, setCurrentColorScheme] = useState({
    cardBackground: {
      backgroundRepeat: "",
      color: "#FFF",
      backgroundImage: ""
    },
    cardBorder: {
      borderColor: "#000",
      borderStyle: "solid",
      borderWidth: "2px"
    }
  });
  const mainMenuTempTheme = useSelector((state) => state.auth.mainMenuTempTheme);
  const isFreePlan = useSelector((state) => state.stripe.isFreePlan);
  const isContactTracingPlan = useSelector((state) => state.stripe.isContactTracingPlan);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    const { cardBackground, cardBorder } = mainMenuTempTheme;
    currentColorScheme['cardBackground'] = cardBackground || currentColorScheme.cardBackground;
    currentColorScheme['cardBorder'] = cardBorder || currentColorScheme.cardBorder;
    setCurrentColorScheme({...currentColorScheme});
  }, [mainMenuTempTheme]);

  const handleChange = (propName, color) => {
    const path = propName.split('.');
    const newColorSchema = _.cloneDeep(currentColorScheme);
    const {rgb: {r, g, b, a}} = color;
    newColorSchema[path[0]][path[1]] = `rgba(${r},${g},${b},${a})`;
    setCurrentColorScheme({...newColorSchema});
  }

  const handleApplyChanges = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const finalTheme = _.merge({}, mainMenuTempTheme, currentColorScheme);
    dispatch(updateMainMenuTempTheme(finalTheme));
    props.history.push('/menu-theme-settings');
  }

  return (
    <div>
      <AppBar position="fixed" color="primary">
        <Toolbar>
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={() => {props.history.push('/menu-theme-settings')}}
          >
            <ArrowBackIosIcon/>
          </IconButton>
          <Typography variant="h6" className={classes.headerTitle}>
            Customize Colors
          </Typography>
          <div></div>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm">
        <CssBaseline/>
        <div className={classes.mainContainer}>
          <form className={classes.form}>
            <ColorPicker propName="cardBackground.color" label='Menu Item Background Color' color={currentColorScheme.cardBackground.color} isFreePlan={isFreePlan || isContactTracingPlan} handleColorChange={handleChange}/>
            <ColorPicker propName="cardBorder.borderColor" label='Menu Item Border' color={currentColorScheme.cardBorder.borderColor} isFreePlan={isFreePlan || isContactTracingPlan} handleColorChange={handleChange}/>
          </form>
        </div>
      </Container>
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar className="bottomTool">
          <Typography variant="h6" className={classes.title} button="true" onClick={handleApplyChanges}>
            Apply Changes
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default CustomizeMainMenuColor;
