/* eslint-disable no-useless-escape */
//no-useless-escape is disable here || It gives warning in Regular expression for email
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Container,
  CssBaseline,
  TextField,
  Toolbar,
  Typography,
  Divider,
  ListItem,
  List,
  ListItemSecondaryAction,
  ListItemText,
  CardMedia,
  Checkbox,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import moment from "moment";
import NavigationBlockerPrompt from "../../components/NavigationBlockerPrompt";
import { retrieveAllOrders } from "../../store/actions/ordersActions";
import colors from "../../constants/colors";

import "./settings.scss";

const useStyles = makeStyles((theme) => ({
  headerTitle: {
    flexGrow: 2,
    textAlign: 'initial',
    paddingLeft: theme.spacing(2),
  },
  mainContainer: {
    margin: theme.spacing(10, 0, 8, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    padding: 0
  },
  form: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  inputText: {
    margin: theme.spacing(0, 0, 2, 0),
    '& .MuiInputBase-root-122': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  subTitleText: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: theme.spacing(1.5),
  },
  listTitle: {
    lineHeight: '1.3',
    padding: theme.spacing(2, 0),
  }
}));

const opts = {
  height: "390",
  width: "640",
  padding: "5",
  playerVars: {
    autoplay: 0,
  },
};

const OrderOverview = (props) => {
  const { orders } = props;
  const classes = useStyles();
  return (
    <div>
        <List className="listingDashboard">
          {orders.map((order) => (
            <ListItem className="listingItemDashboard" key={order.orderId}>
              <ListItemText primary={order.orderId} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  id={order.orderId}
                  name={order.customerName}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
    </div>
  );
};

export default OrderOverview;
