/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  STRIPE_CREATE_CUSTOMER_START,
  STRIPE_CREATE_CUSTOMER_SUCCESS,
  STRIPE_CREATE_CUSTOMER_FAILED,
  STRIPE_GET_CUSTOMER_FAILED,
  STRIPE_GET_CUSTOMER_START,
  STRIPE_GET_CUSTOMER_SUCCESS,
  STRIPE_CREATE_SUBSCRIPTION_START,
  STRIPE_CREATE_SUBSCRIPTION_SUCCESS,
  STRIPE_CREATE_SUBSCRIPTION_FAILED,
  STRIPE_UPDATE_SUBSCRIPTION_START,
  STRIPE_UPDATE_SUBSCRIPTION_SUCCESS,
  STRIPE_UPDATE_SUBSCRIPTION_FAILED,
  STRIPE_CANCEL_SUBSCRIPTION_START,
  STRIPE_CANCEL_SUBSCRIPTION_SUCCESS,
  STRIPE_CANCEL_SUBSCRIPTION_FAILED,
  LOGOUT_SUCCESS,
} from '../actions/actionTypes';
import countryData from "../../constants/countryDataProd.json";

const initialState = {
  data: null,
  error: null,
  loading: false,
  isFreePlan: true,
  isContactTracingPlan: false
};

const stripeReducer = createReducer(
  initialState,
  {
    [STRIPE_CREATE_CUSTOMER_START]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [STRIPE_CREATE_CUSTOMER_SUCCESS]: (state, action) => {
      // state.data = action.payload.data;
      state.loading = false;
      state.error = null;
    },
    [STRIPE_CREATE_CUSTOMER_FAILED]: (state, action) => {
      state.data = null;
      state.loading = false;
      state.error = action.payload.data;
    },
    [STRIPE_UPDATE_SUBSCRIPTION_START]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [STRIPE_UPDATE_SUBSCRIPTION_SUCCESS]: (state, action) => {
      // state.data = action.payload.data;
      state.loading = false;
      state.error = null;
    },
    [STRIPE_UPDATE_SUBSCRIPTION_FAILED]: (state, action) => {
      state.loading = false;
      state.error = action.payload.data;
    },
    [STRIPE_GET_CUSTOMER_START]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [STRIPE_GET_CUSTOMER_SUCCESS]: (state, action) => {
      state.data = action.payload.data;
      state.loading = false;
      state.error = null;
      let isContactTracingPlan = false;
      if (action.payload.data &&
        action.payload.data.subscriptions.data &&
        action.payload.data.subscriptions.data.length > 0) {
        state.isFreePlan = !action.payload.data.subscriptions.data[0].plan.active;
        _.map(countryData, (planData, country) => {
          if (planData.contact_tracing && planData.contact_tracing.stripe_product_id === action.payload.data.subscriptions.data[0].plan.id) {
            isContactTracingPlan = true;
          }
        });
        state.isContactTracingPlan = isContactTracingPlan;
      }
    },
    [STRIPE_GET_CUSTOMER_FAILED]: (state, action) => {
      state.data = null;
      state.loading = false;
      state.error = action.payload.data;
    },
    [LOGOUT_SUCCESS]: (state) => {
      Object.keys(state).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
);

export default stripeReducer;
