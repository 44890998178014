import React from "react";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

const LikeBar = (props) => {
    let bottomBarColor = {};
    let trayStyles = {};

    if (props && props.theme ) {
      trayStyles = props.theme.fonts.tray;

      if (props.theme.tray) {
        bottomBarColor = { backgroundColor: props.theme.tray.color };
      } else if (props.theme.name === 'custom' && props.theme.colors){
        bottomBarColor = { backgroundColor: props.theme.colors.accent };
      } else {
        bottomBarColor = { backgroundColor: '#000' };
      }
    }
  return (
    <div
      className="bottomBarWapper drawerClose "
      style={bottomBarColor}
    >
      <div className="bottomBar">
        <div className="likeItem drawerClose">
          <div className="counter">
            <span>
              <span className="heartCount">0</span>
             <FavoriteBorderIcon />
            </span>
          </div>
          <div className="textWapper">
            <span style={{ ...trayStyles }}>LIKED ITEMS</span>
          </div>
        </div>
      </div>
      <div className="drawerWrapperMain" />
    </div>
  );
};

export default LikeBar;
