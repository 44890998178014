/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import {
  SIGNUP_SUCCESS,
  SIGNUP_FAILED,
  SIGNUP_RESET,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  GET_USER_DATA_START,
  GET_USER_DATA_FAILED,
  GET_USER_DATA_SUCCESS,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,
  UPDATE_MAIN_MENU_THEME,
  UPDATE_MAIN_MENU_CUSTOM_THEME,
  SET_EMAIL_VERIFIED_FLAG,
  UPDATE_MAIN_MENU_TEMP_THEME,
} from '../actions/actionTypes';

const initialState = { user: null, isAuthenticated: false, emailVerified: false, error: null, updatedMenuTheme: {}, mainMenuCustomTheme: {}, mainMenuTempTheme: {} };

const authReducer = createReducer(
  initialState,
  {
    [SIGNUP_SUCCESS]: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.error = null;
    },
    [SIGNUP_FAILED]: (state, action) => {
      state.user = null;
      state.isAuthenticated = false;
      state.error = action.payload;
    },
    [SIGNUP_RESET]: (state, action) => {
      state.user = null;
      state.isAuthenticated = false;
      state.error = null;
    },
    [LOGIN_SUCCESS]: (state, action) => {
      state.user = action.payload.userDetails;
      state.emailVerified = action.payload.emailVerified;
      state.isAuthenticated = true;
    },
    [SET_EMAIL_VERIFIED_FLAG]: (state, action) => {
      state.emailVerified = action.payload.emailVerified;
    },
    [LOGIN_FAILED]: (state) => {
      state.user = null;
      state.isAuthenticated = false;
    },
    [UPDATE_USER_SUCCESS]: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    [UPDATE_USER_FAILED]: (state, action) => {
      state.error = action.payload;
    },
    [LOGOUT_SUCCESS]: (state) => {
      Object.keys(state).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    [LOGOUT_FAILED]: (state, action) => {
      state.error = action.payload;
    },
    [GET_USER_DATA_START]: (state) => {
      state.error = null;
    },
    [GET_USER_DATA_SUCCESS]: (state, action) => {
      state.user = { ...state.user, ...action.payload };
      state.error = null;
    },
    [GET_USER_DATA_FAILED]: (state, action) => {
      state.error = action.payload;
    },
    [UPDATE_MAIN_MENU_THEME]: (state,action) => {
      state.updatedMenuTheme = action.payload;
    },
    [UPDATE_MAIN_MENU_CUSTOM_THEME]: (state,action) => {
      state.mainMenuCustomTheme = action.payload;
    },
    [UPDATE_MAIN_MENU_TEMP_THEME]: (state,action) => {
      state.mainMenuTempTheme = action.payload;
    },
  },
);

export default authReducer;
