import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  CssBaseline,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Divider,
  Button,
  CardMedia,
  ListItemSecondaryAction
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import LockIcon from "@material-ui/icons/Lock";
import {makeStyles} from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";

import presetData from "../../constants/presetData.json";
import { updateMainMenuTheme, updateMainMenuCustomTheme, updateMainMenuTempTheme } from "../../store/actions/authActions";
import ColorPicker from "../../components/ColorPicker";
import {hideLoaderAction, showLoaderAction} from "../../store/actions/loaderActions";
import {uploadBackgroundImage} from "../../firebase/menu";
import colors from '../../constants/colors';

import './MenuThemeSettings.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1)
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
  headerTitle: {
    flexGrow: 2,
    textAlign: "initial",
    paddingLeft: theme.spacing(2)
  },
  title: {
    flexGrow: 2,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  textLabel: {
    marginTop: theme.spacing(4),
    fontFamily: "roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.38)"
  },
  mainContainer: {
    margin: theme.spacing(0, 0, 8, 0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%"
  },
  selectInput: {
    textAlign: 'left'
  },
  subHeaderContainer: {
    width: "100%"
  },
  menuPhoto: {
    width: '120px',
    height: '120px',
    margin: 'auto'
  },
  uploadImageButton: {
    marginTop: theme.spacing(1)
  },
  subTitleText: {
    padding: theme.spacing(0.75, 1)
  },
  marginTop: {
    marginTop: theme.spacing(10)
  },
  primaryRightAlignText: {
    textAlign: 'right',
    color: colors.primary
  },
  lockIcon: {
    padding: theme.spacing(0.25),
    marginLeft: theme.spacing(0.5)
  },
  noPaddingContainer: {
    padding: 0
  },
  premiumBannerText: {
    padding: theme.spacing(0.75, 1, 0.75, 0)
  }
}));

const allowedFileTypes = [
  'image/jpeg',
  'image/png'
];

const MenuThemeSettings = ({ history }) => {
  const classes = useStyles();
  const user = useSelector((state) => state.auth.user);
  const updatedMenuTheme = useSelector((state) => state.auth.updatedMenuTheme);
  const mainMenuTempTheme = useSelector((state) => state.auth.mainMenuTempTheme);
  const themes = useSelector((state) => state.theme.themes);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    includeLogo: false,
    themeName: "Cinema",
    backgroundTexture: "",
    backgroundColor: ""
  });
  const [showImageUploader, setShowImageUploader] = useState(false);
  const [customBackgroundURL, setCustomBackgroundURL] = useState('');
  const [menuFilePreviewURL, setMenuFilePreviewURL] = useState('');
  const [error, setError] = useState({});
  const [menuFile, setMenuFile] = useState();
  const isFreePlan = useSelector((state) => state.stripe.isFreePlan);
  const isContactTracingPlan = useSelector((state) => state.stripe.isContactTracingPlan);

  useEffect(() => {
    let menuTheme = {};
    const defaultTheme = themes.find((theme) => theme.id === "Cinema");
    const currentTheme = !_.isEmpty(mainMenuTempTheme) ? mainMenuTempTheme
      : (!_.isEmpty(updatedMenuTheme) ? updatedMenuTheme
        : (!_.isEmpty(user.menuTheme) ? user.menuTheme : defaultTheme)
      );
    const { includeLogo: themeIncludeLogo, theme, background: themeBackground } = currentTheme;
    const selectedTexture = presetData.textures.find((texture) => texture.backgroundImage === themeBackground.backgroundImage);
    menuTheme = {
      includeLogo: themeIncludeLogo || false,
      themeName: theme || "Cinema",
      backgroundTexture: selectedTexture ? selectedTexture.name : (themeBackground.backgroundImage ? 'customBackground' : 'Solid'),
      backgroundColor: themeBackground.color,
    };
    if (menuTheme.backgroundTexture === 'customBackground') {
      setCustomBackgroundURL(themeBackground.backgroundImage);
    }
    setValues(menuTheme);
  }, [user.menuTheme, updatedMenuTheme, themes]);

  const updateMenuTheme = () => {
    let updatedTheme = _.pick(values, ["includeLogo"]);
    const selectedTexture = presetData.textures.find((texture) => texture.name === values.backgroundTexture);
    updatedTheme.theme = values.themeName;
    updatedTheme.background = {
      backgroundImage: values.backgroundTexture === 'customBackground' ? customBackgroundURL : (selectedTexture ? selectedTexture.backgroundImage : ""),
      backgroundRepeat: selectedTexture ? selectedTexture.backgroundRepeat : "repeat",
      color: ["Solid", "Border"].includes(values.backgroundTexture) ? values.backgroundColor : user.menuTheme.background.color,
    };

    const defaultTheme = themes.find((theme) => theme.id === "Cinema");
    const currentTheme = !_.isEmpty(mainMenuTempTheme) ? mainMenuTempTheme
      : (!_.isEmpty(updatedMenuTheme) ? updatedMenuTheme
        : (!_.isEmpty(user.menuTheme) ? user.menuTheme : defaultTheme)
      );
    updatedTheme.fonts = currentTheme.fonts;
    updatedTheme.colors = currentTheme.colors;

    return _.merge({}, currentTheme, updatedTheme);
  }

  const handlePreview = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const finalTheme = updateMenuTheme();
    dispatch(updateMainMenuTheme(finalTheme));
    dispatch(updateMainMenuTempTheme({}));
    if (finalTheme.theme === "custom") {
      dispatch(updateMainMenuCustomTheme(finalTheme));
    }
    history.push('/menu-settings-preview');
  };

  const toggleImageUploadDialog = (showImageUploader) => {
    setShowImageUploader(showImageUploader);
  }

  const handleChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const {name, value} = event.target;
    if (value === "customBackground") {
      toggleImageUploadDialog(true);
      setValues({...values, [name]: value, themeName: "custom"});
    } else {
      toggleImageUploadDialog(false);
      setValues({...values, [name]: value, themeName: "custom"});
    }
  };

  const handleCheckBoxClick = (event) => {
    const { name } = event.target;
    setValues({...values, [name]: !values[name]});
  };

  const handleBackgroundChange = (propName, color) => {
    const {rgb: {r, g, b, a}} = color;
    setValues({...values, [propName]: `rgba(${r},${g},${b},${a})`, themeName: "custom"});
  }

  const handleCustomizeColorClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const finalTheme = updateMenuTheme();
    dispatch(updateMainMenuTempTheme(finalTheme));
    history.push('/customize-menu-colors');
  }

  const handleCustomizeFontClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const finalTheme = updateMenuTheme();
    dispatch(updateMainMenuTempTheme(finalTheme));
    history.push('/customize-menu-fonts');
  }

  const handleGoBackClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(updateMainMenuTempTheme({}));
    history.push('/menu-settings-preview');
  }

  const uploadBackground = (event) => {
    event.preventDefault();
    if (!_.isNil(menuFile)) {
      dispatch(showLoaderAction());
      return uploadBackgroundImage(menuFile, user.id).then((photoUrl) => {
        dispatch(hideLoaderAction());
        setCustomBackgroundURL(photoUrl);
        toggleImageUploadDialog(false);
      }).catch((e) => {
        setError({
          ...error,
          menuFile: 'Error occurred while uploading the background image. Please try again!'
        });
        dispatch(hideLoaderAction());
      });
    }
  };

  const onFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      if (allowedFileTypes.includes(event.target.files[0].type)) {
        const previewURL = URL.createObjectURL(event.target.files[0]);
        setMenuFilePreviewURL(previewURL);
        setMenuFile(event.target.files[0]);
        setError({
          ...error,
          menuFile: ''
        });
      } else {
        event.target.value = '';
        setError({
          ...error,
          menuFile: 'Please make sure that the menu file is of jpeg or png format.'
        });
      }
    }
  };

  const closeWarningAlert = (event) => {
    event.preventDefault();
    setError({
      ...error,
      menuFile: ''
    });
  }

  const handleUpgradeClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    history.push('/setup', { nextPage: '/menu-theme-settings' });
  }

  const {backgroundTexture, backgroundColor, includeLogo} = values;
  return (
    <div>
      <AppBar position="fixed" color="primary">
        <Toolbar>
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={handleGoBackClick}
          >
            <ArrowBackIosIcon/>
          </IconButton>
          <Typography variant="h6" className={classes.headerTitle}>
            Update Theme
          </Typography>
          <div></div>
        </Toolbar>
      </AppBar>
      { (isFreePlan || isContactTracingPlan) && <div className="premiumBannerContainer">
        <Container maxWidth='sm'>
          <Grid container>
            <Grid item xs>
              <Typography variant="subtitle2" align="left" className={classes.premiumBannerText}>Unlock more with Pro!</Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={handleUpgradeClick}
              >
                Upgrade
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
      }
      <Container component="main" maxWidth="sm">
        <CssBaseline/>
        <div className={`${classes.mainContainer} ${(!(isFreePlan || isContactTracingPlan)) ? classes.marginTop : '' }`}>
          <form className={classes.form}>
            <FormControl variant="filled" className={classes.formControl} fullWidth margin="normal">
              <InputLabel id="texture">Background Texture {(isFreePlan || isContactTracingPlan) && <IconButton edge="end" aria-label="lock" className={classes.noPaddingContainer}>
                <LockIcon color='primary' className={classes.lockIcon}/>
              </IconButton>}
              </InputLabel>
              <Select
                id="backgroundTexture"
                name="backgroundTexture"
                value={backgroundTexture}
                className={classes.selectInput}
                disabled={isFreePlan || isContactTracingPlan}
                onChange={handleChange}
              >
                {
                  presetData.textures.map((texture) => (<MenuItem key={texture.name} value={texture.name}>{texture.name}</MenuItem>))
                }
                <MenuItem key='custom-background' value='customBackground'>Custom</MenuItem>
              </Select>
            </FormControl>
            {
              showImageUploader &&
              <div className={classes.subHeaderContainer}>
                <Grid container maxWidth="sm">
                  <Grid item xs>
                    <Typography variant="subtitle1" align="left" className={classes.subTitleText}>Background Image</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      component="label"
                      className={classes.uploadButton}
                    >
                      Choose file
                      <input id="menuFile" type="file" accept="application/pdf, image/jpeg, image/png" onChange={onFileChange} style={{ display: 'none' }} />
                    </Button>
                  </Grid>
                </Grid>
                {
                  error.menuFile && <Alert severity="warning" className={classes.warningAlert} onClose={closeWarningAlert}>
                    {error.menuFile}
                  </Alert>
                }
                {
                  (menuFilePreviewURL || customBackgroundURL) ?
                    <CardMedia
                      component="img"
                      alt="Menu Image"
                      className={classes.menuPhoto}
                      image={menuFilePreviewURL || customBackgroundURL}
                      title="MenuImage"
                    />
                    : ''
                }
                {
                  menuFilePreviewURL && <Button
                    onClick={uploadBackground}
                    color="primary"
                    variant="contained"
                    className={classes.uploadImageButton}
                  >
                    Upload
                  </Button>
                }
              </div>
            }
            {
              ["Solid", "Border"].includes(backgroundTexture) &&
              <ColorPicker
                propName="backgroundColor"
                label="Background Color"
                color={backgroundColor}
                isFreePlan={isFreePlan || isContactTracingPlan}
                handleColorChange={handleBackgroundChange}
              />
            }
            <Grid container maxWidth="sm">
              <Grid item xs>
                <Typography variant="subtitle1" align="left" className={classes.subTitleText}>Color Scheme</Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  component="label"
                  className={classes.uploadButton}
                  disabled={isFreePlan || isContactTracingPlan}
                  onClick={handleCustomizeColorClick}
                >
                  Choose colors
                  {(isFreePlan || isContactTracingPlan) && <LockIcon color='primary' className={classes.lockIcon}/>}
                </Button>
              </Grid>
            </Grid>
            <Grid container maxWidth="sm">
              <Grid item xs>
                <Typography variant="subtitle1" align="left" className={classes.subTitleText}>Font Style</Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  component="label"
                  className={classes.uploadButton}
                  disabled={isFreePlan || isContactTracingPlan}
                  onClick={handleCustomizeFontClick}
                >
                  Choose fonts
                  {(isFreePlan || isContactTracingPlan) && <LockIcon color='primary' className={classes.lockIcon}/>}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
            <List className={classes.root}>
              <div key='includeLogo'>
                <ListItem role={undefined} dense>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      color="primary"
                      checked={includeLogo}
                      id="includeLogo"
                      name="includeLogo"
                      onChange={handleCheckBoxClick}
                      tabIndex={-1}
                      inputProps={{"aria-labelledby": "includeLogo"}}
                    />
                  </ListItemIcon>
                  <ListItemText id="includeLogo" primary="Include Logo at Top"/>
                </ListItem>
                <Divider variant="inset" component="li"/>
              </div>
            </List>
          </Grid>
          </form>
        </div>
      </Container>
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar className="bottomTool">
          <Typography variant="h6" className={classes.title} button="true" onClick={handlePreview}>
            Apply to Preview
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default MenuThemeSettings;
