import React from "react";
import { Link } from "react-router-dom";
import { Container, CssBaseline, Typography, Button, CardMedia } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import AboardLogo from "../../assets/images/aboard_logo.svg";
import colors from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    button: {
        marginTop: "24%",
        color: colors.white
    },
    link: {
        textTransform: "uppercase",
        textDecoration: "none",
        color: colors.white,
    },
    logo: {
        height: '100%',
        width: '100px',
        objectFit: 'cover',
        margin: theme.spacing(2, "auto"),
    },
}));

const UnAuthorized = () => {
    const classes = useStyles();
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classes.mainContainer}>
                <CardMedia
                    component="img"
                    alt="logo"
                    className={classes.logo}
                    image={AboardLogo}
                    title="Logo"
                />
                <Typography variant="h4" component="h4">Error 403!</Typography>
                <Typography variant="h6" component="h6">You are forbidden to access this page</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button} >
                    <Link to="/" className={classes.link}>Go to home page</Link>
                </Button>
            </div>
        </Container>
    );
};

export default UnAuthorized;
