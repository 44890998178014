import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  CssBaseline,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Divider,
  Button,
  CardMedia,
  ListItemSecondaryAction
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import LockIcon from "@material-ui/icons/Lock";
import {makeStyles} from "@material-ui/core/styles";
import _ from "lodash";

import presetData from "../../constants/presetData.json";
import { updateSelectedMenuTheme, updateTempSelectedMenu } from "../../store/actions/menuActions";
import ColorPicker from "../../components/ColorPicker";
import {hideLoaderAction, showLoaderAction} from "../../store/actions/loaderActions";
import {uploadBackgroundImage} from "../../firebase/menu";
import Alert from "@material-ui/lab/Alert";
import colors from '../../constants/colors';

import './ThemeSettings.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1)
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
  headerTitle: {
    flexGrow: 2,
    textAlign: "initial",
    paddingLeft: theme.spacing(2)
  },
  title: {
    flexGrow: 2,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  textLabel: {
    marginTop: theme.spacing(4),
    fontFamily: "roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.38)"
  },
  mainContainer: {
    margin: theme.spacing(0, 0, 8, 0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  marginTop: {
    marginTop: theme.spacing(10)
  },
  form: {
    width: "100%"
  },
  selectInput: {
    textAlign: 'left'
  },
  subTitleText: {
    padding: theme.spacing(0.75, 1)
  },
  subHeaderContainer: {
    width: "100%"
  },
  menuPhoto: {
    width: '120px',
    height: '120px',
    margin: 'auto'
  },
  uploadImageButton: {
    marginTop: theme.spacing(1)
  },
  primaryRightAlignText: {
    textAlign: 'right',
    color: colors.primary
  },
  lockIcon: {
    padding: theme.spacing(0.25),
    marginLeft: theme.spacing(0.5)
  },
  noPaddingContainer: {
    padding: 0
  },
  premiumBannerText: {
    padding: theme.spacing(0.75, 1, 0.75, 0)
  }
}));

const allowedFileTypes = [
  'image/jpeg',
  'image/png'
];

const ThemeSettings = ({ history }) => {
  const classes = useStyles();
  const user = useSelector((state) => state.auth.user);
  const selectedMenu = useSelector((state) => state.menu.selectedMenu);
  const tempSelectedMenu = useSelector((state) => state.menu.tempSelectedMenu);
  const themes = useSelector((state) => state.theme.themes);
  const isFreePlan = useSelector((state) => state.stripe.isFreePlan);
  const isContactTracingPlan = useSelector((state) => state.stripe.isContactTracingPlan);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    layout: "",
    emphasizeHighMargin: false,
    includeLogo: false,
    includePhotos: false,
    themeName: "",
    backgroundTexture: "",
    backgroundColor: "",
    colorScheme: "",
    includeSign: "None",
    decimals: 2
  });
  const [isWineType, setIsWineType] = useState(false);
  const [showImageUploader, setShowImageUploader] = useState(false);
  const [customBackgroundURL, setCustomBackgroundURL] = useState('');
  const [menuFilePreviewURL, setMenuFilePreviewURL] = useState('');
  const [error, setError] = useState({});
  const [menuFile, setMenuFile] = useState();

  useEffect(() => {
    let menuTheme = {};
    if (!_.isEmpty(selectedMenu.theme)) {
      let currentTheme = !_.isEmpty(tempSelectedMenu) ? tempSelectedMenu.theme : selectedMenu.theme;
      const { emphasizeHighMargin: themeMargin, includeLogo: themeIncludeLogo, includePhotos: themeIncludePhotos, theme, includeSign: themeIncludeSign, decimals: themeDecimals } = currentTheme;
      const selectedTexture = presetData.textures.find((texture) => texture.backgroundImage === theme.background.backgroundImage);
      const layout = theme.layout === 'linePhoto' ? 'line' : theme.layout;
      menuTheme = {
        layout: layout,
        emphasizeHighMargin: themeMargin,
        includeLogo: themeIncludeLogo,
        includePhotos: themeIncludePhotos || false,
        themeName: theme.name ,
        backgroundTexture: selectedTexture ? selectedTexture.name : (theme.background.backgroundImage ? 'customBackground' : 'Solid'),
        backgroundColor: theme.background.color,
        colorScheme: _.findKey(presetData.colorSchemes, theme.colors),
        includeSign: themeIncludeSign || 'None',
        decimals: _.isNil(themeDecimals) ? 2 : themeDecimals
      };
      if (menuTheme.backgroundTexture === 'customBackground') {
        setCustomBackgroundURL(theme.background.backgroundImage);
      }
    } else {
      // If menu does not have any theme then assign default theme "Cinema" to menu.
      const defaultTheme = themes.find((theme) => theme.id === "Cinema");
      const { layout: themeLayout, id, background, colors } = defaultTheme;
      const selectedTexture = presetData.textures.find((texture) => texture.backgroundImage === background.backgroundImage);
      menuTheme = {
        layout: themeLayout,
        emphasizeHighMargin: false,
        includeLogo: false,
        includePhotos: false,
        themeName: id,
        backgroundTexture: selectedTexture ? selectedTexture.name : 'Solid',
        backgroundColor: background.color,
        colorScheme: _.findKey(presetData.colorSchemes, colors),
        includeSign: 'None',
        decimals: 2
      };
    }
    let wineType = false;
    selectedMenu.sections.forEach((section) => {
      if (section.type === 'wine') {
        wineType = true;
      }
    });
    setIsWineType(wineType);
    setValues(menuTheme);
  }, [selectedMenu.theme, themes, tempSelectedMenu]);

  const updateMenuTheme = () =>{
    const defaultTheme = themes.find((theme) => theme.id === "Cinema");
    let currentTheme = (!_.isEmpty(tempSelectedMenu) && !_.isEmpty(tempSelectedMenu.theme)) ? tempSelectedMenu.theme.theme
      : (!_.isEmpty(selectedMenu.theme) ? selectedMenu.theme.theme : defaultTheme);
    let updatedTheme = _.pick(values, ["emphasizeHighMargin", "includeLogo", "includePhotos", "includeSign", "decimals"]);
    const selectedTexture = presetData.textures.find((texture) => texture.name === values.backgroundTexture);
    const layout = updatedTheme.includePhotos ? ( isWineType ? 'line' : 'linePhoto') : values.layout;
    updatedTheme.theme = {
      colors: presetData.colorSchemes[values.colorScheme],
      background: {
        backgroundImage: values.backgroundTexture === 'customBackground' ? customBackgroundURL : (selectedTexture? selectedTexture.backgroundImage : ""),
        backgroundRepeat: selectedTexture ? selectedTexture.backgroundRepeat : "repeat",
        color: ["Solid", "Border"].includes(values.backgroundTexture) ? values.backgroundColor : currentTheme.background.color,
      },
      name: values.themeName,
      layout: layout
    };
    updatedTheme.theme.fonts = currentTheme.fonts;

    return !_.isEmpty(tempSelectedMenu) ? _.merge({}, tempSelectedMenu.theme, updatedTheme) :_.merge({}, selectedMenu.theme, updatedTheme);
  }

  const handlePreview = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const finalTheme = updateMenuTheme();
    dispatch(updateSelectedMenuTheme(finalTheme));
    history.push('/update-menu-preview');
  };

  const toggleImageUploadDialog = (showImageUploader) => {
    setShowImageUploader(showImageUploader);
  }

  const handleChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const {name, value} = event.target;
    if (name === "includeSign" || name === "decimals") {
      setValues({...values, [name]: value});
    } else if (name === "backgroundTexture") {
      if (value === "customBackground") {
        toggleImageUploadDialog(true);
        setValues({...values, [name]: value, themeName: "custom"});
      } else {
        toggleImageUploadDialog(false);
        setValues({...values, [name]: value, themeName: "custom"});
      }
    } else {
      setValues({...values, [name]: value, themeName: "custom"});
    }
  };

  const handleCheckBoxClick = (event) => {
    const { name } = event.target;
    if (name === 'includePhotos') {
      setValues({...values, [name]: !values[name], layout: 'line'});
    } else {
      setValues({...values, [name]: !values[name]});
    }
  };

  const handleBackgroundChange = (propName, color) => {
    const {rgb: {r, g, b, a}} = color;
    setValues({...values, [propName]: `rgba(${r},${g},${b},${a})`, themeName: "custom"});
  };

  const handleCustomizeColorClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let newMenu = !_.isEmpty(tempSelectedMenu) ? _.cloneDeep(tempSelectedMenu) : _.cloneDeep(selectedMenu);
    newMenu.theme = updateMenuTheme();
    dispatch(updateTempSelectedMenu(newMenu));
    history.push('/customize-colors');
  }

  const handleCustomizeFontClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let newMenu = !_.isEmpty(tempSelectedMenu) ? _.cloneDeep(tempSelectedMenu) : _.cloneDeep(selectedMenu);
    newMenu.theme = updateMenuTheme();
    dispatch(updateTempSelectedMenu(newMenu));
    history.push('/customize-fonts');
  }

  const handleGoBackClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(updateTempSelectedMenu({}));
    history.push('/update-menu-preview');
  }

  const uploadBackground = (event) => {
    event.preventDefault();
    if (!_.isNil(menuFile)) {
      dispatch(showLoaderAction());
      return uploadBackgroundImage(menuFile, user.id).then((photoUrl) => {
        dispatch(hideLoaderAction());
        setCustomBackgroundURL(photoUrl);
        toggleImageUploadDialog(false);
      }).catch((e) => {
        setError({
          ...error,
          menuFile: 'Error occurred while uploading the background image. Please try again!'
        });
        dispatch(hideLoaderAction());
      });
    }
  };

  const onFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      if (allowedFileTypes.includes(event.target.files[0].type)) {
        const previewURL = URL.createObjectURL(event.target.files[0]);
        setMenuFilePreviewURL(previewURL);
        setMenuFile(event.target.files[0]);
        setError({
          ...error,
          menuFile: ''
        });
      } else {
        event.target.value = '';
        setError({
          ...error,
          menuFile: 'Please make sure that the menu file is of jpeg or png format.'
        });
      }
    }
  };

  const closeWarningAlert = (event) => {
    event.preventDefault();
    setError({
      ...error,
      menuFile: ''
    });
  }

  const handleUpgradeClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    history.push('/setup', { nextPage: '/change-theme' });
  }

  const {layout, backgroundTexture, backgroundColor, emphasizeHighMargin, includeLogo, includePhotos, includeSign, decimals } = values;
  return (
    <div>
      <AppBar position="fixed" color="primary">
        <Toolbar>
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={handleGoBackClick}
          >
            <ArrowBackIosIcon/>
          </IconButton>
          <Typography variant="h6" className={classes.headerTitle}>
            Update Theme
          </Typography>
          <div></div>
        </Toolbar>
      </AppBar>
      { (isFreePlan || isContactTracingPlan) && <div className="premiumBannerContainer">
        <Container maxWidth='sm'>
          <Grid container>
            <Grid item xs>
              <Typography variant="subtitle2" align="left" className={classes.premiumBannerText}>Unlock more with Pro!</Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={handleUpgradeClick}
              >
                Upgrade
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
      }
      <Container component="main" maxWidth="sm">
        <CssBaseline/>
        <div className={`${classes.mainContainer} ${(!(isFreePlan || isContactTracingPlan)) ? classes.marginTop : '' }`}>
          <form className={classes.form}>
            <FormControl variant="filled" className={classes.formControl} fullWidth margin="normal">
              <InputLabel id="layout">Layout</InputLabel>
              <Select
                id="layout"
                name="layout"
                value={layout || ""}
                className={classes.selectInput}
                disabled={isWineType || includePhotos}
                onChange={handleChange}
              >
                {
                  _.map(presetData.layouts, (layoutName, layoutKey) => (<MenuItem key={layoutKey} value={layoutKey}>{layoutName}</MenuItem>))
                }
              </Select>
            </FormControl>
            <FormControl variant="filled" className={classes.formControl} fullWidth margin="normal">
              <InputLabel id="texture">Background {(isFreePlan || isContactTracingPlan) && <IconButton edge="end" aria-label="lock" className={classes.noPaddingContainer}>
                <LockIcon color='primary' className={classes.lockIcon}/>
              </IconButton>}
              </InputLabel>
              <Select
                id="backgroundTexture"
                name="backgroundTexture"
                value={backgroundTexture}
                className={classes.selectInput}
                onChange={handleChange}
                disabled={isFreePlan || isContactTracingPlan}
              >
                {
                  presetData.textures.map((texture) => (<MenuItem key={texture.name} value={texture.name}>{texture.name}</MenuItem>))
                }
                <MenuItem key='custom-background' value='customBackground'>Custom</MenuItem>
              </Select>
            </FormControl>
            {
              showImageUploader &&
              <div className={classes.subHeaderContainer}>
                <Grid container maxWidth="sm">
                  <Grid item xs>
                    <Typography variant="subtitle1" align="left" className={classes.subTitleText}>Background Image</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      component="label"
                      className={classes.uploadButton}
                    >
                      Choose file
                      <input id="menuFile" type="file" accept="application/pdf, image/jpeg, image/png" onChange={onFileChange} style={{ display: 'none' }} />
                    </Button>
                  </Grid>
                </Grid>
                {
                  error.menuFile && <Alert severity="warning" className={classes.warningAlert} onClose={closeWarningAlert}>
                    {error.menuFile}
                  </Alert>
                }
                {
                  (menuFilePreviewURL || customBackgroundURL) ?
                    <CardMedia
                      component="img"
                      alt="Menu Image"
                      className={classes.menuPhoto}
                      image={menuFilePreviewURL || customBackgroundURL}
                      title="MenuImage"
                    />
                    : ''
                }
                {
                  menuFilePreviewURL && <Button
                    onClick={uploadBackground}
                    color="primary"
                    variant="contained"
                    className={classes.uploadImageButton}
                  >
                    Upload
                  </Button>
                }
              </div>
            }
            {
              ["Solid", "Border"].includes(backgroundTexture) &&
              <ColorPicker
                propName="backgroundColor"
                label={'Background Color'}
                color={backgroundColor}
                isFreePlan={isFreePlan || isContactTracingPlan}
                handleColorChange={handleBackgroundChange}
              />
            }
            <FormControl variant="filled" className={classes.formControl} fullWidth margin="normal">
              <InputLabel id="layout">Include Price Sign</InputLabel>
              <Select
                id="includeSign"
                name="includeSign"s
                value={includeSign}
                className={classes.selectInput}
                onChange={handleChange}
              >
                {
                  presetData.signs.map((sign) => (<MenuItem key={sign} value={sign}>{sign}</MenuItem>))
                }
              </Select>
            </FormControl>
            <FormControl variant="filled" className={classes.formControl} fullWidth margin="normal">
              <InputLabel id="layout">Decimals for Pricing</InputLabel>
              <Select
                id="decimals"
                name="decimals"
                value={decimals}
                className={classes.selectInput}
                onChange={handleChange}
              >
                {
                  presetData.decimals.map((decimal) => (<MenuItem key={decimal} value={decimal}>{decimal}</MenuItem>))
                }
              </Select>
            </FormControl>
            <Grid container maxWidth="sm">
              <Grid item xs>
                <Typography variant="subtitle1" align="left" className={classes.subTitleText}>Color Scheme</Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  component="label"
                  className={classes.uploadButton}
                  disabled={isFreePlan || isContactTracingPlan}
                  onClick={handleCustomizeColorClick}
                >
                  Choose colors
                  {(isFreePlan || isContactTracingPlan) && <LockIcon color='primary' className={classes.lockIcon}/>}
                </Button>
              </Grid>
            </Grid>
            <Grid container maxWidth="sm">
              <Grid item xs>
                <Typography variant="subtitle1" align="left" className={classes.subTitleText}>Font Style</Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  component="label"
                  disabled={isFreePlan || isContactTracingPlan}
                  className={classes.uploadButton}
                  onClick={handleCustomizeFontClick}
                >
                  Choose fonts
                  {(isFreePlan || isContactTracingPlan) && <LockIcon color='primary' className={classes.lockIcon}/>}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
            <List className={classes.root}>
              <div key='emphasizeHighMargin'>
                <ListItem role={undefined} dense>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      color="primary"
                      checked={emphasizeHighMargin}
                      id="emphasizeHighMargin"
                      name="emphasizeHighMargin"
                      onChange={handleCheckBoxClick}
                      tabIndex={-1}
                      inputProps={{"aria-labelledby": "emphasizeHighMargin"}}
                      disabled={isFreePlan || isContactTracingPlan}
                    />
                  </ListItemIcon>
                  <ListItemText id="emphasizeHighMargin" primary="Show Item Tags"/>
                  {
                    (isFreePlan || isContactTracingPlan) && <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="lock" style={{paddingRight: '6px'}}>
                        <LockIcon color='primary' className={classes.lockIcon}/>
                      </IconButton>
                    </ListItemSecondaryAction>
                  }
                </ListItem>
                <Divider variant="inset" component="li"/>
              </div>
              <div key='includeLogo'>
                <ListItem role={undefined} dense>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      color="primary"
                      checked={includeLogo}
                      id="includeLogo"
                      name="includeLogo"
                      onChange={handleCheckBoxClick}
                      tabIndex={-1}
                      inputProps={{"aria-labelledby": "includeLogo"}}
                    />
                  </ListItemIcon>
                  <ListItemText id="includeLogo" primary="Include Logo at Top"/>
                </ListItem>
                <Divider variant="inset" component="li"/>
              </div>
              <div key='includePhotos'>
                <ListItem role={undefined} dense>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      color="primary"
                      checked={includePhotos}
                      id="includePhotos"
                      name="includePhotos"
                      onChange={handleCheckBoxClick}
                      tabIndex={-1}
                      disabled={isWineType}
                      inputProps={{"aria-labelledby": "includePhotos"}}
                    />
                  </ListItemIcon>
                  <ListItemText id="includePhotos" primary="Include Item Photos"/>
                </ListItem>
                <Divider variant="inset" component="li"/>
              </div>
            </List>
          </Grid>
          </form>
        </div>
      </Container>
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar className="bottomTool">
          <Typography variant="h6" className={classes.title} button="true" onClick={handlePreview}>
            Apply to Preview
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default ThemeSettings;
