import React from "react";
import { Link } from "react-router-dom";
import {Container, CssBaseline, Typography, Button, CardMedia} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import AboardWordmark from "../../assets/images/WordMark-black.svg";
import colors from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
      margin: theme.spacing(8, 0, 3, 0),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
  },
  signUpButton: {
      color: colors.white,
      minWidth: '100px',
      width: '50%',
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(1),
  },
  linkContainer: {
    marginTop: theme.spacing(2.5)
  },
  loginLink: {
      paddingTop: '20px',
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "1.25px",
      textTransform: "uppercase",
      textDecoration: "none",
      color: "rgba(0, 0, 0, 0.38)",
      "&:hover": {
          textDecoration: "underline"
      }
  },
  signUpLink: {
      textTransform: "uppercase",
      textDecoration: "none",
      color: colors.white,
  },
  logo: {
    height: '100%',
    width: '280px',
    objectFit: 'cover',
    margin: theme.spacing(4, "auto"),
  },
  card: {
    borderColor: '#3A7F59',
    borderStyle: 'solid',
    borderRadius: '10px',
    borderWidth: '2px',
    backgroundColor: '#FFF',
    width: '90%',
    padding: theme.spacing(2.5),
    boxShadow: '5px 10px #3A7F59',
  },
  title: {
    marginBottom: theme.spacing(3),
    fontWeight: 'normal'
  }
}));

const Welcome = () => {
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline/>
      <div className={classes.mainContainer}>
        <CardMedia
          component="img"
          alt="logo"
          className={classes.logo}
          image={AboardWordmark}
          title="Logo"
        />
        <div className={classes.card}>
          <Typography variant="h6" component="h5" className={classes.title}>
            Easy to use <b>QR code menu creator</b> for any restaurant or bar.
          </Typography>
          <Typography variant="caption" component="h5" className={classes.title}>
            Create online menus and publish them. Share your dedicated link and QR code. Enjoy  contactless menus.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.signUpButton}
          >
            <Link to="/signup" className={classes.signUpLink}>Sign Up</Link>
          </Button>
          <Typography className={classes.linkContainer}>
            <Link to="/login" className={classes.loginLink}>Already have an account? Login</Link>
          </Typography>
        </div>
      </div>
    </Container>
  );
};

export default Welcome;
