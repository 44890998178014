import React from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import AuthenticatedRoute from "./components/authenticatedRoute";
import Dashboard from "./pages/dashboard";
import UpdateMenu from "./pages/updateMenu";
import Login from "./pages/login";
import Signup from "./pages/signup";
import Tutorials from "./pages/tutorials";
import Orders from "./pages/orders";
import Welcome from "./pages/welcome";
import UnAuthorized from "./pages/unAuthorized";
import MenuSettings from "./pages/menuSettings";
import Settings from "./pages/settings";
import BillingSettings from "./pages/billingSettings";
import SetUp from "./pages/setup";
import ForgotPassword from "./pages/forgotPassword";
import AddItem from "./pages/item/add";
import EditSection from "./pages/section/edit";
import ThemeSettings from "./pages/themeSettings";
import UpdateMenuPreview from "./pages/updateMenuPreview";
import MenuSettingsPreview from "./pages/menuSettingsPreview";
import MenuThemeSettings from "./pages/menuThemeSettings";
import AddMenu from "./pages/addMenu";
import EditMenu from "./pages/menuSettings/editMenu";
import UnVerifiedPage from "./pages/unVerifiedPage";
import PageNotFound from "./pages/pageNotFound";
import CustomizeColors from './pages/customizeColor';
import CustomizeMainMenuColor from './pages/customizeMainMenuColor';
import CustomizeFonts from './pages/customizeFonts';
import CustomizeMainMenuFonts from './pages/customizeMainMenuFonts';

const Routes = ({ childProps }) => (
  <Switch>
    <AuthenticatedRoute
      exact
      path="/"
      component={Dashboard}
      props={childProps}
      permissions={['verifiedEmail']}
    />
    <AuthenticatedRoute
      exact
      path="/dashboard"
      component={Dashboard}
      props={childProps}
      permissions={['verifiedEmail']}
    />
    <AuthenticatedRoute
      exact
      path="/menu-settings"
      component={MenuSettings}
      props={childProps}
      permissions={['verifiedEmail']}
    />
    <AuthenticatedRoute
      exact
      path="/settings"
      component={Settings}
      props={childProps}
      permissions={['verifiedEmail']}
    />
    <AuthenticatedRoute
      exact
      path="/tutorials"
      component={Tutorials}
      props={childProps}
      permissions={['verifiedEmail']}
    />
    <AuthenticatedRoute
      exact
      path="/orders"
      component={Orders}
      props={childProps}
      permissions={['verifiedEmail']}
    />
    <AuthenticatedRoute
      exact
      path="/billing-settings"
      component={BillingSettings}
      props={childProps}
      permissions={['verifiedEmail']}
    />
    <AuthenticatedRoute
      exact
      path="/setup"
      component={SetUp}
      props={childProps}
      permissions={['verifiedEmail']}
    />
    <AuthenticatedRoute
      exact
      path="/update-menu-preview"
      component={UpdateMenuPreview}
      props={childProps}
      permissions={['verifiedEmail']}
    />
    <AuthenticatedRoute
      path="/change-theme"
      exact
      component={ThemeSettings}
      props={childProps}
      permissions={['verifiedEmail']}
    />
    <AuthenticatedRoute
      path="/customize-fonts"
      exact
      component={CustomizeFonts}
      props={childProps}
      permissions={['verifiedEmail']}
    />
    <AuthenticatedRoute
      path="/menu-settings-preview"
      exact
      component={MenuSettingsPreview}
      props={childProps}
      permissions={['verifiedEmail']}
    />
    <AuthenticatedRoute
      path="/menu-theme-settings"
      exact
      component={MenuThemeSettings}
      props={childProps}
      permissions={['verifiedEmail']}
    />
    <AuthenticatedRoute
      path="/customize-menu-colors"
      exact
      component={CustomizeMainMenuColor}
      props={childProps}
      permissions={['verifiedEmail']}
    />
    <AuthenticatedRoute
      path="/customize-menu-fonts"
      exact
      component={CustomizeMainMenuFonts}
      props={childProps}
      permissions={['verifiedEmail']}
    />
    <AuthenticatedRoute
      path="/update-menu"
      exact
      component={UpdateMenu}
      props={childProps}
      permissions={['verifiedEmail']}
    />
    <AuthenticatedRoute
      path="/add-item"
      exact
      component={AddItem}
      props={childProps}
      permissions={['verifiedEmail']}
    />
    <AuthenticatedRoute
      path="/edit-section"
      exact
      component={EditSection}
      props={childProps}
      permissions={['verifiedEmail']}
    />
    <AuthenticatedRoute
      path="/add-section"
      exact
      component={EditSection}
      props={childProps}
      permissions={['verifiedEmail']}
    />
    <AuthenticatedRoute
      path="/add-menu"
      exact
      component={AddMenu}
      props={childProps}
      permissions={['verifiedEmail']}
    />
    <AuthenticatedRoute
      path="/edit-menu"
      exact
      component={EditMenu}
      props={childProps}
      permissions={['verifiedEmail']}
    />
    <AuthenticatedRoute
      path="/customize-colors"
      exact
      component={CustomizeColors}
      props={childProps}
      permissions={['verifiedEmail']}
    />
    <AuthenticatedRoute
      path="/unverified-user"
      exact
      component={UnVerifiedPage}
      props={childProps}
    />
    <Route exact path="/welcome" component={Welcome} />
    <Route exact path="/forbidden" component={UnAuthorized} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/signup" component={Signup} />
    <Route exact path="/forgot-password" component={ForgotPassword} />
    <Route component={PageNotFound} />
  </Switch>
);

Routes.propTypes = {
  childProps: PropTypes.instanceOf(Object).isRequired,
};

export default Routes;
