import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color'
import { makeStyles } from '@material-ui/core/styles';
import {IconButton, TextField, Typography} from "@material-ui/core";

import './ColorPicker.scss';
import LockIcon from "@material-ui/icons/Lock";

const useStyles = makeStyles((theme) => ({
  color: {
    width: '25px',
    height: '25px',
    borderRadius: '2px'
  },
  swatch: {
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
    margin: theme.spacing(3.5, 1.5, 1, 0)
  },
  popover: {
    position: 'absolute',
    zIndex: '2'
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  colorPicker: {
    marginBottom: theme.spacing(9)
  },
  lockIcon: {
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(0.25),
    marginBottom: theme.spacing(0.5)
  },
  noPaddingContainer: {
    padding: 0
  }
}));

const ColorPicker = (props) => {
  const [currentColor, setCurrentColor] = useState(props.color || '#fff');
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setCurrentColor(props.color);
  }, [props.color]);

  const handleClick = () => {
    if (props.isFreePlan) {
      setDisplayColorPicker(false);
    } else {
      setDisplayColorPicker(!displayColorPicker);
    }
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color) => {
    props.handleColorChange(props.propName, color);
  }

  return (
    <div>
      <TextField
        variant="filled"
        margin="normal"
        fullWidth
        id="color"
        label={
          <Typography variant="body2">
            {props.label}
            {props.isFreePlan ? <IconButton edge="end" aria-label="lock" className={classes.noPaddingContainer}>
              <LockIcon color='primary' className={classes.lockIcon}/>
            </IconButton>
              : ''}
          </Typography>
        }
        name={props.label}
        value={currentColor}
        disabled={true}
        InputProps={{
          startAdornment: (
            <div className={ classes.swatch } onClick={ handleClick }>
              <div className={ classes.color } style={{background: currentColor}}/>
            </div>
          ),
        }}
      />
      { displayColorPicker ? <div className={'colorPickerContainer ' + classes.popover }>
        <div className={classes.cover} onClick={ handleClose }/>
        <SketchPicker color={ currentColor } onChange={ handleChange } className={classes.colorPicker}/>
      </div> : null }

    </div>
  );
}

export default ColorPicker;
