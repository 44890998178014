/* eslint-disable react-hooks/exhaustive-deps  */
//react-hooks/exhaustive-deps is disable here || It gives warning in useEffect has a missing dependency
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import Routes from "./Routes";
import PopUpDialog from "./components/PopUpDialog";
import Loader from './components/Loader';
import { auth } from "./firebase/config";
import { getUser, setEmailVerifiedFlag,logout } from './store/actions/authActions';
import { getCustomer } from "./store/actions/stripeActions";
import colors from "./constants/colors";

import "./assets/styles/global/App.scss";

// Theme settings
const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary
    },
    secondary: {
      main: colors.secondary,
    },
  },
});

const stripePromise = loadStripe("pk_live_RYj8TfNZ31TRrAgHyhc7y4ff");
// const stripePromise = loadStripe("pk_test_i2j0j8FkV82yaW3wK01Y4ZtH");

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const emailVerified = useSelector((state) => state.auth.emailVerified);
  const authUser = useSelector((state) => state.auth.user);
  const showPopup = useSelector((state) => state.popup.showPopup);
  const showLoader = useSelector((state) => state.loader.showLoader);
  const stripe = useSelector((state) => state.stripe.data);
  const AuthError = useSelector((state) => state.auth.error);

  const [userId, setUserId] = useState(null);
  const childProps = {
    isAuthenticated,
    emailVerified,
    bypassEmailValidation: authUser && authUser.bypassEmailValidation,
    stripe
  };

  if (authUser && authUser.id && (authUser.id !== userId)) {
    setUserId(authUser.id);
  }

  const dispatch = useDispatch();
  useEffect(() => {
    if (userId) {
      dispatch(getUser(userId));
    }
    if (authUser && authUser.stripeCustomerId) {
      dispatch(getCustomer(authUser.stripeCustomerId));
    }
  }, [userId]);
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        dispatch(setEmailVerifiedFlag(user.emailVerified));
      }
    })
  }, []);

  useEffect(() => {
    if(AuthError && AuthError.message && AuthError.message == "Request failed with status code 401"){
      dispatch(logout());
    }
  },[AuthError])
  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
        <Elements stripe={stripePromise}>
          {showPopup && <PopUpDialog />}
          {showLoader && <Loader showLoader={showLoader} />}
          <Router>
            <Routes childProps={childProps} />
          </Router>
        </Elements>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
