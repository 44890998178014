import { createReducer } from '@reduxjs/toolkit';
import {
  SHOW_LOADER,
  HIDE_LOADER,
  LOGOUT_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
  showLoader: false
};
const loaderReducer = createReducer(
  initialState,
  {
    [SHOW_LOADER]: (state) => {
      state.showLoader = true;
    },
    [HIDE_LOADER]: (state) => {
      state.showLoader = false;
    },
    [LOGOUT_SUCCESS]: (state) => {
      state.showLoader = false;
    },
  },
);

export default loaderReducer;
