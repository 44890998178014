export const themes = {
  Cinema: {
    fonts: {
      heading: {
        fontFamily: "Playfair Display",
        fontStyle: "normal",
        fontSize: "40px",
        fontWeight: "bold",
      },
      section: {
        fontFamily: "Playfair Display",
        fontStyle: "normal",
        fontSize: "20px",
        fontWeight: "normal",
      },
      item: {
        fontFamily: "Raleway",
        fontStyle: "normal",
        fontSize: "20px",
        fontWeight: "bold",
      },
      description: {
        fontFamily: "Raleway",
        fontStyle: "normal",
        fontSize: "16px",
        fontWeight: "normal",
      },
    },
    colors: {
      primary: "#000",
      secondary: "#000",
      accent: "#000",
    },
    background: {
      color: "#F9F6EF",
      texture: null,
    },
  },
  mediterranean: {
    fonts: {
      heading: {
        fontFamily: "Amantic SC",
        fontStyle: "normal",
        fontSize: "65px",
        fontWeight: "bold",
      },
      section: {
        fontFamily: "Amantic SC",
        fontStyle: "normal",
        fontSize: "24px",
        fontWeight: "bold",
      },
      item: {
        fontFamily: "Quicksand",
        fontStyle: "normal",
        fontSize: "24px",
        fontWeight: "bold",
      },
      description: {
        fontFamily: "Quicksand",
        fontStyle: "normal",
        fontSize: "16px",
        fontWeight: "normal",
      },
    },
    colors: {
      primary: "#000",
      secondary: "#476F90",
      accent: "#FFF",
    },
    background: {
      color: "#FFF",
      texture: null,
    },
  },
};
