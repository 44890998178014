import React, { useState } from "react";
import {
  FormControlLabel,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
} from "@material-ui/core";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Checkbox from "@material-ui/core/Checkbox";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { menuItemToggle } from "../../store/actions/menuActions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0px 0px 0`,

  display: "block",
  width: "100%",

  // change background colour if dragging
  background: isDragging ? "rgba(33, 33, 33, 0.08)" : "white",
  border: "none",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "rgba(33, 33, 33, 0.08)" : "white",
  padding: 0,
  margin: "0px 0",
});

export default function MenuItem(props) {
  const dispatch = useDispatch();

  const handleCheckMarkToggle = (event) => {
    const { section } = props;
    const { name } = event.target;
    dispatch(menuItemToggle({ sectionId: section, itemId: name }));
  };
  
  let currenySign = "";
  let decimals = 0;

  if (props.priceStyles.decimals || props.priceStyles.includeSign) {
    if (props.priceStyles.includeSign) {
      if (props.priceStyles.includeSign != "None") {
        currenySign = props.priceStyles.includeSign;
      }
    }

    if (props.priceStyles.decimals) {
      decimals = props.priceStyles.decimals;
    }
  }
  return (
    <Droppable droppableId={props.type} type={`droppableSubItem__${props.type}`}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)} >

          {props.subItems.map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {(provided, snapshot) => (
                <div className="subMenuItemWrapper">
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                    className="subMenuItem" >
                    <ListItem>
                      <>
                        <ListItemAvatar {...provided.dragHandleProps}
                          className="dragHandler" >
                          <>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name={item.id}
                                  className="menu-checkbox"
                                  checked={item.toggle}
                                  onChange={handleCheckMarkToggle}
                                />
                              }
                              className="itemName"
                              label={`${item.name}`}
                            />
                            {
                              item.desc && <ListItemText
                                className="descLBL"
                                primary={item.desc}
                              />
                            }
                            <ListItemText
                              className={item.desc ? "priceLBL" : "noDescPriceLBL"}
                              primary={item.price?`${currenySign}${item.price.toFixed(decimals)}`:''}
                            />
                          </>
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            className="menuItemArrow"
                            onClick={(e) => {
                              props.handleButtonClick(e, "/add-item", {
                                ...item,
                                section: props.section,
                              });
                            }} >
                            <ArrowForwardIosIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </>
                    </ListItem>
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}
