import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import { hidePopupAction } from "../../store/actions/popupActions";
import colors from "../../constants/colors";
import QRCode from "qrcode.react";
import YouTube from 'react-youtube';

const useStyles = makeStyles((theme) => ({
  headerTitle: {
    flexGrow: 2,
    textAlign: 'center',
    fontWeight: "600",
    paddingLeft: theme.spacing(2),
  },
  qrCodeContainer: {
    margin: "auto",
    border: `2px solid ${colors.primary}`,
    boxSizing: "border-box",
    borderRadius: "15px",
    width: "fit-content",
    padding: theme.spacing(1.5),
  },
  dialogActionButtons: {
    justifyContent: "center",
    flexDirection: "column",
  },
  dismissButton: {
    marginLeft: "0 !important",
  },
  menuContentList: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "#000000",
  },
  messageText: {
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  messageBox: {
    display: 'flex'
  },
  textAlignCenter: {
    textAlign: "center"
  },
  mainSubHeader: {
    textAlign: "center",
    fontWeight: "600"
  },
  errorBox: {
    minWidth: "200px",
    padding: theme.spacing(1.5),
    textOverflow: "ellipsis",
    overflowX: "hidden",
    wordBreak: "break-word"
  }
}));

const PopUpDialog = () => {
  const classes = useStyles();
  const user = useSelector((state) => state.auth.user);
  const popupData = useSelector((state) => state.popup);

  const dispatch = useDispatch();

  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 0,
    },
  };
  
  const handleDismiss = (event) => {
    event.preventDefault();
    dispatch(hidePopupAction());
  };

  // const downloadQRCode = (event) => {
  //   event.preventDefault();
  //   const canvasNode = document.createElement("canvas");
  //   const canvasContext = canvasNode.getContext("2d");
  //   const imageTag = document.getElementById("qr-code-image");
  //   canvasNode.width = imageTag.width;
  //   canvasNode.height = imageTag.height;
  //   canvasContext.drawImage(imageTag, 0, 0);
  //   const dataUrl = canvasNode.toDataURL("image/png");
  //   const link = document.createElement("a");
  //   link.href = dataUrl.replace(/^data:image\/(png|jpg);base64,/, "");;
  //   link.setAttribute("download", "QRCode.png");
  //   link.click();
  // };

  const downloadQRCode = (event) => {
    // Generate download with use canvas and stream
    event.preventDefault();
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `QRCode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleConfirmClick = (event) => {
    event.preventDefault();
    popupData.handleConfirm(popupData.selectedId);
  }

  const getWelcomeMessage = () => (
    <div className={classes.menuContentList}>
      <YouTube videoId="fFxA8ne2SLo" opts={opts}/>
    </div>
  );
  const getWelcomeMessageForUnpaidUser = () => (
    <div className={classes.menuContentList}>
      <div className={classes.messageBox}>
        <Typography variant='subtitle2'>1.</Typography>
        <Typography variant='subtitle2' className={classes.messageText}>Add a menu to your menu list. Use our auto-import tool to save time.</Typography>
      </div>
      <div className={classes.messageBox}>
        <Typography variant='subtitle2'>2.</Typography>
        <Typography variant='subtitle2' className={classes.messageText}>Validate your menu sections and items. Rearrange your menu by dragging and dropping, add any photos, or change items as needed.</Typography>
      </div>
      <div className={classes.messageBox}>
        <Typography variant='subtitle2'>3.</Typography>
        <Typography variant='subtitle2' className={classes.messageText}>Choose your theme and preview your menu for free. If you’d like to publish and see your menu at your unique link, please subscribe to a paid plan.</Typography>
      </div>
      <Typography variant="subtitle2" className={classes.textAlignCenter}>Don’t like your link? With a paid plan, you can change it in the settings page!</Typography>
    </div>
  );
  const getQRCodeContainer = () => (
    <div className={classes.qrCodeContainer}>
      {/* <img
        id="qr-code-image"
        src={`https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=aboard.menu/${user.link}`}
        alt="QR-code"
        crossOrigin="anonymous"
      /> */}
 <QRCode
        id="qr-gen"
        value={`https://aboard.menu/${user.link}`}
        size={150}
        level={"H"}
        includeMargin={true}
      />
    </div>
  );
  const getMessageContainer = () => (
    <div className={classes.errorBox}>
      <Typography variant="subtitle1">{popupData.message}</Typography>
    </div>
  );
  return(
    <Dialog
      open={popupData.showPopup}
      onClose={handleDismiss}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {
          popupData.header && <Typography variant="h5" component="h5" className={classes.headerTitle}>
            {popupData.header}
          </Typography>
        }
        {
          popupData.subHeader && <Typography variant="subtitle2" component="h6" className={popupData.header ? classes.headerTitle : classes.mainSubHeader}>
            {popupData.subHeader}
          </Typography>
        }
      </DialogTitle>
      <DialogContent>
        {
          popupData.popupType === "welcomeMessage" ? getWelcomeMessage()
            : (popupData.popupType === "welcomeMessageForUnpaidUser" ? getWelcomeMessageForUnpaidUser()
            : (popupData.popupType === "QRCode" ? getQRCodeContainer() : getMessageContainer()))
        }
      </DialogContent>
      <DialogActions className={classes.dialogActionButtons}>
          {
            popupData.popupType === "QRCode" && <Button
              onClick={downloadQRCode}
              color="primary"
              variant="contained"
            >
              Download
            </Button>
          }
          {
           popupData.handleConfirm && <Button
             onClick={handleConfirmClick}
             color="primary"
             variant="contained"
           >
             Confirm
           </Button>
          }
          <Button
            onClick={handleDismiss}
            color="default"
            className={classes.dismissButton}
          >
            Dismiss
          </Button>
        </DialogActions>
    </Dialog>
  );
};

export default PopUpDialog;
