import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  Container,
  CssBaseline,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
  Button,
  Divider,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Tooltip
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import _ from 'lodash';

import MenuPreview from "../../components/MenuPreview";
import ConfirmationAnimationLoader from "../../components/ConfirmationAnimationLoader";
import {
  resetSelectedMenu,
  updateMenu,
  updateSelectedMenuTheme,
  menuOrdering,
  createMenuDraft,
  setIsLockedTheme
} from '../../store/actions/menuActions';
import { updateUser } from '../../store/actions/authActions'
import { showPopupAction } from "../../store/actions/popupActions";

import './UpdateMenuPreview.scss';
import LockIcon from "@material-ui/icons/Lock";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
  headerTitle: {
    flexGrow: 2,
    textAlign: "initial",
    paddingLeft: theme.spacing(2),
  },
  title: {
    flexGrow: 2,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  textLabel: {
    marginTop: theme.spacing(4),
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.38)",
  },
  mainContainer: {
    margin: theme.spacing(7, 0, 2, 0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
  },
  subTitleText: {
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.87)",
    padding: theme.spacing(1.5),
  },
  alertContainer: {
    width: "100%",
  },
  selectInputControl: {
    marginBottom: theme.spacing(2),
    textAlign: "left",
    '& #themeName': {
      padding: theme.spacing(3, 1.5, 2.5),
      display: 'flex'
    },
  },
  proOnlyTextContainer: {
    justifyContent: 'space-between'
  },
  lockIcon: {
    padding: theme.spacing(0.25),
    marginLeft: theme.spacing(0.5)
  },
  lockedThemeName:{
    padding: theme.spacing(0.5, 0)
  }
}));

const UpdateMenuPreview = (props) => {
  const classes = useStyles();
  const [displayLoader, setDisplayLoader] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState("loading");
  const selectedMenu = useSelector((state) => state.menu.selectedMenu);
  const menuCustomTheme = useSelector((state) => state.menu.menuCustomTheme);
  const isLockedTheme = useSelector((state) => state.menu.isLockedTheme);
  const isFreePlan = useSelector((state) => state.stripe.isFreePlan);
  const isContactTracingPlan = useSelector((state) => state.stripe.isContactTracingPlan);
  const themes = useSelector((state) => state.theme.themes);
  const [themeName, setThemeName] = useState('Cinema');
  const userDetails = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!_.isEmpty(selectedMenu.theme)) {
      setThemeName(selectedMenu.theme.theme.name);
    }
  }, [selectedMenu.theme]);

  const publishMenu = (event) => {
    event.preventDefault();
    if (isLockedTheme) {
      dispatch(showPopupAction({ showPopup: true, message: 'Please upgrade your plan to use Premium theme!' }));
    } else {
      setDisplayLoader(true);
      let finalMenu = _.cloneDeep(selectedMenu);
      if (_.isEmpty(finalMenu.theme)) {
        // If menu theme is emppty then assign default theme "Cinema" to the menu
        const defaultTheme = themes.find((theme) => theme.id === "Cinema");
        if (!_.isEmpty(defaultTheme)) {
          finalMenu.theme = {
            includeLogo: false,
            emphasizeHighMargin: false,
            includeSign: 'None',
            decimals: 2,
            theme: {
              name: defaultTheme.id,
              ...defaultTheme
            }
          };
          delete finalMenu.theme.theme.id;
        }
      }
      finalMenu.toggle = true;
      let menuId = finalMenu.id;
      let draftId;
      if (finalMenu.menuId) {
        draftId = finalMenu.id;
        menuId = finalMenu.menuId;
        delete finalMenu.id;
        delete finalMenu.menuId;
        delete finalMenu.updatedAt;
      }
      delete finalMenu.drafts;
      dispatch(updateMenu(menuId, finalMenu, draftId, (error) => {
        if (error) {
          setDisplayLoader(false);
          setLoadingStatus("loading");
          dispatch(showPopupAction({
            showPopup: true,
            popupType: "error",
            header: "Error!",
            message: "Error occurred while publishing the menu. Please try again!"
          }));
        } else {
          let updatedUserDetails = _.cloneDeep(userDetails);
          if(updatedUserDetails.menuOrder && !_.includes(updatedUserDetails.menuOrder, finalMenu.id)) {
            let newOrder = updatedUserDetails.menuOrder;
            newOrder.push(finalMenu.id);
            updatedUserDetails.menuOrder = newOrder;
            dispatch(updateUser(userDetails.id, updatedUserDetails, (error) => {
              //console.log(error)
              if (error) {
                return false
              }
              dispatch(menuOrdering(updatedUserDetails.menuOrder));
              setLoadingStatus("complete");
              setTimeout(() => {
                setLoadingStatus("success");
              }, 1000);
              setTimeout(() => {
                setDisplayLoader(false);
                setLoadingStatus("loading");
                dispatch(resetSelectedMenu());
                props.history.push("/");
              }, 1500);
              return true
            }))
          } else {
            setLoadingStatus("complete");
            setTimeout(() => {
              setLoadingStatus("success");
            }, 1000);
            setTimeout(() => {
              setDisplayLoader(false);
              setLoadingStatus("loading");
              dispatch(resetSelectedMenu());
              props.history.push("/");
            }, 1500);
          }
        }
      }));
    }
  };

  const changeTheme = (event) => {
    event.preventDefault();
    props.history.push("/change-theme");
  };

  const handleChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { value } = event.target;
    window.analytics.track('Change Theme', {
      menuId: selectedMenu.id,
      theme: value,
    });
    let finalTheme = {};
    if (value === "custom") {
      finalTheme = _.cloneDeep(menuCustomTheme);
    } else {
      if (!_.isEmpty(selectedMenu.theme)) {
        finalTheme = {
          emphasizeHighMargin: selectedMenu.theme.emphasizeHighMargin,
          includeLogo: selectedMenu.theme.includeLogo,
          includeSign: selectedMenu.theme.includeSign,
          decimals: selectedMenu.theme.decimals,
        }
      } else {
        finalTheme = {
          emphasizeHighMargin: false,
          includeLogo: false,
          includeSign: "None",
          decimals: 2,
        }
      }
      const selectedTheme = themes.find((theme) => theme.id === value);
      finalTheme.theme = {
        name: value,
        ...selectedTheme
      };
      let isWineType = false;
      selectedMenu.sections.forEach((section) => {
        if (section.type === 'wine') {
          isWineType = true;
        }
      });
      if (isWineType) {
        finalTheme.theme.layout = 'line';
      }
      delete finalTheme.theme.id;
      if (isFreePlan || isContactTracingPlan) {
        const themeIndex = themes.findIndex((theme) => theme.id === value);
        if (themeIndex > 5) {
          dispatch(setIsLockedTheme(true));
        } else {
          dispatch(setIsLockedTheme(false));
        }
      }
    }
    dispatch(updateSelectedMenuTheme(finalTheme));
  }

  const handleSaveAsDraftClick = (event) => {
    event.preventDefault();
    let draft = _.cloneDeep(selectedMenu);
    let menuId = draft.id;
    if (draft.menuId) {
      menuId = draft.menuId;
    }
    draft.updatedAt = new Date();
    draft.menuId = menuId;
    delete draft.id;
    delete draft.drafts;
    dispatch(createMenuDraft(draft, props.history));
  }

  return (
    <div>

      <AppBar position="fixed" color="primary">
        <Toolbar>
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={() => {
              props.history.push("/update-menu");
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" className={classes.headerTitle}>
            Update Menu
          </Typography>
          <Tooltip title="Save as draft">
            <Typography variant="subtitle1" id="draft-button" onClick={handleSaveAsDraftClick}>
              Save as Draft
            </Typography>
          </Tooltip>
          <div></div>
        </Toolbar>
      </AppBar>

      <Container component="main" maxWidth="md">
        <CssBaseline />
        <ConfirmationAnimationLoader
          displayLoader={displayLoader}
          loadingStatus={loadingStatus}
        />
        <div className={classes.mainContainer}>
          <FormControl variant="filled" className={classes.selectInputControl} fullWidth margin="normal">
            <Select
              id="themeName"
              name="themeName"
              value={themeName}
              onChange={handleChange}
              className={classes.selectInput}
              disableUnderline={true} >
              {
                themes.map((theme, index) => {
                  return ((isFreePlan || isContactTracingPlan) && index > 5) ?
                    <MenuItem key={theme.id} value={theme.id} className={classes.proOnlyTextContainer}>
                      <Typography className={classes.lockedThemeName}>
                        {theme.id}
                      </Typography>
                      <LockIcon color='primary' className={classes.lockIcon}/>
                    </MenuItem>
                    : <MenuItem key={theme.id} value={theme.id}>{theme.id}</MenuItem>
                })
              }
              {!_.isEmpty(menuCustomTheme)
                && (isLockedTheme ? <MenuItem value="custom" className={classes.proOnlyTextContainer}>
                  <Typography className={classes.lockedThemeName}>
                    Custom
                  </Typography>
                  <LockIcon color='primary' className={classes.lockIcon}/>
                </MenuItem> : <MenuItem value="custom">Custom</MenuItem>)
              }
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            className={classes.signUpButton}
            onClick={changeTheme}
          >
            Customize
          </Button>
          <div className={classes.alertContainer}>
            <Grid container maxwidth="sm">
              <Grid item xs>
                <Typography
                  variant="body2"
                  align="left"
                  className={classes.subTitleText}
                >
                  Preview
                </Typography>
              </Grid>
            </Grid>
            <Divider />
          </div>
          <div className="previewContainer">
            <MenuPreview selectedMenu={selectedMenu} userDetails={userDetails} />
          </div>
        </div>
      </Container>

      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar className="bottomTool">
          <Typography
            variant="h6"
            className={classes.title}
            button="true"
            onClick={publishMenu}
          >
            Publish
          </Typography>
        </Toolbar>
      </AppBar>

    </div>
  );
};

export default UpdateMenuPreview;
