// Auth actions
export const SIGNUP_SUCCESS = 'signup/success';
export const SIGNUP_FAILED = 'signup/failed';
export const SIGNUP_RESET = 'signup/reset';
export const LOGIN_SUCCESS = 'login/success';
export const LOGIN_FAILED = 'login/failed';
export const SET_EMAIL_VERIFIED_FLAG = 'user/emailVerified';
export const UPDATE_USER_SUCCESS = 'user/updateSuccess';
export const UPDATE_USER_FAILED = 'user/updateFailed';
export const LOGOUT_START = 'logout/start';
export const LOGOUT_SUCCESS = 'logout/success';
export const LOGOUT_FAILED = 'logout/failed';
export const GET_USER_DATA_START = 'user/getDataStart';
export const GET_USER_DATA_SUCCESS = 'user/getDataSuccess';
export const GET_USER_DATA_FAILED = 'user/getDataFailed';
export const UPDATE_MAIN_MENU_THEME = 'menu/updateMainMenuTheme';
export const UPDATE_MAIN_MENU_CUSTOM_THEME = 'menu/updateMainMenuCustomTheme';
export const UPDATE_MAIN_MENU_TEMP_THEME = 'menu/updateMainMenuTempTheme';

// Menu actions
export const MENU_CREATE_START = 'menu/createStart';
export const MENU_CREATE_SUCCESS = 'menu/createSuccess';
export const MENU_CREATE_FAILED = 'menu/createFailed';
export const MENU_UPDATE_START = 'menu/updateStart';
export const MENU_UPDATE_SUCCESS = 'menu/updateSuccess';
export const MENU_UPDATE_FAILED = 'menu/updateFailed';
export const MENU_GET_START = 'menu/getStart';
export const MENU_GET_SUCCESS = 'menu/getSuccess';
export const MENU_GET_FAILED = 'menu/getFailed';
export const MENUS_FOR_USER_GET_START = 'menu/getForUserStart';
export const MENUS_FOR_USER_GET_SUCCESS = 'menu/getForUserSuccess';
export const MENUS_FOR_USER_GET_FAILED = 'menu/getForUserFailed';
export const MENUS_FOR_LINK_GET_START = 'menu/getForLinkStart';
export const MENUS_FOR_LINK_GET_SUCCESS = 'menu/getForLinkSuccess';
export const MENUS_FOR_LINK_GET_FAILED = 'menu/getForLinkFailed';
export const MENU_CREATE_SECTION_START = 'menu/createSectionStart';
export const MENU_CREATE_SECTION_SUCCESS = 'menu/createSectionSuccess';
export const MENU_CREATE_SECTION_FAILED = 'menu/createSectionFailed';
export const MENU_UPDATE_SECTION_START = 'menu/updateSectionStart';
export const MENU_UPDATE_SECTION_SUCCESS = 'menu/updateSectionSuccess';
export const MENU_UPDATE_SECTION_FAILED = 'menu/updateSectionFailed';
export const MENU_CREATE_ITEM_START = 'menu/createItemStart';
export const MENU_CREATE_ITEM_SUCCESS = 'menu/createItemSuccess';
export const MENU_CREATE_ITEM_FAILED = 'menu/createItemFailed';
export const MENU_UPDATE_ITEM_START = 'menu/updateItemStart';
export const MENU_UPDATE_ITEM_SUCCESS = 'menu/updateItemSuccess';
export const MENU_UPDATE_ITEM_FAILED = 'menu/updateItemFailed';
export const MENU_DELETE_START = 'menu/deleteStart';
export const MENU_DELETE_SUCCESS = 'menu/deleteSuccess';
export const MENU_DELETE_FAILED = 'menu/deleteFailed';
export const MENU_ITEMS_REORDER_START = 'menu/menuReorderStart';
export const MENU_ITEMS_REORDER_SUCCESS = 'menu/menuReorderSuccess';
export const MENU_ITEMS_REORDER_FAILED = 'menu/menuReorderFailed';
export const MENU_ITEMS_TOGGLE_SUCCESS = 'menu/menuItemToggleSuccess';
export const MENU_ITEM_UPDATED = 'menu/menuItemUpdated';
export const MENU_ITEM_ADDED = 'menu/menuItemAdded';
export const MENU_LIST_UPDATED = 'menu/menuListUpdated';
export const SET_SELECTED_MENU = 'menu/setSelectedMenu';
export const RESET_SELECTED_MENU = 'menu/resetSelectedMenu';
export const SET_UPDATED_MENU_LIST = 'menu/setUpdatedMenuList';
export const UPDATE_SELECTED_MENU_THEME = 'menu/updateSelectedMenuTheme';
export const MENU_ITEM_DELETED = 'menu/menuItemDeleted';
export const MENU_SECTION_DELETED = 'menu/menuSectionDeleted';
export const UPDATE_MENU_CUSTOM_THEME = 'menu/updateMenuCustomTheme';
export const UPDATE_TEMP_SELECTED_MENU = 'theme/updateTempSelectedMenu';
export const SET_IS_LOCKED_THEME = 'theme/setIsLockedTheme';

// Draft actions
export const MENU_DRAFT_CREATE_START = 'menu/createDraftStart';
export const MENU_DRAFT_CREATE_SUCCESS = 'menu/createDraftSuccess';
export const MENU_DRAFT_CREATE_FAILED = 'menu/createDraftFailed';
export const MENU_DRAFT_DELETE_START = 'menu/draftDeleteStart';
export const MENU_DRAFT_DELETE_SUCCESS = 'menu/draftDeleteSuccess';
export const MENU_DRAFT_DELETE_FAILED = 'menu/draftDeleteFailed';

// Theme actions
export const THEMES_GET_ALL_START = 'theme/getAllStart';
export const THEMES_GET_ALL_SUCCESS = 'theme/getAllSuccess';
export const THEMES_GET_ALL_FAILED = 'theme/getAllFailed';
export const THEME_CREATE_SUCCESS = 'theme/createSuccess';
export const THEME_CREATE_FAILED = 'theme/createFailed';
export const THEME_UPDATE_SUCCESS = 'theme/updateSuccess';
export const THEME_UPDATE_FAILED = 'theme/updateFailed';
export const THEME_GET_SUCCESS = 'theme/getSuccess';
export const THEME_GET_FAILED = 'theme/getFailed';
export const THEME_DELETE_SUCCESS = 'theme/deleteSuccess';
export const THEME_DELETE_FAILED = 'theme/deleteFailed';

// Stripe actions
export const STRIPE_CREATE_CUSTOMER_START = 'stripe/createCustomerStart';
export const STRIPE_CREATE_CUSTOMER_SUCCESS = 'stripe/createCustomerSuccess';
export const STRIPE_CREATE_CUSTOMER_FAILED = 'stripe/createCustomerFailed';

export const STRIPE_GET_CUSTOMER_START = 'stripe/getCustomerStart';
export const STRIPE_GET_CUSTOMER_SUCCESS = 'stripe/getCustomerSuccess';
export const STRIPE_GET_CUSTOMER_FAILED = 'stripe/getCustomerFailed';

export const STRIPE_CREATE_SUBSCRIPTION_START = 'stripe/createSubscriptionStart';
export const STRIPE_CREATE_SUBSCRIPTION_SUCCESS = 'stripe/createSubscriptionSuccess';
export const STRIPE_CREATE_SUBSCRIPTION_FAILED = 'stripe/createSubscriptionFailed';
export const STRIPE_UPDATE_SUBSCRIPTION_START = 'stripe/updateSubscriptionStart';
export const STRIPE_UPDATE_SUBSCRIPTION_SUCCESS = 'stripe/updateSubscriptionSuccess';
export const STRIPE_UPDATE_SUBSCRIPTION_FAILED = 'stripe/updateSubscriptionFailed';
export const STRIPE_CANCEL_SUBSCRIPTION_START = 'stripe/cancelSubscriptionStart';
export const STRIPE_CANCEL_SUBSCRIPTION_SUCCESS = 'stripe/cancelSubscriptionSuccess';
export const STRIPE_CANCEL_SUBSCRIPTION_FAILED = 'stripe/cancelSubscriptionFailed';

// Popup actions
export const SHOW_POP_UP = 'SHOW_POP_UP';
export const HIDE_POP_UP = 'HIDE_POP_UP';

// Loader actions
export const SHOW_LOADER = 'loader/showLoader';
export const HIDE_LOADER = 'loader/hideLoader';

// Menu Ordering
export const MENU_ORDERING_START = 'menu/menuOrderingStart';
export const MENU_ORDERING = 'menu/menuOrderingSuccess';

// Ordering Dashboard
export const ORDERS_FOR_USER_GET_START = 'ORDERS_FOR_USER_GET_START';
export const ORDERS_FOR_USER_GET_SUCCESS = 'ORDERS_FOR_USER_GET_SUCCESS';
export const ORDERS_FOR_USER_GET_FAILED = 'ORDERS_FOR_USER_GET_FAILED';
