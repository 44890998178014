import React from "react";
import { Prompt } from "react-router-dom";

const NavigationBlockerPrompt = (props) => {
  if (props.navigationBlocked) {
    window.onbeforeunload = () => true
  } else {
    window.onbeforeunload = null
  }
  return (
    <Prompt
      when={props.navigationBlocked}
      message={props.message || "All your unpublished changes will be lost. Are you sure you want to leave?"}
    />
  )
};

export default NavigationBlockerPrompt;
