import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
  Button,
  Container,
  CssBaseline,
  TextField,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Typography
} from "@material-ui/core";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { showPopupAction } from '../../store/actions/popupActions';
import { createSubscription } from '../../store/actions/stripeActions';
import { updateSubscription } from '../../store/actions/stripeActions';
import countryData from "../../constants/countryDataProd.json";
import "./billingForm.scss";
import { hideLoaderAction, showLoaderAction } from "../../store/actions/loaderActions";
import colors from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    margin: theme.spacing(15, 0, 8, 0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%"
  },
  button: {
    marginTop: theme.spacing(1)
  },
  inputText: {
    margin: theme.spacing(0, 0, 2, 0),
    "& .MuiInputBase-root-122": {
      color: "rgba(0, 0, 0, 0.6)"
    }
  },
  subTitleText: {
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.87)",
    padding: theme.spacing(1.5)
  },
  freeTrialContainer: {
    textAlign: "center",
    marginBottom: theme.spacing(2)
  },
  freeTrialText: {
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.87)",
    padding: theme.spacing(1.5)
  },
  subscriptionButton: {
    marginTop: theme.spacing(3)
  },
  radioGroup: {
    justifyContent: "center",
    marginBottom: theme.spacing(2)
  },
  paymentInput: {
    width: "100%",
    border: "1px solid #b7b7b7",
    borderRadius: "4px",
    padding: "18.5px 14px",
    margin: "0 0 16px 0"
  },
  radioOption: {
    marginLeft: "0",
    marginRight: "0"
  },
  selectInput: {
    textAlign: 'left'
  },
  buttonContainer: {
    display: "grid",
    justifyContent: "center",
    flexDirection: "column",
  },
  labelContainer: {
    textAlign: "left",
    marginBottom: theme.spacing(2)
  },
  labelText: {
    fontWeight: "600"
  },
  couponLabel: {
    fontSize: "1rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "400",
    lineHeight: "1.75",
    letterSpacing: "0.00938em"
  },
  couponContainer: {
    textAlign: "right",
    color: colors.primary,
    marginBottom: theme.spacing(2)
  }
}));

const BillingForm = (props) => {
  const classes = useStyles();
  const { isUpdate } = props;
  const history = useHistory();
  const userDetails = useSelector((state) => state.auth.user);
  const stripeDetails = useSelector((state) => state.stripe.data);
  const dispatch = useDispatch();
  const selectedCountryData = countryData[userDetails.country || "United States"];
  const currentSubscriptionDetails = (stripeDetails && stripeDetails.subscriptions && stripeDetails.subscriptions.data) ?
    stripeDetails.subscriptions.data[0] : {};
  const currentSubscriptionPlan = (currentSubscriptionDetails && currentSubscriptionDetails.plan) ?
    _.findKey(selectedCountryData, (planDetails) => planDetails.stripe_product_id === currentSubscriptionDetails.plan.id) : "";
  const isActiveStripePlan = !!(currentSubscriptionDetails && currentSubscriptionDetails.plan && currentSubscriptionDetails.plan.active);
  const isTrial = !!(currentSubscriptionDetails && (currentSubscriptionDetails.status === 'trialing'));


  const initialState = {
    name: userDetails.name || "",
    currentPlan: props.planType === 'contactTracing' ? 'contact_tracing' : (currentSubscriptionPlan || Object.keys(selectedCountryData)[0]),
    coupon: ""
  };
  const [values, setValues] = useState({
    ...initialState,
    errors: {}
  });
  const [showCouponInput, setShowCouponInput] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const options = {
    style: {
      base: {
        fontSize: "16px",
        color: "rgba(0, 0, 0, 0.87)",
        letterSpacing: "0.025em",
        fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#f44336"
      }
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let { name, value } = event.target;
    const { errors } = values;
    delete errors[name];
    if (value === `Discontinue`) {
      elements.getElement(CardNumberElement).clear();
      elements.getElement(CardCvcElement).clear();
      elements.getElement(CardExpiryElement).clear();
    }
    if (name === "coupon") {
      value = value.toUpperCase();
    }
    setValues({ ...values, errors, [name]: value });
  };

  const validate = () => {
    const requiredFields = ["name"];
    const couponRegExp = /^\S*$/gm;
    let errors = {};
    requiredFields.forEach((fieldName) => {
      if (_.isEmpty(values[fieldName].trim())) {
        errors[fieldName] = `Please enter ${fieldName}`;
      }
    });
    if (!_.isEmpty(values.coupon) && !couponRegExp.test(values.coupon)) {
      errors.coupon = 'Please enter valid coupon code';
    }
    return errors;
  };

  // Function to update billing method
  const handleApplyChanges = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    let validationErrors = {};
    if (values.currentPlan !== 'Discontinue') {
      validationErrors = validate();
    }
    if (_.isEmpty(validationErrors)) {
      // console.log('selectedCountryData', selectedCountryData);
      // console.log("values", values);
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      dispatch(showLoaderAction());
      // Use your card Element with other Stripe.js APIs
      // TODO:- stripe product id is set in `billing_details`. please change that according to requirement
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: values.name,
          // product_id: selectedCountryData[values.currentPlan].stripe_product_id
        }
      });
      if (values.currentPlan === 'Discontinue') {
        dispatch(updateSubscription({
          subscriptionId: currentSubscriptionDetails.id,
          priceId: null,
          userEmail: userDetails.email,
          paymentMethodId: null,
          coupon: null
        },
          history,
          currentSubscriptionDetails.current_period_end
        ));
      }
      else if (error) {
        dispatch(hideLoaderAction());
        dispatch(showPopupAction({
          showPopup: true,
          popupType: "ErrorMessage",
          header: "Error with card details!",
          subHeader: "Make sure your card details are right."
        }));
      } else {
          if (currentSubscriptionDetails) {
            dispatch(updateSubscription({
              subscriptionId: currentSubscriptionDetails.id,
              priceId: selectedCountryData[values.currentPlan].stripe_product_id,
              userEmail: userDetails.email,
              paymentMethodId: paymentMethod.id,
              coupon: values.coupon
            }, history));
          } else {
            dispatch(createSubscription({
              paymentMethodId: paymentMethod.id,
              customerId: userDetails.stripeCustomerId,
              priceId: selectedCountryData[values.currentPlan].stripe_product_id,
              coupon: values.coupon
            }, history, props.nextPage));
          }
          dispatch(hideLoaderAction());
      }
    } else {
      setValues({ ...values, errors: validationErrors });
    }
  };

  // Function to subscribe the user for the first time
  const handleSubscribe = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const validationErrors = validate();
    if (_.isEmpty(validationErrors)) {
      // console.log('selectedCountryData', selectedCountryData);
      // console.log("values", values);
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      dispatch(showLoaderAction());
      // Use your card Element with other Stripe.js APIs
      // TODO:- stripe product id is set in `billing_details`. please change that according to requirement
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: values.name,
          // product_id: selectedCountryData[values.currentPlan].stripe_product_id
        }
      });

      if (error) {
        console.log('[PaymentMethod error]', error);
        dispatch(hideLoaderAction());
        dispatch(showPopupAction({
          showPopup: true,
          popupType: "ErrorMessage",
          header: "Error with card details!",
          subHeader: "Make sure your card details are right."
        }));
      } else {
        //       console.log('[PaymentMethod]', paymentMethod);
        dispatch(createSubscription({
          paymentMethodId: paymentMethod.id,
          customerId: userDetails.stripeCustomerId,
          priceId: selectedCountryData[values.currentPlan].stripe_product_id,
          coupon: values.coupon
        }, history, props.nextPage));
      }
    } else {
      setValues({ ...values, errors: validationErrors });
    }
  };

  const skipSubscriptionForNow = (event) => {
    event.preventDefault();
    if (props.nextPage) {
      history.push(props.nextPage);
    } else {
      dispatch(showPopupAction({ showPopup: true, popupType: "welcomeMessageForUnpaidUser", header: "Welcome to Aboard!", subHeader: "Get started in 3 easy steps." }));
      history.push("/");
    }
  };

  const handleGoBackClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    history.push(props.nextPage);
  }

  const { name, coupon, currentPlan, errors } = values;
  return (
    <div>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.mainContainer}>
          <form className={classes.form}>
            <div className={classes.labelContainer}>
              <Typography variant="overline" className={classes.labelText}>
                  Please select a plan:
                </Typography>
            </div>
            {
              props.planType === 'contactTracing'
                ? <FormControl fullWidth variant="outlined" className={classes.inputText}>
                  <Select
                    fullWidth
                    id="currentPlan"
                    value={currentPlan || ""}
                    name="currentPlan"
                    className={classes.selectInput}
                    onChange={handleChange}>
                    <MenuItem key='contact_tracing' value='contact_tracing'>{selectedCountryData.contact_tracing.rate}</MenuItem>
                  </Select>
                </FormControl>
                : (!isTrial && isUpdate && isActiveStripePlan)
                ? <FormControl fullWidth variant="outlined" className={classes.inputText}>
                  <Select
                    fullWidth
                    id="currentPlan"
                    value={currentPlan || ""}
                    name="currentPlan"
                    className={classes.selectInput}
                    onChange={handleChange}>
                    {
                      _.map(selectedCountryData, (planDetails, planKey) => (
                        planKey !== 'contact_tracing' ? <MenuItem key={planKey} value={planKey}>{planDetails.rate}</MenuItem>
                          : <MenuItem key={planKey} value={planKey}>{planDetails.rate}</MenuItem>
                      ))
                    }
                    {
                      (isActiveStripePlan && !(currentSubscriptionDetails && currentSubscriptionDetails.cancel_at))&&
                      <MenuItem value={"Discontinue"}>Discontinue</MenuItem>
                    }
                  </Select>
                </FormControl>
                : <RadioGroup row aria-label="position" name="currentPlan" defaultValue={currentPlan}
                  className={classes.radioGroup} onChange={handleChange}>
                  <Grid container spacing={1}>
                    {
                      _.map(selectedCountryData, (planDetails, planKey) => (
                        planKey !== 'contact_tracing' && <Grid item xs>
                          <FormControlLabel
                            value={planKey}
                            control={<Radio color="primary" />}
                            label={<img alt={planKey} src={require(`../../assets/images/pricing/${planDetails.image}`)} />}
                            className={classes.radioOption}
                            labelPlacement="top"
                          />
                        </Grid>
                      ))
                    }
                  </Grid>
                </RadioGroup>
            }
            <div className={classes.labelContainer}>
            <Typography variant="overline" className={classes.labelText}>
              Credit Card Details:
            </Typography>
            </div>
            {
              (isTrial || !isActiveStripePlan) && (
                !showCouponInput ? <div className={classes.couponContainer}><label className={classes.couponLabel} onClick={() => {setShowCouponInput(true)}}>Coupon?</label></div>
                  : <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="coupon"
                    name="coupon"
                    placeholder="Coupon"
                    value={coupon}
                    onChange={handleChange}
                    error={!_.isEmpty(errors.coupon)}
                    helperText={errors.coupon}
                    className={classes.inputText}
                  />
              )
            }
            <TextField
              variant="outlined"
              required
              fullWidth
              id="name"
              name="name"
              placeholder="Name"
              value={name}
              onChange={handleChange}
              error={!_.isEmpty(errors.name)}
              helperText={errors.name}
              className={classes.inputText}
            />
            <FormControl className={classes.paymentInput} variant="outlined" margin="normal" required>
              <CardNumberElement options={options} />
            </FormControl>
            <FormControl className={classes.paymentInput} variant="outlined" margin="normal" required>
              <CardCvcElement options={options} />
            </FormControl>
            <FormControl className={classes.paymentInput} variant="outlined" margin="normal" required>
              <CardExpiryElement options={options} />
            </FormControl>
            <div className={classes.buttonContainer}>
              {
                !isTrial && isUpdate && isActiveStripePlan ?
                  <Button
                    color="primary"
                    className={classes.subscriptionButton}
                    onClick={handleApplyChanges}
                  >
                    Apply changes
                  </Button>
                  : <Button
                    variant="contained"
                    color="primary"
                    className={classes.subscriptionButton}
                    onClick={handleApplyChanges}
                  >
                    Subscribe
                  </Button>
              }
              {
                !isUpdate && <Button
                  color="primary"
                  className={classes.subscriptionButton}
                  onClick={handleGoBackClick}
                >
                  Go back
                </Button>
              }
            </div>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default BillingForm;
