import React from "react";
import {Grid} from "@material-ui/core";
import _ from "lodash";
import Row from "../Row";
import Item from "./item";
import { getValidObject } from "../../../utils/dataOperations";
import WineBottle from "../../../assets/images/wineBottle.svg";
import Glass from "../../../assets/images/glass.svg";

const Sections = (props) => {
  let sectionHeaderStyles = {};
  let sectionDescriptionStyles = {};
  let itemStyles = {};
  let itemDescriptionStyles = {};
  let itemHeading = {};
  let cardStyle = {};
  let colors = {};
  let emphasizeHighMargin = false;
  let hmItemBackground = {};
  let hmItemBorder = {};
  let priceStyles = {};
  let floatItemWrapperStyle = {};
  let sectionHeaderBackground = {};
  let themeName = '';

  if (props.themeData && props.themeData.theme) {
    const theme = props.themeData.theme;
    themeName = theme.name;
    sectionHeaderStyles = getValidObject(theme.fonts.section);
    itemStyles = getValidObject(theme.fonts.item);
    itemDescriptionStyles = getValidObject(theme.fonts.description);
    sectionDescriptionStyles = !_.isEmpty(theme.fonts.sectionDescription)
      ? getValidObject(theme.fonts.sectionDescription) : getValidObject(theme.fonts.description);
    itemHeading = getValidObject(theme.fonts.heading);
    cardStyle = {
      border: theme.itemBorder,
      background: theme.itemBackground,
      line: theme.itemLine,
    };
    colors = theme.colors;
    emphasizeHighMargin = props.themeData.emphasizeHighMargin;
    hmItemBackground = getValidObject(theme.hmItemBackground);
    hmItemBorder = getValidObject(theme.hmItemBorder);
    priceStyles = {
      includeSign: props.themeData.includeSign
        ? props.themeData.includeSign
        : null,
      decimals: props.themeData.decimals ? props.themeData.decimals : null,
    };

    if (props.type === "float") {
      if (theme.sectionBackground) {
        sectionHeaderBackground.backgroundColor = theme.sectionBackground.color;
      } else {
        sectionHeaderBackground.backgroundColor = theme.colors ? theme.colors.primary : '#fff';
      }
      if (theme.itemBackground) {
        floatItemWrapperStyle.backgroundColor = theme.itemBackground.color;
      } else {
        floatItemWrapperStyle.backgroundColor = theme.colors ? theme.colors.primary : '#fff';
      }

      if (theme.itemBorder) {
        floatItemWrapperStyle.border = `${theme.itemBorder.borderWidth} solid ${theme.itemBorder.borderColor}`;
      }
    }
    if (sectionHeaderStyles.textAlign) {
      sectionDescriptionStyles.textAlign = sectionHeaderStyles.textAlign;
    }
  }

  return (
    <div className={`sectionWapper ${props.type}`}>
      {props.sections && props.sections.length > 0 ? (
        props.sections.map((section) => (
          <Row rowKey={section.id} key={section.id}>
            <div className="row-item">
              {section.toggle ?
                (section.type === 'wine'? (
                  <Grid container spacing={1} className="wineLayoutSectionTitle">
                    <Grid item xs={8} sm={10} md={10}>
                      <h3 className="sectionTitle" style={{ ...sectionHeaderStyles }}>
                        {section.name ? section.name : ``}
                      </h3>
                      {
                        section.desc &&
                        <p className="sectionDescription" style={{...sectionDescriptionStyles }}>
                          {" "}
                          {section.desc}
                        </p>
                      }
                    </Grid>
                    <Grid item xs={2} sm={1} md={1}>
                      <img
                        alt="glass-logo"
                        className="bottleLogo"
                        src={Glass}
                        title="Glass-Logo"
                      />
                    </Grid>
                    <Grid item xs={2} sm={1} md={1}>
                      <img
                        alt="wine-logo"
                        className="bottleLogo"
                        src={WineBottle}
                        title="Wine-Bottle-Logo"
                      />
                    </Grid>
                  </Grid>
              ) : (
                <div className="sectionTitle" style={props.type === 'float' ? sectionHeaderBackground : {}}>
                  <h3 style={{ ...sectionHeaderStyles }}>
                    {section.name ? section.name : ``}
                  </h3>
                  {
                    section.desc &&
                    <p className="sectionDescription" style={{...sectionDescriptionStyles }}>
                      {" "}
                      {section.desc}
                    </p>
                  }
                </div>
                ))
                : (
                ``
              )}
              {props.type === "float" ? (
                <div className="floatItemWapper" style={{ ...floatItemWrapperStyle }}>
                  <Item
                    country={props.country}
                    highLighted={props.highLighted}
                    items={section.items}
                    type={props.type}
                    itemStyles={itemStyles}
                    itemDescriptionStyles={itemDescriptionStyles}
                    itemHeading={itemHeading}
                    cardStyle={cardStyle}
                    menuID={props.menuID}
                    colors={colors}
                    emphasizeHighMargin={emphasizeHighMargin}
                    hmItemBackground={hmItemBackground}
                    hmItemBorder={hmItemBorder}
                    priceStyles={priceStyles}
                    themeName={themeName}
                  />
                </div>
              ) : (
                <Item
                  country={props.country}
                  highLighted={props.highLighted}
                  items={section.items}
                  sectionType={section.type}
                  type={props.type}
                  itemStyles={itemStyles}
                  itemDescriptionStyles={itemDescriptionStyles}
                  itemHeading={itemHeading}
                  cardStyle={cardStyle}
                  menuID={props.menuID}
                  colors={colors}
                  emphasizeHighMargin={emphasizeHighMargin}
                  hmItemBackground={hmItemBackground}
                  hmItemBorder={hmItemBorder}
                  priceStyles={priceStyles}
                  themeName={themeName}
                />
              )}
            </div>
          </Row>
        ))
      ) : (
        <div>
          <span>No Data Found </span>
        </div>
      )}
    </div>
  );
};

export default Sections;
