/* eslint-disable no-console */
import { createAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import moment from 'moment';
import {
  STRIPE_CREATE_CUSTOMER_START,
  STRIPE_CREATE_CUSTOMER_SUCCESS,
  STRIPE_CREATE_CUSTOMER_FAILED,
  STRIPE_GET_CUSTOMER_FAILED,
  STRIPE_GET_CUSTOMER_SUCCESS,
  STRIPE_GET_CUSTOMER_START,
  STRIPE_CREATE_SUBSCRIPTION_START,
  STRIPE_CREATE_SUBSCRIPTION_SUCCESS,
  STRIPE_CREATE_SUBSCRIPTION_FAILED,
  STRIPE_UPDATE_SUBSCRIPTION_START,
  STRIPE_UPDATE_SUBSCRIPTION_SUCCESS,
  STRIPE_UPDATE_SUBSCRIPTION_FAILED,
  STRIPE_CANCEL_SUBSCRIPTION_START,
  STRIPE_CANCEL_SUBSCRIPTION_SUCCESS,
  STRIPE_CANCEL_SUBSCRIPTION_FAILED,
} from './actionTypes';
import * as API from '../../functions/api';
import { showPopupAction } from './popupActions';
import { getUser } from './authActions';
import { hideLoaderAction } from "./loaderActions";

export const createCustomerStart = createAction(STRIPE_CREATE_CUSTOMER_START);
export const createCustomerSuccess = createAction(STRIPE_CREATE_CUSTOMER_SUCCESS);
export const createCustomerFailed = createAction(STRIPE_CREATE_CUSTOMER_FAILED);
export const createCustomer = (data) => (dispatch) => {
  dispatch(createCustomerStart());
  return API.createCustomer(data)
    .then((customer) => {
      if (customer) {
        dispatch(createCustomerSuccess(customer));
        dispatch(getUser(data.userId));
      }
    })
    .catch((e) => {
      console.error('createcustomer error: ', e);
      dispatch(createCustomerFailed(e));
    });
};

export const getCustomerStart = createAction(STRIPE_GET_CUSTOMER_START);
export const getCustomerSuccess = createAction(STRIPE_GET_CUSTOMER_SUCCESS);
export const getCustomerFailed = createAction(STRIPE_GET_CUSTOMER_FAILED);
export const getCustomer = (id) => (dispatch) => {
  dispatch(getCustomerStart());
  return API.getCustomer(id)
    .then((customer) => {
      if (customer) {
        dispatch(getCustomerSuccess(customer));
      }
    })
    .catch((e) => {
      console.error('getCustomer error: ', e);
      dispatch(getCustomerFailed(e));
    });
};

export const createSubscriptionStart = createAction(STRIPE_CREATE_SUBSCRIPTION_START);
export const createSubscriptionSuccess = createAction(STRIPE_CREATE_SUBSCRIPTION_SUCCESS);
export const createSubscriptionFailed = createAction(STRIPE_CREATE_SUBSCRIPTION_FAILED);
export const createSubscription = (data, history, nextPage) => (dispatch) => {
  dispatch(createSubscriptionStart());
  API.createSubscription(data)
    .then((subscription) => {
      dispatch(hideLoaderAction());
      if (subscription) {
        window.analytics.track('New Subscription', {
          subscription,
        });
        dispatch(createSubscriptionSuccess(subscription));
        dispatch(getCustomer(subscription.data.customer));
        if (nextPage) {
          history.push(nextPage);
        } else {
          history.push("/");
        }
      }
    })
    .catch((e) => {
      console.error('createSubscription error: ', e);
      dispatch(hideLoaderAction());
      dispatch(showPopupAction({ showPopup: true, popupType: "ErrorMessage", header: "Error with your subscription!", subHeader: "Make sure your payment details are right and your coupon is valid." }));
      dispatch(createSubscriptionFailed(e));
    });
};

export const updateSubscriptionStart = createAction(STRIPE_UPDATE_SUBSCRIPTION_START);
export const updateSubscriptionSuccess = createAction(STRIPE_UPDATE_SUBSCRIPTION_SUCCESS);
export const updateSubscriptionFailed = createAction(STRIPE_UPDATE_SUBSCRIPTION_FAILED);
export const updateSubscription = (data, history, subscriptionEndDate) => (dispatch) => {
  dispatch(updateSubscriptionStart());
  API.updateSubscription(data)
    .then((subscription) => {
      dispatch(hideLoaderAction());
      if (subscription) {
        window.analytics.track('Plan Updated', {
          newPlan: subscription,
        });
        dispatch(updateSubscriptionSuccess(subscription));
        dispatch(getCustomer(subscription.data.customer));
        if (_.isNull(data.priceId)) {
          window.analytics.track('Plan Cancelled', {
            plan: data.priceId,
          });
          dispatch(showPopupAction(
            {
              showPopup: true,
              popupType: "Success",
              message: `Your plan has been cancelled. You have access until ${moment.unix(subscriptionEndDate).format('Do MMMM, YYYY')}`
            })
          );
        } else {
          dispatch(showPopupAction(
            {
              showPopup: true,
              popupType: "Success",
              message: `Your plan has been updated.`
            }
          ));
        };
        if (history) {
          history.push("/");
        }
      }
    })
    .catch((e) => {
      console.error('updateSubscription error: ', e);
      dispatch(hideLoaderAction());
      dispatch(updateSubscriptionFailed(e));
      dispatch(showPopupAction({
        showPopup: true,
        popupType: "ErrorMessage",
        header: "Error with card details!",
        subHeader: "Make sure your coupon code or card details are correct."
      }));
    });
};

export const cancelSubscriptionStart = createAction(STRIPE_CANCEL_SUBSCRIPTION_START);
export const cancelSubscriptionSuccess = createAction(STRIPE_CANCEL_SUBSCRIPTION_SUCCESS);
export const cancelSubscriptionFailed = createAction(STRIPE_CANCEL_SUBSCRIPTION_FAILED);
export const cancelSubscription = (data) => (dispatch) => {
  dispatch(cancelSubscriptionStart());
  API.cancelSubscription(data)
    .then((subscription) => {
      if (subscription) {
        dispatch(cancelSubscriptionSuccess(subscription));
      }
    })
    .catch((e) => {
      console.error('cancelSubscription error: ', e);
      dispatch(cancelSubscriptionFailed(e));
    });
};
