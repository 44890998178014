/* eslint-disable react-hooks/exhaustive-deps  */
//react-hooks/exhaustive-deps is disable here || It gives warning in useEffect has a missing dependency
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  AppBar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  Container,
  Checkbox,
  ListItemIcon,
  Divider,
  Grid,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SettingsIcon from "@material-ui/icons/Settings";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

import {
  setUpdatedMenuList,
  menuOrdering,
} from "../../store/actions/menuActions";
import {updateUser} from "../../store/actions/authActions"
import { updateMainMenuCustomTheme } from "../../store/actions/authActions";
import NavigationBlockerPrompt from "../../components/NavigationBlockerPrompt";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
  mainContainer: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(8)
  },
  headerTitle: {
    flexGrow: 2,
    textAlign: "initial",
    paddingLeft: theme.spacing(2),
  },
  title: {
    flexGrow: 2,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  textLabel: {
    marginTop: theme.spacing(4),
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.38)",
  },
  deleteIconButton: {
    color: "rgba(0, 0, 0, 0.38)",
  },
}));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const MenuSettings = ({ history }) => {
  const grid = 8;
  const menusData = useSelector((state) => state.menu.menus);
  const menuOrderingState = useSelector((state) => state.menu.menuOrder);
  const userDetails = useSelector((state) => state.auth.user);
  const updatedMenuList = useSelector((state) => state.menu.updatedMenuList);
  const classes = useStyles();
  const menuListData =
    updatedMenuList && updatedMenuList.length > 0 ? updatedMenuList : menusData;
  const [menuList, setMenuList] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);

  const dispatch = useDispatch();

  const orderingNewMenu = () => {
    let tamp = [];
    let isNew = [];
    let orderIds = [];
    if (userDetails.menuOrder && userDetails.menuOrder.length) {
      let currentMenuOrdering = (menuOrderingState && menuOrderingState.length > 0)? _.cloneDeep(menuOrderingState) : _.cloneDeep(userDetails.menuOrder);
      currentMenuOrdering.map((orderId) => {
        menuListData.map((menue) => {
          if(_.includes(currentMenuOrdering,menue.id)){
          if (orderId === menue.id) {
            tamp.push(menue);
            orderIds.push(menue.id)
          }
        } else {
          if(!_.includes(isNew,menue)){
            isNew.push(menue)
           }
        }
        });
      });
      if(Object.keys(isNew).length > 0) {
        isNew.map(menu =>{ tamp.push(menu)
        orderIds.push(menu.id) })
        let updatedUserDetails = _.cloneDeep(userDetails);
        updatedUserDetails.menuOrder = orderIds
        dispatch(updateUser(userDetails.id,updatedUserDetails,(error) => { 
          if (error) {
          return false
          }
          dispatch(menuOrdering(orderIds));
          return true
        }))
      }
      setMenuList(tamp);
    }
  };

  const handleCheckBoxClick = (event) => {
    const { name } = event.target;
    let menuListTemp = _.cloneDeep(menuList)
    menuListTemp.forEach((menu) => {
      if (menu.id === name) {
        menu.toggle = !_.isUndefined(menu.toggle) ? !menu.toggle : true;
      }
    });
    setMenuList(menuListTemp);
  };

  const handlePreview = (event) => {
    event.preventDefault();
    if (menuList.length > 0) {
      setIsUpdated(false);
      setTimeout(() => {
        dispatch(setUpdatedMenuList(menuList));
        if (userDetails.menuTheme && userDetails.menuTheme.theme === "custom") {
          dispatch(updateMainMenuCustomTheme(userDetails.menuTheme));
        }
        history.push("/menu-settings-preview");
      }, 10);
    }
  };

  const handleButtonClick = (event, path, data) => {
    event.preventDefault();
    setIsUpdated(false);
    setTimeout(() => {
      if (_.isEmpty(data)) {
        history.push(path);
      } else {
        history.push(path, data);
      }
    }, 10);
  };

  useEffect(() => {
    if (updatedMenuList && updatedMenuList.length > 0) {
      if (
        userDetails &&
        userDetails.menuOrder &&
        userDetails.menuOrder.length > 0
      ) {
        orderingNewMenu();
      } else {
        setMenuList(_.cloneDeep(updatedMenuList));
      }
    } else {
      if (
        userDetails &&
        userDetails.menuOrder &&
        userDetails.menuOrder.length > 0
      ) {
        orderingNewMenu();
      } else {
        setMenuList(_.cloneDeep(menusData));
      }
    }
  }, [updatedMenuList, menusData]);

  useEffect(() => {
    let updated;
    if (updatedMenuList.length !== 0) {
      if (!_.isEqual(menusData, menuList)) {
        updated = true;
      } else {
        updated = !_.isEqual(menusData, updatedMenuList);
      }
    } else {
      updated = !_.isEqual(menusData, menuList);
    }
    setIsUpdated(updated);
  }, [menusData, updatedMenuList, menuList]);

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: 0,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging 1px solid
    background: isDragging ? "rgba(33, 33, 33, 0.06)" : "white",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "rgba(33, 33, 33, 0.08)" : "white",
    padding: 0,
    width: "100%",
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.type === "droppableItem") {
      // For Display
      const items = reorder(
        menuList,
        result.source.index,
        result.destination.index
      );
      setMenuList(items);
      let menuOrder = [];
      items.map((item) => menuOrder.push(item.id));
      dispatch(menuOrdering(menuOrder));
    }
  };
  return (
    <div>
      <NavigationBlockerPrompt navigationBlocked={isUpdated} />
      <AppBar position="fixed" color="primary">
        <Toolbar>
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={() => history.push("/")}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" className={classes.headerTitle}>
            Menu Settings
          </Typography>
          <div></div>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="md" className={classes.mainContainer}>
        <Grid item xs={12} md={12}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" type="droppableItem">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  <List className={classes.root}>
                    {menuList && menuList.length > 0 ? (
                      menuList.map((menu, index) => {
                        const labelId = `checkbox-list-label-${menu.id}`;
                        return (
                          <div key={menu.id}>
                            <Draggable
                              key={`${menu.id}`}
                              draggableId={menu.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <ListItem
                                    role={undefined}
                                    dense
                                    className="dragPoint"
                                    {...provided.dragHandleProps}
                                  >
                                    <ListItemIcon>
                                      <Checkbox
                                        edge="start"
                                        color="primary"
                                        disabled={
                                          menu.sections
                                            ? menu.sections.length === 0
                                            : true
                                        }
                                        checked={
                                          menu.toggle ? menu.toggle : false
                                        }
                                        id={menu.id}
                                        name={menu.id}
                                        onChange={handleCheckBoxClick}
                                        tabIndex={-1}
                                        inputProps={{
                                          "aria-labelledby": labelId,
                                        }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      id={labelId}
                                      primary={menu.name}
                                    />
                                    <IconButton
                                      aria-controls="menu-settings"
                                      aria-haspopup="true"
                                      color="inherit"
                                      className="p-0 clr-gray"
                                      onClick={(e) =>
                                        handleButtonClick(e, "/edit-menu", {
                                          name: menu.name,
                                          id: menu.id
                                        })
                                      }
                                    >
                                      <SettingsIcon />
                                    </IconButton>
                                    {provided.placeholder}
                                  </ListItem>
                                </div>
                              )}
                            </Draggable>
                            <Divider variant="inset" component="li" />
                          </div>
                        );
                      })
                    ) : (
                      <Typography
                        variant="subtitle1"
                        className={classes.textLabel}
                      >
                        You have no menus, please add one before updating
                        settings
                      </Typography>
                    )}
                  </List>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
      </Container>
      {(menuList)? 
      <AppBar
        position="fixed"
        color={menuList.length > 0 ? "primary" : "default"}
        className={classes.appBar}
      >
        <Toolbar className="bottomTool">
          <Typography
            variant="h6"
            className={classes.title}
            button="true"
            onClick={handlePreview}
          >
            Preview
          </Typography>
        </Toolbar>
      </AppBar>
      : ``} 
    </div>
  );
};

export default MenuSettings;
