import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './reducers/authReducer';
import menuReducer from './reducers/menuReducer';
import themeReducer from './reducers/themeReducer';
import stripeReducer from './reducers/stripeReducer';
import popupReducer from './reducers/popupReducer';
import loaderReducer from './reducers/loaderReducer';
import ordersReducer from './reducers/ordersReducer';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  menu: menuReducer,
  theme: themeReducer,
  stripe: stripeReducer,
  popup: popupReducer,
  loader: loaderReducer,
  orders: ordersReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
    devTools: false,
  });
  const persistor = persistStore(store);
  return { store, persistor };
};
