import { createAction } from '@reduxjs/toolkit';
import asyncLib from 'async';
import _ from 'lodash';
import {
  SIGNUP_SUCCESS,
  SIGNUP_FAILED,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  LOGOUT_START,
  LOGOUT_FAILED,
  LOGOUT_SUCCESS,
  GET_USER_DATA_START,
  GET_USER_DATA_FAILED,
  GET_USER_DATA_SUCCESS,
  UPDATE_MAIN_MENU_THEME,
  UPDATE_MAIN_MENU_CUSTOM_THEME,
  SET_EMAIL_VERIFIED_FLAG,
  UPDATE_MAIN_MENU_TEMP_THEME
} from './actionTypes';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail
} from '../../firebase/auth';
import { updateUserSettings, getUserData } from '../../functions/api';
import { showPopupAction } from "./popupActions";
import { updateMenu } from './menuActions';
import { createCustomer } from './stripeActions';
import { showLoaderAction, hideLoaderAction } from './loaderActions';

export const signUpSuccess = createAction(SIGNUP_SUCCESS);
export const signUpFailed = createAction(SIGNUP_FAILED);
export const signUp = (data, history) => (dispatch) => {
  dispatch(showLoaderAction());
  createUserWithEmailAndPassword(data, (e) => {
    console.error('signup error: ', e);
    dispatch(signUpFailed(e));
    dispatch(hideLoaderAction());
  }).then(async (user) => {
    if (user) {
      dispatch(signUpSuccess(user));
      const stripeCustomerData = { userId: user.id, email: user.email };
      const stripeResp = await dispatch(createCustomer(stripeCustomerData));
      history.push("/");
      window.analytics.identify(user.id, {
        email: user.email,
        phone_number: user.phone,
        name: user.company,
        country: user.country,
        created_at: Date.now(),
      });
      window.analytics.track('Sign Up');
      dispatch(showPopupAction({ showPopup: true, popupType: "welcomeMessage", header: "Welcome to Aboard!", subHeader: "Watch this quick video to get started." }));
      dispatch(hideLoaderAction());
    }
  });
};

export const logInSuccess = createAction(LOGIN_SUCCESS);
export const logInFailed = createAction(LOGIN_FAILED);
export const logIn = (data) => (dispatch) => {
  dispatch(showLoaderAction());
  signInWithEmailAndPassword(data, (e) => {
    console.error('login error: ', e);
    dispatch(logInFailed(e));
    const message = e.code === "auth/user-not-found" ? "User not found" :
      e.code === "auth/wrong-password" ? "Invalid password" : e.message;
    dispatch(showPopupAction({ showPopup: true, popupType: "error", header: "Error!", message }));
    dispatch(hideLoaderAction());
  }).then((res) => {
    if (res) {
      window.analytics.identify(res.user.id, {
        email: res.user.email,
        name: res.user.company,
        country: res.user.country,
      });
      window.analytics.track('Log In');
      dispatch(logInSuccess({
        userDetails: res.user,
        emailVerified: res.emailVerified
      }));
      dispatch(hideLoaderAction());
    }
  });
};

export const setEmailVerified = createAction(SET_EMAIL_VERIFIED_FLAG);
export const setEmailVerifiedFlag = (emailVerified) => (dispatch) => {
  dispatch(setEmailVerified({emailVerified}));
}

// Action to send reset password email
export const resetPasswordAction = (email, callback) => (dispatch) => {
  sendPasswordResetEmail(email).then(() => {
    callback();
  }).catch((error) => {
    console.log('error', error);
    callback();
  });
};

// Action to set users's main menu theme
export const updateMainMenuThemeAction = createAction(UPDATE_MAIN_MENU_THEME);
export const updateMainMenuTheme = (menuTheme) => (dispatch) => {
  dispatch(updateMainMenuThemeAction(menuTheme))
};

export const updateUserSuccess = createAction(UPDATE_USER_SUCCESS);
export const updateUserFailed = createAction(UPDATE_USER_FAILED);
export const updateUser = (id, data, callback) => (dispatch) => {
  dispatch(showLoaderAction());
  updateUserSettings(id, data)
    .then((response) => {
      dispatch(hideLoaderAction());
      if (response) {
        window.analytics.track('Update User Settings');
        dispatch(updateUserSuccess(response.data));
        dispatch(updateMainMenuThemeAction({}))
        callback();
      }
    })
    .catch((err) => {
      dispatch(updateUserFailed(err));
      dispatch(hideLoaderAction());
      callback(err);
    });
};

export const logoutStart = createAction(LOGOUT_START);
export const logoutSuccess = createAction(LOGOUT_SUCCESS);
export const logoutFailed = createAction(LOGOUT_FAILED);
export const logout = () => (dispatch) => {
  dispatch(logoutStart());
  signOut((error) => {
    if (error) {
      dispatch(logoutFailed(error));
    } else {
      window.analytics.reset();
      dispatch(logoutSuccess());
    }
  });
};

export const getUserDataStart = createAction(GET_USER_DATA_START);
export const getUserDataSuccess = createAction(GET_USER_DATA_SUCCESS);
export const getUserDataFailed = createAction(GET_USER_DATA_FAILED);
export const getUser = (id) => (dispatch) => {
  getUserData(id)
    .then((data) => {
      if (data) {
        dispatch(getUserDataSuccess(data));
      }
    })
    .catch((err) => dispatch(getUserDataFailed(err)));
};

// Action to set users's main menu custom theme
export const updateMainMenuCustomThemeAction = createAction(UPDATE_MAIN_MENU_CUSTOM_THEME);
export const updateMainMenuCustomTheme = (menuTheme) => (dispatch) => {
  dispatch(updateMainMenuCustomThemeAction(menuTheme))
};

// Action to set users's main menu temporary theme for color changes
export const updateMainMenuTempThemeAction = createAction(UPDATE_MAIN_MENU_TEMP_THEME);
export const updateMainMenuTempTheme = (menuTheme) => (dispatch) => {
  dispatch(updateMainMenuTempThemeAction(menuTheme))
};

export const updateMenuAndUserMenuTheme = (originalMenuList, updatedMenuList, userData, callback) => (dispatch) => {
  asyncLib.eachSeries(updatedMenuList, function(menu, seriesCallback) {
    const selectedOriginalMenu = originalMenuList.find((originalMenu) => originalMenu.id === menu.id);
    if (!_.isEqual(selectedOriginalMenu, menu)) {
      dispatch(updateMenu(menu.id, { toggle: menu.toggle ? menu.toggle : false }, "", seriesCallback));
    } else {
      seriesCallback();
    }
  }, (err) => {
    if( err ) {
      callback(err);
    } else {
      dispatch(updateUser(userData.id, userData, callback));
    }
  })
};
