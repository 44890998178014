import { createReducer } from '@reduxjs/toolkit';
import {
  ORDERS_FOR_USER_GET_START,
  ORDERS_FOR_USER_GET_SUCCESS,
  ORDERS_FOR_USER_GET_FAILED
} from '../actions/actionTypes';
const initialState = {
  orders: []
};
const ordersReducer = createReducer(
  initialState,
  {
    [ORDERS_FOR_USER_GET_START]: (state) => {
      state.orders = [];
    },
    [ORDERS_FOR_USER_GET_SUCCESS]: (state, action) => {
      const { payload } = action;
      state.orders = payload.orders;
    },
    [ORDERS_FOR_USER_GET_FAILED]: (state) => {
      state.orders = [];
    },
  },
);

export default ordersReducer;
